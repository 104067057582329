import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Logout from '@mui/icons-material/Logout';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { IconButton } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import doesRoleExist from '../../utils/doesRoleExist';
import { Organization } from '../../models/organization.model';

export default function AccountMenu({
  logOutHandler,
}: {
  logOutHandler: () => {};
}) {
  const { user, organization, organizations, setOrganization } = useAuth();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeSelectedOrganization = (organizationId: string) => {
    setOrganization(
      organizations?.find(
        (organization: any) => organization.id === organizationId
      )
    );
  };
  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        size="small"
        data-testid="account-menu-button"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar sx={{ width: 32, height: 32 }}>{user?.firstName[0]}</Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem style={{ pointerEvents: 'none' }}>
          <Avatar /> {`${user?.firstName} ${user?.lastName}`}
        </MenuItem>
        <Divider />
        {organizations
          ?.filter((i: Organization) => !i.pivot.deleted_at)
          .map((org: any) => (
            <MenuItem
              onClick={() => changeSelectedOrganization(org.id)}
              data-testid={org.id}
              key={org.id}
            >
              <ListItemIcon>
                {organization?.id === org.id ? (
                  <CheckBoxIcon fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon fontSize="small" />
                )}
              </ListItemIcon>
              {org?.name}
            </MenuItem>
          ))}
        <Divider />
        <MenuItem
          component={Link}
          to="/app/createOrganization"
          data-testid="addOrganization"
        >
          <ListItemIcon>
            <GroupAddIcon fontSize="small" />
          </ListItemIcon>
          {t('ACCOUNTS_MENU.ADD_ORGANIZATION')}
        </MenuItem>

        <MenuItem onClick={logOutHandler} data-testid="signOutButton">
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('MAIN.SIGNOUT')}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
