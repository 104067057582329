import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ConfirmProvider } from 'material-ui-confirm';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthProvider from './context/AuthContext';
import NotificationProvider from './context/NotificationContext';
import SystemSettingsProvider from './context/SystemSettingsContext';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SystemSettingsProvider>
          <ConfirmProvider>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </ConfirmProvider>
        </SystemSettingsProvider>
      </AuthProvider>
    </ThemeProvider>
  </LocalizationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
