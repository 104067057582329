import { Box, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { Performance } from '../../../models/performance.model';
import camelize from '../../../utils/camelize';
import OrdersAwaitingCustomerApproval from './Sample/OrdersAwaitingCustomerApproval';
import RecentCustomers from './Sample/RecentCustomers';
import RecentOrders from './Sample/RecentOrders';
import RevenueByWeeks from './Sample/RevenueByMonths';
import TasksPending from './Sample/TasksPending';
import TotalOrder from './Sample/TotalOrder';
import TotalRevenue from './Sample/TotalRevenue';

export default function OverviewPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [performance, setPerformance] = useState<Performance>();
  const { organization } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const from = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      ).toISOString();
      const to = new Date().toISOString();
      const performanceResp = await axios.get(
        `api/organizations/${organization?.id}/performance?startDate=${from}&endDate=${to}`
      );
      const performance = new Performance().initialize();
      const performanceObj = camelize(performanceResp.data);
      setPerformance(performance.deserialize(performanceObj));
    };
    fetchData();
  }, [organization?.id]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      gap="20px"
      flexWrap="wrap"
      data-testid="overviewPage"
    >
      <Card
        sx={{
          height: 250,
          minWidth: isMobile ? '80%' : '20%',
          maxWidth: isMobile ? '100%' : 240,
          flex: 1,
        }}
      >
        <CardContent
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <TotalRevenue performance={performance} />
        </CardContent>
      </Card>
      <Card sx={{ height: 250, minWidth: isMobile ? '80%' : '40%', flex: 1 }}>
        <CardContent>
          <RevenueByWeeks performance={performance} />
        </CardContent>
      </Card>
      <Card
        sx={{
          height: 250,
          minWidth: isMobile ? '80%' : '20%',
          maxWidth: isMobile ? '100%' : 240,
          flex: 1,
        }}
      >
        <CardContent
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <TotalOrder performance={performance} />
        </CardContent>
      </Card>
      <Card
        sx={{
          minHeight: 240,
          minWidth: '80%',
          flex: 1,
          overflowX: 'auto',
        }}
      >
        <CardContent
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <RecentOrders />
        </CardContent>
      </Card>

      <Card
        sx={{
          minHeight: 240,
          minWidth: '80%',
          flex: 1,
          overflowX: 'auto',
        }}
      >
        <CardContent
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <OrdersAwaitingCustomerApproval />
        </CardContent>
      </Card>

      <Card
        sx={{
          minHeight: 240,
          minWidth: isMobile ? '80%' : '40%',
          flex: 1,
          overflowX: 'auto',
        }}
      >
        <CardContent
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <RecentCustomers />
        </CardContent>
      </Card>

      <Card
        sx={{
          minHeight: 240,
          minWidth: isMobile ? '80%' : '40%',
          flex: 1,
          overflowX: 'auto',
        }}
      >
        <CardContent
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <TasksPending />
        </CardContent>
      </Card>
    </Box>
  );
}
