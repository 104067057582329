import React, { useState } from 'react';
import Box from '@mui/material/Box';

import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ForgotPasswordForm, {
  ForgotPasswordFormInput,
} from './ForgotPasswordForm';
import axios from '../../axios';
import MainDialog from '../../components/MainDialog';
import ForgotPasswordCard from './ForgotPasswordCard';

export default function ForgotPasswordPage() {
  const { control, handleSubmit } = useForm<ForgotPasswordFormInput>();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRecoverySent, setIsRecoverySent] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<ForgotPasswordFormInput> = async (
    data: ForgotPasswordFormInput
  ) => {
    try {
      await axios('sanctum/csrf-cookie');
      await axios.post('api/auth/forgot-password', { email: data.email });
      setEmail(data.email);
      setIsRecoverySent(true);
    } catch {
      setDialogContent(t('FORGOT_PASSWORD.ERROR_DIALOG_CONTENT'));
      setIsDialogOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh',
        }}
      >
        {isRecoverySent ? (
          <ForgotPasswordCard email={email} />
        ) : (
          <ForgotPasswordForm
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        )}
      </Box>
      <MainDialog
        open={isDialogOpen}
        onConfirm={() => {
          setIsDialogOpen(false);
        }}
        content={dialogContent}
        ok={t('FORGOT_PASSWORD.OK')}
      />
    </>
  );
}
