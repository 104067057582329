import { createContext, ReactNode, useContext, useState } from 'react';

interface SystemSettingsContextProps {
  industries?: null | any;
  setIndustries: null | any;
  roles?: null | any;
  setRoles?: null | any;
  customerTypes?: null | any;
  setCustomerTypes: null | any;
  customerActivityTypes: null | any;
  setCustomerActivityTypes?: null | any;
  customerActivityChannels: null | any;
  setCustomerActivityChannels?: null | any;
  taskStatusTypes: null | any;
  setTaskStatusTypes?: null | any;
  taskPriorityTypes: null | any;
  setTaskPriorityTypes?: null | any;
  orderStatusTypes: null | any;
  setOrderStatusTypes?: null | any;
}

export const taskStatusTextMap = {
  pending: 'SYSTEM_TASK_STATUS_PENDING',
  in_progress: 'SYSTEM_TASK_STATUS_IN_PROGRESS',
  done: 'SYSTEM_TASK_STATUS_DONE',
};

export const prioritiesTextMap = {
  low: 'SYSTEM_PRIORITY_LOW',
  medium: 'SYSTEM_PRIORITY_MEDIUM',
  high: 'SYSTEM_PRIORITY_HIGH',
};

export const customerTypesTextMap = {
  potential: 'SYSTEM_CUSTOMER_TYPES_POTENTIAL',
  active: 'SYSTEM_CUSTOMER_TYPES_ACTIVE',
  passive: 'SYSTEM_CUSTOMER_TYPES_PASSIVE',
};

export const customerActivityChannelTextMap = {
  phone: 'SYSTEM_ACTIVITY_CHANNEL_PHONE',
  email: 'SYSTEM_ACTIVITY_CHANNEL_EMAIL',
  meeting: 'SYSTEM_ACTIVITY_CHANNEL_MEETING',
  online_meeting: 'SYSTEM_ACTIVITY_CHANNEL_ONLINE_MEETING',
};

export const activityTypesTextMap = {
  demo_request: 'SYSTEM_CUSTOMER_ACTIVITY_TYPES_DEMO_REQUEST',
  meeting_request: 'SYSTEM_CUSTOMER_ACTIVITY_TYPES_MEETING_REQUEST',
};

export const orderStatusTextMap = {
  draft: 'ORDERS.STATUS_DRAFT',
  pending_customer_approval: 'ORDERS.STATUS_PENDING_CUSTOMER_APPROVAL',
  on_track: 'ORDERS.STATUS_ON_TRACK',
  done: 'ORDERS.STATUS_DONE',
};

export const SystemSettingsContext = createContext<SystemSettingsContextProps>({
  industries: null,
  setIndustries: null,
  roles: null,
  setRoles: null,
  customerTypes: null,
  setCustomerTypes: null,
  customerActivityTypes: null,
  setCustomerActivityTypes: null,
  customerActivityChannels: null,
  setCustomerActivityChannels: null,
  taskStatusTypes: null,
  setTaskStatusTypes: null,
  taskPriorityTypes: null,
  setTaskPriorityTypes: null,
  orderStatusTypes: null,
  setOrderStatusTypes: null,
});

export const useSystemSettings = () => useContext(SystemSettingsContext);

interface ProviderProps {
  children?: ReactNode;
}

const SystemSettingsProvider = ({ children }: ProviderProps) => {
  const [industries, setIndustries] = useState(null);
  const [roles, setRoles] = useState(null);
  const [customerTypes, setCustomerTypes] = useState(null);
  const [taskStatusTypes, setTaskStatusTypes] = useState(null);
  const [taskPriorityTypes, setTaskPriorityTypes] = useState(null);
  const [orderStatusTypes, setOrderStatusTypes] = useState(null);
  const [customerActivityTypes, setCustomerActivityTypes] = useState(null);
  const [customerActivityChannels, setCustomerActivityChannels] =
    useState(null);

  return (
    <SystemSettingsContext.Provider
      value={{
        industries,
        setIndustries,
        roles,
        setRoles,
        customerTypes,
        setCustomerTypes,
        customerActivityTypes,
        setCustomerActivityTypes,
        customerActivityChannels,
        setCustomerActivityChannels,
        taskStatusTypes,
        setTaskStatusTypes,
        taskPriorityTypes,
        setTaskPriorityTypes,
        orderStatusTypes,
        setOrderStatusTypes,
      }}
    >
      {children}
    </SystemSettingsContext.Provider>
  );
};

export default SystemSettingsProvider;
