import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Title from './Title';

import { camelCase, mapKeys, orderBy, toUpper } from 'lodash';
import { useHistory } from 'react-router-dom';
import axios from '../../../../axios';
import { useAuth } from '../../../../context/AuthContext';
import { Task } from '../../../../models/task.model';

export interface Data {
  id: string;
  title: string;
  description: string;
  dueDate: string;
  priority: string;
  status: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  // {
  //   id: 'id',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'TASKS.ID',
  // },
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'TASKS.TITLE',
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'TASKS.DUE_DATE',
  },
  {
    id: 'priority',
    numeric: false,
    disablePadding: false,
    label: 'TASKS.PRIORITY',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'TASKS.STATUS',
  },
];

export default function TasksPending() {
  const { t } = useTranslation();
  const [rows, setRows] = React.useState<Data[]>([]);
  const { organization, user } = useAuth();

  const fetchTasks = useCallback(async () => {
    const tasksResp = await axios.get(
      `api/organizations/${organization?.id}/tasks`,
      {
        params: {
          filters: {
            status__$ne: 'done',
          },
        },
      }
    );
    const tasksData = tasksResp.data.data.items.map((item: any) => {
      return mapKeys(item, (v, k) => camelCase(k));
    });
    const taskData = tasksData.map((task: Task) => ({
      id: task.id,
      title: task.title,
      description: task.description,
      dueDate: task.dueDate,
      priority: task.priority,
      status: task.status,
    }));
    setRows(taskData.slice(0, 10));
  }, [organization?.id, user?.id]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const history = useHistory();

  return (
    <React.Fragment>
      <Title>{t('TASKS.PENDING')}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.label}>{t(headCell.label)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              hover
              onClick={() => {
                history.push(`./task/${row.id}`);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.dueDate}</TableCell>
              <TableCell>
                {t(`SYSTEM_PRIORITY_${toUpper(row.priority)}`)}
              </TableCell>
              <TableCell>
                {t(`SYSTEM_TASK_STATUS_${toUpper(row.status)}`)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
