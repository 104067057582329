import { Customer } from './customer.model';
import { Deserializable } from './deserializable.model';
import { Team } from './team.model';
import { User } from './user.model';

export class Task implements Deserializable {
  'id': string;
  'title': string;
  'description': string;
  'dueDate': string;
  'priority': string;
  'status': string;
  'customerId': string;
  'assignedTeamId': string;
  'assignedUserId': string;
  'customer': Customer;
  'assignedTeam': Team;
  'assignedUser': User;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
