import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Card,
  CardContent,
  LinearProgress,
  ListItemIcon,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';

import AccountMenu from '../AccountMenu';
import { camelCase, get, mapKeys } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import doesRoleExist from '../../../utils/doesRoleExist';
import { productNameMap } from '../../../utils/productNameMap';
import axios from '../../../axios';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

const subsList = [
  'Customer Management',
  'Task Management',
  'Order Management',
  'Product Management',
  'Team Management',
  'Employee Management',
  'Reporting',
  'Location Management',
  'Sales Assistant',
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface SubscriptionDialogProps {
  open: boolean;
  handleClose: () => void;
}

export default function SubscriptionChangeDialog(
  props: SubscriptionDialogProps
) {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  const { user, organization, subscription, setSubscription, setUser } =
    useAuth();
  const [isLoading, setLoading] = React.useState(false);

  const history = useHistory();

  const logout = async () => {
    setLoading(true);
    await axios.post('api/auth/logout');
    setUser(null);
    localStorage.removeItem('user');
    history.push('/');
    setLoading(false);
  };

  const fetchSubscription = React.useCallback(async () => {
    const subscriptionResp = await axios.get(
      `api/organizations/${organization?.id}/subscriptions`
    );
    setSubscription(mapKeys(subscriptionResp.data, (v, k) => camelCase(k)));
  }, [setSubscription, organization?.id]);

  const updateSubscription = React.useCallback(
    async (product: string) => {
      await axios.post(`api/organizations/${organization?.id}/subscriptions`, {
        product_path: product,
      });

      await fetchSubscription();
      return true;
    },
    [organization?.id, fetchSubscription]
  );

  const getSessionUrlOfProduct = React.useCallback(
    async (product: string) => {
      const resp = await axios.get(
        `api/organizations/${organization?.id}/subscriptions/session`,
        {
          params: {
            product_path: product,
          },
        }
      );

      const sessionUrl = resp?.data?.session_url;
      return sessionUrl;
    },
    [organization?.id]
  );

  const reactivateSubscription = React.useCallback(async () => {
    await axios.post(
      `api/organizations/${organization?.id}/subscriptions/reactivate`
    );

    await fetchSubscription();
  }, [fetchSubscription, organization?.id]);

  const cancelSubscription = React.useCallback(async () => {
    await axios.post(
      `api/organizations/${organization?.id}/subscriptions/cancel`
    );

    await fetchSubscription();
  }, [fetchSubscription, organization?.id]);

  const confirm = useConfirm();

  const subscriptionConfirmation = React.useCallback(
    async ({ description }: { description: string }) => {
      await confirm({
        title: t('CONFIRMATION_DIALOG.ARE_YOU_SURE'),
        description,
        confirmationText: t('CONFIRMATION_DIALOG.CONFIRM'),
        cancellationText: t('CONFIRMATION_DIALOG.CANCEL'),
      });
    },
    [confirm, t]
  );

  const handlePreview = React.useCallback(
    async (product: string) => {
      setLoading(true);
      const previewDataResp = await axios.post(
        `api/organizations/${organization?.id}/subscriptions/preview`,
        {
          reason: 'plan_change',
          new_product: product,
        }
      );

      setLoading(false);
      await subscriptionConfirmation({
        description: t('SUBSCRIPTION_MANAGEMENT.ACTIONS.UPDATE_TEXT', {
          additionalCharge:
            previewDataResp.data?.amountDue?.totalAmountDueDisplay,
        }),
      });
    },
    [organization?.id, t, subscriptionConfirmation]
  );

  const handleSubscriptionSelect = React.useCallback(
    async (product: string) => {
      try {
        if (subscription?.isSuperOrg) {
          setLoading(true);
          await updateSubscription(product);
          handleClose();
          setLoading(false);
          return;
        }

        if (['trial_ended', 'deactivated'].includes(subscription?.status)) {
          setLoading(true);
          const sessionUrl = await getSessionUrlOfProduct(product);
          window.open(sessionUrl, '_blank', 'noreferrer');
          setLoading(false);
          return;
        }

        if (subscription?.status === 'canceled') {
          setLoading(true);
          await reactivateSubscription();
          setLoading(false);
          handleClose();
          return;
        }

        if (
          subscription?.status === 'active' &&
          product === subscription?.product
        ) {
          await subscriptionConfirmation({
            description: t('SUBSCRIPTION_MANAGEMENT.ACTIONS.CANCEL'),
          });

          setLoading(true);
          await cancelSubscription();
          setLoading(false);
          handleClose();
          return;
        }

        await handlePreview(product);
        setLoading(true);
        await updateSubscription(product);
        setLoading(false);
        handleClose();
      } catch (err) {}
    },
    [
      updateSubscription,
      handleClose,
      subscription?.status,
      subscription?.product,
      subscription?.isSuperOrg,
      getSessionUrlOfProduct,
      reactivateSubscription,
      cancelSubscription,
      subscriptionConfirmation,
      handlePreview,
      t,
    ]
  );

  const getSubscribeButtonDisableStatus = (buttonProduct: string) => {
    if (!doesRoleExist(user, organization, ['ADMIN'])) {
      return true;
    }

    if (['overdue'].includes(subscription?.status)) {
      return true;
    }

    if (['canceled'].includes(subscription?.status)) {
      return subscription.product !== buttonProduct;
    }

    if (subscription?.status === 'trial_ended') {
      return false;
    }

    if (subscription?.product === 'ecosh-standard') {
      return ['ecosh-lite'].includes(buttonProduct);
    }

    if (subscription?.product === 'ecosh-professional') {
      return ['ecosh-standard', 'ecosh-lite'].includes(buttonProduct);
    }

    return false;
  };

  const getSubscribeButtonLabel = (buttonProduct: string) => {
    if (
      subscription?.status === 'canceled' &&
      buttonProduct === subscription?.product
    ) {
      return t('SUBSCRIPTION_MANAGEMENT.REACTIVATE');
    }

    if (
      subscription?.status === 'active' &&
      buttonProduct === subscription?.product
    ) {
      return t('SUBSCRIPTION_MANAGEMENT.CANCEL');
    }

    return doesRoleExist(user, organization, ['ADMIN'])
      ? t('SUBSCRIBE_BUTTON')
      : t('SUBSCRIBE_BUTTON_NO_PERMISSON');
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: '#f6f6f6',
        },
      }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t('SUBSCRIPTIONS_TITLE')}
          </Typography>

          <AccountMenu logOutHandler={logout} />
        </Toolbar>
      </AppBar>

      <Box sx={{ width: '100%' }}>{isLoading && <LinearProgress />}</Box>

      <Alert severity="warning" sx={{ m: 2, justifyContent: 'flex-start' }}>
        {t('SUBSCRIPTION_MANAGEMENT.ALERT')}
      </Alert>

      {doesRoleExist(user, organization, ['ADMIN']) &&
        subscription?.accountManagementUrl && (
          <Alert severity="info" sx={{ m: 2, justifyContent: 'flex-start' }}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('SUBSCRIPTION_MANAGEMENT.ACCOUNT_MANAGEMENT_URL', {
                  accountManagementUrl: subscription?.accountManagementUrl,
                }),
              }}
            ></span>
          </Alert>
        )}

      {subscription?.status === 'trial_ended' && (
        <Alert
          severity="warning"
          sx={{ m: 2, mt: 0, justifyContent: 'flex-start' }}
        >
          {t('SUBSCRIPTION_MANAGEMENT.FREE_TRIAL_ENDED')}
        </Alert>
      )}

      {['deactivated'].includes(subscription?.status) && (
        <Alert
          severity="warning"
          sx={{ m: 2, mt: 0, justifyContent: 'flex-start' }}
        >
          {t('SUBSCRIPTION_MANAGEMENT.DEACTIVATED')}
        </Alert>
      )}

      {['overdue'].includes(subscription?.status) &&
        subscription?.accountManagementUrl && (
          <Alert
            severity="error"
            sx={{ m: 2, mt: 0, justifyContent: 'flex-start' }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t('SUBSCRIPTION_MANAGEMENT.OVERDUE', {
                  accountManagementUrl: subscription?.accountManagementUrl,
                }),
              }}
            ></span>
          </Alert>
        )}

      {['canceled'].includes(subscription?.status) && (
        <Alert
          severity="error"
          sx={{ m: 2, mt: 0, justifyContent: 'flex-start' }}
        >
          {t('SUBSCRIPTION_MANAGEMENT.CANCELED', {
            date: subscription?.deactivationDate,
          })}
        </Alert>
      )}

      <Box
        display="flex"
        gap="20px"
        flexWrap="wrap"
        justifyContent="center"
        margin="auto"
        sx={{ minHeight: '80%' }}
      >
        <Card key="ecosh-lite-box">
          <CardContent
            style={{
              display: 'flex',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                padding: '10px',
                minWidth: '300px',
              }}
            >
              <Box
                sx={{
                  marginBottom: '20px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  {get(productNameMap, 'ecosh-lite')?.name}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    marginTop: '10px',
                    color: '#e96e06',
                  }}
                >
                  ${get(productNameMap, 'ecosh-lite')?.price}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: '4px',
                    color: 'rgb(172 172 176)',
                  }}
                >
                  {t('SUBSCRIPTION_USER_MONTH')}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ paddingLeft: 2, flexGrow: 1 }}>
                <List dense>
                  {subsList.slice(0, 6).map((sub, index) => (
                    <ListItem key={`sub-checks-${sub}-${index}`}>
                      <ListItemIcon
                        sx={{
                          color: '#e96e06',
                        }}
                      >
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary={sub} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Button
                variant="contained"
                disabled={getSubscribeButtonDisableStatus('ecosh-lite')}
                onClick={() => handleSubscriptionSelect('ecosh-lite')}
              >
                {getSubscribeButtonLabel('ecosh-lite')}
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Card key="ecosh-standard-box">
          <CardContent
            style={{
              display: 'flex',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                padding: '10px',
                minWidth: '300px',
              }}
            >
              <Box
                sx={{
                  marginBottom: '20px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  {get(productNameMap, 'ecosh-standard')?.name}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    marginTop: '10px',
                    color: '#e96e06',
                  }}
                >
                  ${get(productNameMap, 'ecosh-standard')?.price}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: '4px',
                    color: 'rgb(172 172 176)',
                  }}
                >
                  {t('SUBSCRIPTION_USER_MONTH')}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ paddingLeft: 2, flexGrow: 1 }}>
                <List dense>
                  {subsList.slice(0, 7).map((sub, index) => (
                    <ListItem key={`sub-checks-${sub}-${index}`}>
                      <ListItemIcon
                        sx={{
                          color: '#e96e06',
                        }}
                      >
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary={sub} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Button
                variant="contained"
                disabled={getSubscribeButtonDisableStatus('ecosh-standard')}
                onClick={() => handleSubscriptionSelect('ecosh-standard')}
              >
                {getSubscribeButtonLabel('ecosh-standard')}
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Card key="ecosh-professional-box">
          <CardContent
            style={{
              display: 'flex',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                padding: '10px',
                minWidth: '300px',
              }}
            >
              <Box
                sx={{
                  marginBottom: '20px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  {get(productNameMap, 'ecosh-professional')?.name}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    marginTop: '10px',
                    color: '#e96e06',
                  }}
                >
                  ${get(productNameMap, 'ecosh-professional')?.price}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: '4px',
                    color: 'rgb(172 172 176)',
                  }}
                >
                  {t('SUBSCRIPTION_USER_MONTH')}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ paddingLeft: 2, flexGrow: 1 }}>
                <List dense>
                  {subsList.map((sub) => (
                    <ListItem key={`sub-checks-${sub}`}>
                      <ListItemIcon
                        sx={{
                          color: '#e96e06',
                        }}
                      >
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary={sub} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Button
                variant="contained"
                disabled={getSubscribeButtonDisableStatus('ecosh-professional')}
                onClick={() => handleSubscriptionSelect('ecosh-professional')}
              >
                {getSubscribeButtonLabel('ecosh-professional')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  );
}
