// export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }
//
function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  // Handle null or undefined values
  if (valueA == null && valueB == null) return 0;
  if (valueA == null) return 1;
  if (valueB == null) return -1;

  // Handle date comparison with formats YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss
  const dateRegex = /^\d{4}-\d{2}-\d{2}(\s\d{2}:\d{2}:\d{2})?$/;

  if (
    typeof valueA === 'string' &&
    typeof valueB === 'string' &&
    dateRegex.test(valueA) &&
    dateRegex.test(valueB)
  ) {
    const dateA = new Date(valueA);
    const dateB = new Date(valueB);
    return dateA.getTime() - dateB.getTime();
  }

  // Try to cast values to numbers
  const numA = parseFloat(String(valueA));
  const numB = parseFloat(String(valueB));

  const isNumAValid = !isNaN(numA);
  const isNumBValid = !isNaN(numB);

  // If both values are castable to numbers, compare them as numbers
  if (isNumAValid && isNumBValid) {
    return numA - numB;
  }

  const typeA = typeof valueA;
  const typeB = typeof valueB;

  // Compare numbers
  if (typeA === 'number' && typeB === 'number') {
    return (valueA as number) - (valueB as number);
  }

  // Compare booleans
  if (typeA === 'boolean' && typeB === 'boolean') {
    return valueA === valueB ? 0 : valueA ? -1 : 1;
  }

  // Compare strings (including Turkish characters)
  if (typeA === 'string' && typeB === 'string') {
    return (valueA as string).localeCompare(valueB as string, 'tr', {
      sensitivity: 'base',
    });
  }

  // Handle arrays
  if (Array.isArray(valueA) && Array.isArray(valueB)) {
    // Example: Compare based on length
    return valueA.length - valueB.length;
  }

  // Default to string comparison for other types
  return String(valueA).localeCompare(String(valueB), 'tr', {
    sensitivity: 'base',
  });
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | boolean | string[] | any },
  b: { [key in Key]: number | string | boolean | string[] | any }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
