import {
  Alert,
  AppBar,
  Box,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import doesRoleExist from '../../../utils/doesRoleExist';
import axios from '../../../axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AccountMenu from '../AccountMenu';
import { useHistory } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface SubscriptionDialogProps {
  open: boolean;
  handleClose: () => void;
}

export default function SubscriptionHistoryDialog(
  props: SubscriptionDialogProps
) {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  const { user, organization, subscription, setUser } = useAuth();
  const [isLoading, setLoading] = React.useState(false);

  const [entries, setEntries] = React.useState([]);

  const getSubscriptionEntries = React.useCallback(async () => {
    const resp = await axios.get(
      `api/organizations/${organization?.id}/subscriptions/entries`
    );

    return resp.data;
  }, [organization?.id]);

  const history = useHistory();
  const logout = async () => {
    setLoading(true);
    await axios.post('api/auth/logout');
    setUser(null);
    localStorage.removeItem('user');
    history.push('/');
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    getSubscriptionEntries()
      .then((resp) => {
        setEntries(resp);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getSubscriptionEntries]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: '#f6f6f6',
        },
      }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t('SUBSCRIPTION_HISTORY')}
          </Typography>

          <AccountMenu logOutHandler={logout} />
        </Toolbar>
      </AppBar>
      <Box sx={{ width: '100%' }}>{isLoading && <LinearProgress />}</Box>

      {doesRoleExist(user, organization, ['ADMIN']) &&
        subscription?.accountManagementUrl && (
          <Alert severity="info" sx={{ m: 2, justifyContent: 'center' }}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('SUBSCRIPTION_MANAGEMENT.ACCOUNT_MANAGEMENT_URL', {
                  accountManagementUrl: subscription?.accountManagementUrl,
                }),
              }}
            ></span>
          </Alert>
        )}

      <Box
        display="flex"
        gap="20px"
        flexWrap="wrap"
        justifyContent="center"
        margin="auto"
        sx={{ minHeight: '80%' }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('SUBSCRIPTION_HISTORY_PERIOD')}</TableCell>
                <TableCell align="left">
                  {t('SUBSCRIPTION_HISTORY_PRODUCT')}
                </TableCell>

                <TableCell align="left">
                  {t('SUBSCRIPTION_HISTORY_CUSTOMER_EMAIL')}
                </TableCell>
                <TableCell align="left">
                  {t('SUBSCRIPTION_HISTORY_ADDRESS')}
                </TableCell>

                <TableCell align="left">
                  {t('SUBSCRIPTION_HISTORY_CARD_ENDING')}
                </TableCell>

                <TableCell align="left">
                  {t('SUBSCRIPTION_HISTORY_TOTAL')}
                </TableCell>

                <TableCell align="left">
                  {t('SUBSCRIPTION_HISTORY_INVOICE')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((entry: any) => (
                <TableRow
                  key={entry.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {entry.beginPeriodDate} / {entry.endPeriodDate}
                  </TableCell>

                  <TableCell align="left">
                    {entry.order.items[0].display}
                  </TableCell>

                  <TableCell align="left">
                    {entry.order.customer.email}
                  </TableCell>
                  <TableCell align="left">
                    {entry.order.address.display}
                  </TableCell>

                  <TableCell align="right">
                    {entry.order.payment.cardEnding}
                  </TableCell>
                  <TableCell align="left">{entry.order.totalDisplay}</TableCell>
                  <TableCell align="center">
                    <a href={entry.order.invoiceUrl}>
                      {t('SUBSCRIPTION_HISTORY_DOWNLOAD_INVOICE')}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Dialog>
  );
}
