import React, { useState, useEffect } from 'react';

import axios from '../axios';

const LocationTracker: React.FC = () => {
  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
          },
          (error) => {
            console.error('Error getting location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    // Get location initially
    getLocation();

    let intervalId: number | null = Number(
      localStorage.getItem('locationIntervalId')
    );
    if (!intervalId) {
      intervalId = window.setInterval(() => {
        getLocation();
      }, 10 * 60 * 1000); // 10 minutes in milliseconds
      localStorage.setItem('locationIntervalId', intervalId.toString());
    }

    // Cleanup function to clear interval
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        localStorage.removeItem('locationIntervalId');
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (!location?.latitude || !location?.longitude) {
      return;
    }

    axios.post('/api/me/location', {
      lat: location.latitude.toString(),
      long: location.longitude.toString(),
    });
  }, [location]);

  return <></>;
};

export default LocationTracker;
