import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { Category } from '../../../models/category.model';
import { PRICE_REGEX } from '../../../utils/regexHelpers';

export interface AddProductInput {
  categoryId: string;
  serialNumber: string;
  name: string;
  description: string;
  unit: string;
  unitPrice: string;
  isActive: boolean;
}

export default function AddProductDialog({
  isOpen,
  handleClose,
  onSubmit,
  categories,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: SubmitHandler<AddProductInput>;
  categories: Category[];
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { organization } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { reset, control, handleSubmit } = useForm<AddProductInput>();

  useEffect(() => {
    if (isOpen) {
      reset({
        categoryId: '',
        name: '',
        description: '',
        unit: '',
        unitPrice: '',
        isActive: false,
      });
    }
  }, [reset, isOpen, organization?.id]);

  return (
    <Box>
      <Dialog open={isOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DialogTitle>{t('PRODUCTS.CREATE_NEW')}</DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Controller
              name="name"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('PRODUCTS.NAME')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'nameInput' }}
                />
              )}
              rules={{
                required: `${t('PRODUCTS.NAME')} ${t('PRODUCTS.REQUIRED')}`,

                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="serialNumber"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('PRODUCTS.SERIAL_NUMBER')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'serialNumberInput' }}
                />
              )}
              rules={{
                required: `${t('PRODUCTS.SERIAL_NUMBER')} ${t(
                  'PRODUCTS.REQUIRED'
                )}`,
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="categoryId"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('PRODUCTS.CATEGORY')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('PRODUCTS.CATEGORY')}
                    data-testid={'categoryInput'}
                    onChange={onChange}
                  >
                    {categories?.map((category: Category) => (
                      <MenuItem value={category.id} key={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('PRODUCTS.CATEGORY')} ${t('PRODUCTS.REQUIRED')}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              name="unit"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  multiline
                  label={t('PRODUCTS.UNIT')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'unitInput' }}
                />
              )}
              rules={{
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="unitPrice"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('PRODUCTS.UNIT_PRICE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{
                    'data-testid': 'unitPriceInput',
                  }}
                />
              )}
              rules={{
                pattern: {
                  value: PRICE_REGEX,
                  message: `${t('PRODUCTS.UNIT_PRICE')} ${t(
                    'PRODUCTS.UNIT_PRICE_NUMERIC'
                  )}`,
                },
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="description"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  rows={3}
                  multiline
                  label={t('PRODUCTS.DESCRIPTION')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'descriptionInput' }}
                />
              )}
              rules={{
                maxLength: {
                  value: 10000,
                  message: t('VALIDATION.MAX_LENGTH', { length: 10000 }),
                },
              }}
            />
            <Controller
              name="isActive"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={<Switch onChange={onChange} value={value} />}
                  sx={{ mt: 1 }}
                  label={t('PRODUCTS.IS_ACTIVE')}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} data-testid="cancelDialog">
              {t('PRODUCTS_ADD.CANCEL')}
            </Button>
            <Button type="submit" data-testid="createButton">
              {t('PRODUCTS_ADD.SAVE')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
