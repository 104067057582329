import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';

import { isAfter, parseISO, addMinutes } from 'date-fns';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import UpdatePasswordForm, {
  UpdatePasswordFormInput,
} from './UpdatePasswordForm';
import MainDialog from '../../components/MainDialog';
import { Alert } from '@mui/material';
import Logo, { LogoVariants } from '../../components/Logo';

interface passwordResetParams {
  token: string;
  email: string;
}

export default function UpdatePasswordPage() {
  const updatePasswordForm = useForm<UpdatePasswordFormInput>();

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const updatePasswordControl = updatePasswordForm.control;
  const handleUpdatePasswordSubmit = updatePasswordForm.handleSubmit;
  const getValues = updatePasswordForm.getValues;
  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [passwordResetParams, setPasswordResetParams] =
    useState<passwordResetParams>({
      token: '',
      email: '',
    });
  const { t } = useTranslation();
  const { token } = useParams<{ token?: string }>();

  const tokenCreatedAt = addMinutes(
    parseISO(query.get('createdAt') || new Date().toISOString()),
    60
  );

  const now = new Date();

  useEffect(() => {
    const email = query.get('email');

    if (token && email) {
      setPasswordResetParams({ token, email });
    }
  }, [search, query, token]);

  const updatePassword: SubmitHandler<UpdatePasswordFormInput> = async (
    data: UpdatePasswordFormInput
  ) => {
    try {
      await axios.post('api/auth/reset-password', {
        email: passwordResetParams.email,
        token: passwordResetParams.token,
        password: data.password,
        password_confirmation: data.passwordConfirmation,
      });
      history.push('/signin?isPasswordReset=true');
    } catch {
      setDialogContent(t('FORGOT_PASSWORD.UPDATE_ERROR_DIALOG_CONTENT'));
      setIsDialogOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        {isAfter(now, tokenCreatedAt) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Logo variant={LogoVariants.small} />
            <Alert
              severity="error"
              sx={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
              }}
              data-testid="alertId"
            >
              {t('FORGOT_PASSWORD.EXPIRED')}
            </Alert>
          </div>
        ) : (
          <UpdatePasswordForm
            control={updatePasswordControl}
            handleSubmit={handleUpdatePasswordSubmit}
            onSubmit={updatePassword}
            getValues={getValues}
          />
        )}
      </Box>
      <MainDialog
        open={isDialogOpen}
        onConfirm={() => {
          setIsDialogOpen(false);
        }}
        content={dialogContent}
        ok={t('FORGOT_PASSWORD.OK')}
      />
    </>
  );
}
