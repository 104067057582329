import { Deserializable } from './deserializable.model';

class OrganizationUserPivot implements Deserializable {
  'user_id': string;
  'organization_id': string;
  'is_owner': boolean;
  'deleted_at': string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class Organization implements Deserializable {
  'id': string;
  'name': string;
  'currency': string;
  'taxOfficeName': string;
  'taxNumber': string;
  'industry': string;
  'size': string;
  'web': string;
  'phone': string;
  'email': string;
  'subscription': string;
  'locale': string;
  'pivot': OrganizationUserPivot;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
