import LinkIcon from '@mui/icons-material/Link';
import {
  Alert,
  CircularProgress,
  IconButton,
  Skeleton,
  Snackbar,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { get, times } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { orderStatusTextMap } from '../../../context/SystemSettingsContext';
import { Order, getComparator } from '../../../utils/tableHelpers';

export interface Data {
  id: string;
  code: string;
  customerName: string;
  orderDate: string;
  totalAmount: string;
  totalDiscount: string;
  taxAmount: string;
  note: string;
  status: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.ID',
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.CODE',
  },
  {
    id: 'orderDate',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.ORDER_DATE',
  },
  {
    id: 'note',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.NOTE',
  },
  {
    id: 'customerName',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.CUSTOMER',
  },
  {
    id: 'totalAmount',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.PRICE',
  },
  {
    id: 'totalDiscount',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.DISCOUNT',
  },
  {
    id: 'taxAmount',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.TAX',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.STATUS',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key="action" />
      </TableRow>
    </TableHead>
  );
}

export default function OrdersTable({
  rows,
  setSelectedOrder,
  isLoading,
}: {
  rows: Data[];
  setSelectedOrder: (orderId: string) => void;
  isLoading: boolean;
}) {
  const { organization } = useAuth();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [linkLoadingRow, setLinkLoadingRow] = useState('');

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, id: string) => {
    setSelected((prevSelected) => {
      return prevSelected !== id ? id : '';
    });
    const row = rows.find((row) => row.id === id);
    if (row) {
      setSelectedOrder(row.id);
    }
  };

  const getSharableLink = useCallback(
    async (orderId: string) => {
      setLinkLoadingRow(orderId);
      try {
        const linkResp = await axios.get(
          `api/organizations/${organization?.id}/orders/${orderId}/sharable-link`
        );
        const link = linkResp.data.data;
        navigator.clipboard.writeText(link);
        setAlertOpen(true);
      } finally {
        setLinkLoadingRow('');
      }
    },
    [organization?.id]
  );

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    /* istanbul ignore next */
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }

    /* istanbul ignore next */
    setAlertOpen(false);
  };

  const { t } = useTranslation();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Row = ({
    row,
    isItemSelected,
    labelId,
  }: {
    row: Data;
    isItemSelected: boolean;
    labelId: string;
  }) => {
    const { t } = useTranslation();
    return (
      <>
        <TableRow
          hover
          onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          data-testid={row.id}
          selected={isItemSelected}
          sx={{ cursor: 'pointer' }}
        >
          <TableCell component="th" id={labelId} scope="row">
            {row.id}
          </TableCell>
          <TableCell align="left">{row.code}</TableCell>
          <TableCell align="left">{row.orderDate}</TableCell>
          <TableCell align="left">{row.note}</TableCell>
          <TableCell align="left">{row.customerName}</TableCell>
          <TableCell align="left">{row.totalAmount}</TableCell>
          <TableCell align="left">{row.totalDiscount}</TableCell>
          <TableCell align="left">{row.taxAmount}</TableCell>
          <TableCell align="left">
            {t(get(orderStatusTextMap, row.status))}
          </TableCell>
          <TableCell align="left">
            {linkLoadingRow === row.id ? (
              <CircularProgress size={34} />
            ) : (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  getSharableLink(row.id);
                }}
              >
                <LinkIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const isSelected = ($id: string) => selected.indexOf($id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  let rowDisplay;
  if (isLoading) {
    rowDisplay = times(3, (index) => {
      return (
        <TableRow key={index}>
          {times(10, (index) => (
            <TableCell align="left" key={index}>
              <Skeleton animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  } else {
    rowDisplay = rows
      .sort(getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <Row
            key={row.id}
            row={row}
            isItemSelected={isItemSelected}
            labelId={labelId}
          />
        );
      });
  }

  return (
    <Box sx={{ width: '100%' }} data-testid="ordersTable">
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rowDisplay}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          data-testid="rowsPerPage"
        />
      </Paper>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t('ORDER.LINK_COPIED')}
        </Alert>
      </Snackbar>
    </Box>
  );
}
