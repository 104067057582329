import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { camelCase, mapKeys, pickBy, snakeCase } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { useNotification } from '../../../context/NotificationContext';
import { Activity } from '../../../models/activity.model';
import { Address } from '../../../models/address.model';
import { Customer } from '../../../models/customer.model';
import { User } from '../../../models/user.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import AddAddressDialog, {
  AddAddressInput,
} from '../SettingsPage/AddAddressDialog';
import AddActivityDialog, { AddActivityInput } from './AddActivityDialog';
import CustomerActivitiesTable from './CustomerActivitiesTable';
import CustomerDetailsForm, {
  CustomerDetailsFormInput,
} from './CustomerDetailsForm';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={isMobile ? { paddingTop: 3 } : { p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'data-testid': `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CustomerDetailsPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  let { customerId }: { customerId: string } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [customer, setCustomer] = useState<Customer>();
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [isAddActivityOpen, setAddActivityOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { organization, user } = useAuth();
  const confirm = useConfirm();
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [updateAddress, setUpdateAddress] = useState<Address>();
  const [isAddAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressesLoading, setIsAddressesLoading] = useState(false);
  const [isActivitiesLoading, setIsActivitiesLoading] = useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<Activity>();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [value, setValue] = React.useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const { showMessage } = useNotification();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchAddresses = useCallback(async () => {
    setIsAddressesLoading(true);
    const addressResp = await axios.get(
      `api/organizations/${organization?.id}/customers/${customerId}/addresses`
    );
    const addresses = addressResp.data.data.items.map((item: Address) => {
      const add = new Address();
      const addObj = mapKeys(item, (_v, k) => camelCase(k));
      return add.deserialize(addObj);
    });
    setIsAddressesLoading(false);
    setAddresses(addresses);
  }, [organization?.id, customerId]);

  const fetchCustomer = useCallback(
    async (customerId: string) => {
      setIsLoading(true);
      const customerResp = await axios.get(
        `api/organizations/${organization?.id}/customers/${customerId}`
      );
      const customer = new Customer();
      customer.deserialize(
        mapKeys(customerResp.data.data, (_v, k) => camelCase(k))
      );
      setCustomer(customer);
      setIsLoading(false);
    },
    [organization?.id]
  );

  const fetchUsers = useCallback(async () => {
    const usersResp = await axios.get(
      `api/organizations/${organization?.id}/users`
    );
    const usersData = usersResp.data.data.items.map((item: any) => {
      return mapKeys(item, (_v, k) => camelCase(k));
    });

    setUsers(
      usersData.map((userObj: User) => {
        const user = new User();
        return user.deserialize(userObj);
      })
    );
  }, [organization?.id]);

  useEffect(() => {
    fetchUsers();
    fetchCustomer(customerId);
  }, [fetchUsers, fetchCustomer, customerId]);

  const deleteCustomer = () => {
    confirm({
      title: t('CONFIRMATION.ARE_YOU_SURE'),
      description: t('CONFIRMATION.DESCRIPTION'),
      confirmationText: t('CONFIRMATION.YES'),
      cancellationText: t('CONFIRMATION.CANCEL'),
    })
      .then(async () => {
        await axios.delete(
          `api/organizations/${organization?.id}/customers/${customer?.id}`
        );
        history.push('../customers');
        showMessage({
          message: t('CUSTOMERS.DELETED'),
        });
      })
      .catch(() => {});
  };

  const deleteActivity = () => {
    confirm({
      title: t('CONFIRMATION.ARE_YOU_SURE'),
      description: t('CONFIRMATION.DESCRIPTION'),
      confirmationText: t('CONFIRMATION.YES'),
      cancellationText: t('CONFIRMATION.CANCEL'),
    })
      .then(async () => {
        await axios.delete(
          `api/organizations/${organization?.id}/customers/${customer?.id}/activities/${selectedActivity?.id}`
        );
        await fetchActivities();
      })
      .catch(() => {});
  };

  const addAddressHandler = useCallback(
    async (data: AddAddressInput) => {
      setUpdateAddress(undefined);
      setIsAddressDialogOpen(false);
      if (data.id) {
        await axios.put(
          `api/organizations/${organization?.id}/customers/${customer?.id}/addresses/${data.id}`,
          mapKeys(pickBy(data), (v, k) => snakeCase(k))
        );
      } else {
        await axios.post(
          `api/organizations/${organization?.id}/customers/${customer?.id}/addresses`,
          mapKeys(pickBy(data), (v, k) => snakeCase(k))
        );
      }

      fetchAddresses();
    },
    [organization?.id, fetchAddresses, customer?.id]
  );

  const fetchActivities = useCallback(async () => {
    setIsActivitiesLoading(true);
    const activitiesResp = await axios.get(
      `api/organizations/${organization?.id}/customers/${customerId}/activities`
    );
    const activities = activitiesResp.data.data.items.map((item: any) => {
      const activity = new Activity();
      const addObj = mapKeys(item, (v, k) => camelCase(k));
      const createdBy = mapKeys(addObj.createdBy, (v, k) => camelCase(k));
      addObj.id = addObj.id.toString();
      addObj.createdBy = createdBy;
      return activity.deserialize(addObj);
    });
    setIsActivitiesLoading(false);
    setActivities(activities);
  }, [organization?.id, customerId]);

  const addActivityHandler = useCallback(
    async (data: AddActivityInput) => {
      setAddActivityOpen(false);
      await axios.post(
        `api/organizations/${organization?.id}/customers/${customer?.id}/activities`,
        mapKeys(pickBy(data), (v, k) => snakeCase(k))
      );
      fetchActivities();
    },
    [organization?.id, fetchActivities, customer?.id]
  );

  const deleteAddress = useCallback(
    (addressId: string) => {
      confirm({
        title: t('CONFIRMATION.ARE_YOU_SURE'),
        description: t('CONFIRMATION.DESCRIPTION'),
        confirmationText: t('CONFIRMATION.YES'),
        cancellationText: t('CONFIRMATION.CANCEL'),
      })
        .then(async () => {
          await axios.delete(
            `api/organizations/${organization?.id}/customers/${customer?.id}/addresses/${addressId}`
          );
          fetchAddresses();
        })
        .catch(() => {});
    },
    [fetchAddresses, organization?.id, confirm, t, customer?.id]
  );

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    /* istanbul ignore next */
    if (reason === 'clickaway') {
      return;
    }

    /* istanbul ignore next */
    setAlertOpen(false);
  };

  useEffect(() => {
    if (value === 1) {
      fetchAddresses();
    } else if (value === 2) {
      fetchActivities();
    }
  }, [fetchAddresses, value, fetchActivities]);

  const updateBasicDetails = useCallback(
    async (data: CustomerDetailsFormInput) => {
      setIsDetailsLoading(true);
      await axios.put(
        `api/organizations/${organization?.id}/customers/${data.id}`,
        mapKeys(pickBy(data), (v, k) => snakeCase(k))
      );
      fetchCustomer(customerId);
      setIsDetailsLoading(false);
      setAlertMessage('CUSTOMERS.CUSTOMER_DETAILS.UPDATED');
      setAlertOpen(true);
    },
    [organization?.id, customerId, fetchCustomer]
  );

  const detailsCard = (
    <Card sx={{ width: '100%', maxWidth: isMobile ? '100%' : '75%' }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="detailsHeader"
        >
          {t('SETTINGS.ORGANIZATION_DETAILS')}
        </Typography>
        <CustomerDetailsForm
          customer={customer}
          onSubmit={updateBasicDetails}
          users={users}
          isDetailsLoading={isDetailsLoading}
        />
      </CardContent>
    </Card>
  );

  const dangerZoneCard = (
    <Card sx={{ width: '25%', minWidth: isMobile ? '100%' : 400 }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('SETTINGS.DANGER_ZONE')}
        </Typography>
        <Button
          variant="contained"
          size="medium"
          onClick={deleteCustomer}
          disabled={
            !doesRoleExist(user, organization, [
              'ADMIN',
              'TEAM_MANAGER',
              'TEAM_MEMBER',
            ])
          }
          data-testid="deleteCustomer"
          sx={{ width: '200px', backgroundColor: 'red', color: 'white' }}
        >
          {t('CUSTOMERS.CUSTOMER_DETAILS.DELETE_CUSTOMER')}
        </Button>
      </CardContent>
    </Card>
  );

  const addressesCard = (
    <Card sx={{ width: '100%', maxWidth: isMobile ? '100%' : '75%' }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('SETTINGS.ADDRESSES')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            padding: '10px',
          }}
        >
          {isAddressesLoading && <Skeleton animation="wave" />}
          {!isAddressesLoading &&
            addresses.map((address) => (
              <Accordion key={address.id} sx={{ backgroundColor: '#F3F4F6' }}>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  data-testid={address.id}
                >
                  <Typography>{address.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{address.type}</Typography>
                  <Typography>{address.addressLine1}</Typography>
                  <Typography>{address.addressLine2}</Typography>
                  <Typography>{address.postalcode}</Typography>
                  <Typography>{address.city}</Typography>
                  <Typography>{address.country}</Typography>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button
                    data-testid={`updateButton-${address.id}`}
                    onClick={() => {
                      setUpdateAddress(address);
                      setIsAddressDialogOpen(true);
                    }}
                    disabled={doesRoleExist(user, organization, ['OBSERVER'])}
                  >
                    {t('SETTINGS.ADDRESS_UPDATE')}
                  </Button>
                  <Button
                    data-testid={`deleteButton-${address.id}`}
                    onClick={() => deleteAddress(address.id)}
                    disabled={doesRoleExist(user, organization, ['OBSERVER'])}
                  >
                    {t('SETTINGS.ADDRESS_DELETE')}
                  </Button>
                </AccordionActions>
              </Accordion>
            ))}
          <Button
            variant="outlined"
            size="medium"
            data-testid="addAddressButton"
            onClick={() => setIsAddressDialogOpen(true)}
            disabled={
              !doesRoleExist(user, organization, [
                'ADMIN',
                'TEAM_MANAGER',
                'TEAM_MEMBER',
              ])
            }
            sx={{ width: '200px', marginTop: 2 }}
          >
            {t('SETTINGS.ADDRESS_BUTTON_TEXT')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

  const activitiesCard = (
    <Card sx={{ width: '100%', maxWidth: isMobile ? '100%' : '75%' }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('CUSTOMERS.CUSTOMER_DETAILS.TAB_ACTIVITIES')}
        </Typography>
        <Box display="flex" flexDirection="column" mb={1}>
          <Button
            variant="outlined"
            sx={{
              maxWidth: 250,
              alignSelf: 'flex-end',
            }}
            onClick={() => {
              setAddActivityOpen(true);
            }}
            data-testid="createActivityButton"
            disabled={
              !doesRoleExist(user, organization, [
                'ADMIN',
                'TEAM_MANAGER',
                'TEAM_MEMBER',
              ])
            }
          >
            {t('CUSTOMERS.CUSTOMER_DETAILS.CREATE_ACIVITY')}
          </Button>
        </Box>
        <CustomerActivitiesTable
          rows={activities}
          deleteActivity={deleteActivity}
          setSelectedActivity={setSelectedActivity}
          isLoading={isActivitiesLoading}
        />
      </CardContent>
    </Card>
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="20px"
      flexWrap="wrap"
      data-testid="customerDetailsPage"
    >
      <Box>
        <Button
          variant="text"
          data-testid="customersLink"
          onClick={() => history.push('../customers')}
        >
          <KeyboardBackspaceIcon />{' '}
          {t('CUSTOMERS.CUSTOMER_DETAILS.CUSTOMERS_LINK')}
        </Button>
        {isLoading ? (
          <Skeleton animation="wave" />
        ) : (
          <Typography
            variant="h5"
            mb={1.5}
            sx={{ color: 'darkgray', paddingLeft: '24px', paddingTop: '12px' }}
          >
            {customer?.shortName}
          </Typography>
        )}
      </Box>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="customer-tabs"
          >
            <Tab
              label={t('CUSTOMERS.CUSTOMER_DETAILS.DETAILS')}
              {...a11yProps(0)}
            />
            <Tab
              label={t('CUSTOMERS.CUSTOMER_DETAILS.ADDRESSES')}
              {...a11yProps(1)}
            />
            <Tab
              label={t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITIES')}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box display="flex" flexDirection="column" gap={2}>
            {detailsCard}
            {dangerZoneCard}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {addressesCard}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {activitiesCard}
        </TabPanel>
      </Box>

      <AddAddressDialog
        onSubmit={addAddressHandler}
        handleClose={() => {
          setUpdateAddress(undefined);
          setIsAddressDialogOpen(false);
        }}
        isOpen={isAddAddressDialogOpen}
        values={updateAddress}
      />
      <AddActivityDialog
        isOpen={isAddActivityOpen}
        handleClose={() => setAddActivityOpen(false)}
        onSubmit={addActivityHandler}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t(alertMessage)}
        </Alert>
      </Snackbar>
    </Box>
  );
}
