import { Deserializable } from './deserializable.model';
import { User } from './user.model';

export class Team implements Deserializable {
  'id': string;
  'createdAt': string;
  'organizationId': string;
  'updatedAt': string;
  'name': string;
  'manager': {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  'users': User[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
