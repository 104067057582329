import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import axios from '../../../axios';
import * as L from 'leaflet';
import { camelCase, mapKeys } from 'lodash';
import 'leaflet/dist/leaflet.css';
import { useAuth } from '../../../context/AuthContext';
import { User } from '../../../models/user.model';

export interface City {
  name: string;
  latLng: number[];
}

const MAP_TILE = L.tileLayer(
  `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
  {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  }
);

const mapStyles: React.CSSProperties = {
  overflow: 'hidden',
  width: '100%',
  height: '80vh',
};

export default function MapPage() {
  const mapRef = useRef<L.Map | null>(null);
  const layerRef = useRef<L.LayerGroup | null>(null);
  const controlRef = useRef<L.Control.Layers | null>(null);
  const { organization } = useAuth();

  useEffect(() => {
    const mapParams: L.MapOptions = {
      center: L.latLng(39, 35),
      zoom: 6,
      zoomControl: false,
      layers: [MAP_TILE],
    };

    mapRef.current = L.map('map', mapParams);

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      controlRef.current = L.control
        .layers({
          OpenStreetMap: MAP_TILE,
        })
        .addTo(mapRef.current);

      L.control
        .zoom({
          position: 'topright',
        })
        .addTo(mapRef.current);
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      layerRef.current = L.layerGroup().addTo(mapRef.current);
      controlRef.current?.addOverlay(layerRef.current, 'Circles');
    }
  }, []);

  useEffect(() => {
    layerRef.current?.clearLayers();

    const fetchUsersAndLocations = async () => {
      const usersResp = await axios.get(
        `api/organizations/${organization?.id}/users`
      );
      const usersData = usersResp.data.data.items.map((item: any) => {
        return mapKeys(item, (v, k) => camelCase(k));
      });

      const users = usersData.map((userObj: any) => {
        const user = new User();
        return user.deserialize(userObj);
      });

      const cities = users.map((user: User) => {
        if (user.lastKnownLocation) {
          const city: City = { name: '', latLng: [] };
          city.name = `${user.firstName} ${user.lastName}`;
          const latLong = user.lastKnownLocation.split(',');
          city.latLng = [+latLong[0], +latLong[1]];
          return city;
        }
        return null;
      });

      cities.forEach((city: City) => {
        if (layerRef.current && city?.latLng) {
          const [lat, lng] = city.latLng;
          L.circle(L.latLng(lat, lng), {
            radius: 10000,
          })
            .addTo(layerRef.current)
            .bindPopup(city.name, { autoClose: false, closeOnClick: false })
            .openPopup();
        }
      });
    };

    fetchUsersAndLocations();
  }, [organization?.id]);

  return (
    <React.Fragment>
      <Box id="map" data-testid="mapPage" style={mapStyles} />
    </React.Fragment>
  );
}
