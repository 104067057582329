import axios from '../../../axios';
import React from 'react';
import { useAuth } from '../../../context/AuthContext';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const ProductsPage = () => {
  interface Filters {
    priceFrom: string | null;
    priceTo: string | null;
    isActive: string;
  }

  const [filters, setFilters] = React.useState<Filters>({
    priceFrom: null,
    priceTo: null,
    isActive: 'All',
  });

  const [activities, setActivities] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    limit: 10,
    page: 0,
  });

  const [sortField, setSortField] = React.useState('id:desc');

  const { organization } = useAuth();
  const { t } = useTranslation();

  const fetchActivities = React.useCallback(async () => {
    const params = {
      filters: {
        status: 'draft',
      },
      pagination: {
        enabled: 1,
        limit: pagination?.limit,
        page: pagination?.page + 1,
        sort: sortField,
      },
    } as any;
    if (filters?.priceFrom) {
      params.filters = params.filters || {};
      params.filters.unit_price__$gte = filters.priceFrom;
    }

    if (filters?.priceTo) {
      params.filters = params.filters || {};
      params.filters.unit_price__$lte = filters.priceTo;
    }

    if (filters?.isActive !== 'All') {
      params.filters = params.filters || {};
      params.filters.is_active = filters.isActive === 'active';
    }

    const { data: { data: { items = [], total_count = 0 } = {} } = {} } =
      await axios.get(`api/organizations/${organization?.id}/products`, {
        params,
      });

    setActivities(items);

    if (totalCount !== total_count) {
      setTotalCount(total_count);
    }
  }, [organization, pagination, totalCount, filters, sortField]);

  const columns: GridColDef<(typeof activities)[number]>[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'serial_number',
      headerName: 'Serial Number',
      width: 90,
      sortable: false,
    },
    {
      field: 'name',
      headerName: t(`PRODUCTS.NAME`),
      width: 150,
      editable: false,
    },
    {
      field: 'description',
      headerName: t(`PRODUCTS.DESCRIPTION`),
      width: 150,
      editable: false,
    },
    {
      field: 'unit',
      headerName: t(`PRODUCTS.UNIT`),
      width: 150,
      editable: false,
    },
    {
      field: 'unit_price',
      headerName: t(`PRODUCTS.UNIT_PRICE`),
      width: 150,
      editable: false,
    },
    {
      field: 'category',
      headerName: t(`PRODUCTS.CATEGORY`),
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (_v, row: any) => row.category?.name,
    },
    {
      field: 'is_active',
      headerName: t(`PRODUCTS.IS_ACTIVE`),
      width: 150,
      editable: false,
    },
  ];

  React.useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  return (
    <Box>
      <FormControl sx={{ width: 200, ml: 2 }}>
        <TextField
          value={filters?.priceFrom}
          onChange={(e) => {
            setFilters({ ...filters, priceFrom: e.target.value });
          }}
          label={t(`PRODUCTS.PRICE_FROM`)}
        />
      </FormControl>
      <FormControl sx={{ width: 200, ml: 2 }}>
        <TextField
          value={filters?.priceTo}
          onChange={(e) => {
            setFilters({ ...filters, priceTo: e.target.value });
          }}
          label={t(`PRODUCTS.PRICE_TO`)}
        />
      </FormControl>
      <FormControl sx={{ width: 200, ml: 2 }}>
        <InputLabel id="filters-is-active-label">
          {t('PRODUCTS.IS_ACTIVE')}
        </InputLabel>
        <Select
          labelId="filters-is-active-label"
          id="filters-is-active-select"
          value={filters?.isActive}
          label={t('PRODUCTS.IS_ACTIVE')}
          onChange={(e) => {
            setFilters({
              ...filters,
              isActive: e.target.value,
            });
          }}
          inputProps={{ 'data-testid': 'organizationSizeInput' }}
        >
          <MenuItem value="All">{t(`FILTERS.ALL`)}</MenuItem>
          <MenuItem value={'active'}>{t('PRODUCTS.ACTIVE')}</MenuItem>
          <MenuItem value={'passive'}>{t('PRODUCTS.PASSIVE')}</MenuItem>
        </Select>
      </FormControl>
      <DataGrid
        rows={activities}
        columns={columns}
        paginationModel={{
          pageSize: pagination?.limit,
          page: pagination?.page,
        }}
        onPaginationModelChange={(params) => {
          if (params.pageSize !== pagination.limit) {
            params.page = 0;
          }

          setPagination({
            limit: params.pageSize,
            page: params.page,
          });
        }}
        sortingMode="server"
        onSortModelChange={([sortObj]) => {
          if (!sortObj?.field && !sortObj?.sort) {
            return null;
          }

          setSortField(`${sortObj?.field}:${sortObj?.sort}`);
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        rowCount={totalCount || 0}
        paginationMode="server"
        sx={{ minHeight: 500 }}
      />
    </Box>
  );
};

export default ProductsPage;
