import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 64px)',
      }}
    >
      <Typography variant="h1" style={{ color: 'gray' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: 'gray' }}>
        {t('NOT_FOUND_PAGE_CONTENT')}
      </Typography>
    </Box>
  );
}
