import { isEmpty, isObject, keys, get } from 'lodash';
import { Deserializable } from './deserializable.model';

const removeEmpty = function (object: any) {
  if (!isObject(object)) {
    return object;
  }

  keys(object).forEach(function (key: string) {
    var localObj = get(object, key);

    if (isObject(localObj)) {
      if (isEmpty(localObj)) {
        // @ts-ignore
        delete object[key];
        return;
      }

      removeEmpty(localObj);

      if (isEmpty(localObj)) {
        // @ts-ignore
        delete object[key];
        return;
      }
    }
  });
  return object;
};

export interface OrderByWeek {
  week: string;
  weekOfyear: number;
  count: number;
  revenue: number;
}

export interface OrderByMonth {
  month: string;
  monthOfyear: number;
  count: number;
  revenue: number;
}

export class Performance implements Deserializable {
  'orderPerformance': {
    orderCountByStatus: {
      done: number;
      draft: number;
      onTrack: number;
      pendingCustomerApproval: number;
    };
    orderByWeek: OrderByWeek[];
    orderByMonth: OrderByMonth[];
  };
  'customerPerformance': {
    customerCountByType: {
      potential: number;
      active: number;
      passive: number;
    };
    customerActivityCountByType: {
      demoRequest: number;
      meetingRequest: number;
    };
  };
  'taskPerformance': {
    taskCountByStatus: {
      done: number;
      pending: number;
      inProgress: number;
    };
    taskCountByPriority: {
      low: number;
      medium: number;
      high: number;
    };
    taskCountByTeam: any;
  };

  initialize() {
    Object.assign(this, {
      orderPerformance: {
        orderCountByStatus: {
          done: 0,
          draft: 0,
          onTrack: 0,
          pendingCustomerApproval: 0,
        },
        orderByWeek: [],
        orderByMonth: [],
      },
      customerPerformance: {
        customerCountByType: {
          potential: 0,
          active: 0,
          passive: 0,
        },
        customerActivityCountByType: {
          demoRequest: 0,
          meetingRequest: 0,
        },
      },
      taskPerformance: {
        taskCountByStatus: {
          done: 0,
          pending: 0,
        },
        taskCountByPriority: {
          low: 0,
          medium: 0,
        },
        taskCountByTeam: {},
      },
    });
    return this;
  }

  deserialize(input: any) {
    Object.assign(this, removeEmpty(input));
    return this;
  }
}
