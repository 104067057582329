import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import Countdown from 'react-countdown';
import Logo, { LogoVariants } from '../../components/Logo';

const renderer = ({
  minutes,
  seconds,
}: {
  minutes: number;
  seconds: number;
}) => {
  return (
    <span data-testid="countdown">
      {minutes}:{seconds}
    </span>
  );
};

export default function VerifyEmailCard(params: { email: string | undefined }) {
  const { t } = useTranslation();
  const { email } = params;

  const [verificationResent, setVerificationResent] = useState(false);

  const handleResend = async () => {
    await axios.post('api/auth/email/verification-notification', { email });
    setVerificationResent(true);
  };

  return (
    <Card
      sx={{
        width: 'auto',
        margin: '12px',
      }}
      data-testid="verify-email-card"
    >
      <CardContent>
        <Logo variant={LogoVariants.small} />
        <Typography variant="h5" component="div">
          {t('VERIFICATION.VERIFY')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginTop: '15px',
          }}
        >
          {t('VERIFICATION.VERIFY.DESCRIPTION', { email })}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          size="small"
          onClick={handleResend}
          disabled={verificationResent}
          data-testid="resendVerifyEmailButton"
        >
          {verificationResent
            ? t('VERIFICATION.RESEND_MESSAGE')
            : t('VERIFICATION.RESEND')}
        </Button>
        {verificationResent && (
          <Countdown
            date={Date.now() + 60000}
            renderer={renderer}
            onComplete={
              /* istanbul ignore next */
              () => {
                setVerificationResent(false);
              }
            }
          />
        )}
      </CardActions>
    </Card>
  );
}
