export const productNameMap = {
  'ecosh-lite': {
    name: 'LITE',
    price: '3,99',
  },
  'ecosh-standard': {
    name: 'STANDART',
    price: '4,99',
  },
  'ecosh-professional': {
    name: 'PROFESSIONAL',
    price: '9,99',
  },
};
