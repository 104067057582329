import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import axios from '../../../axios';
import LinkIcon from '@mui/icons-material/Link';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Alert, DialogContentText, Snackbar } from '@mui/material';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';

export default function ShareOrderDialog({
  isOpen,
  handleClose,
  onSubmit,
  orderId,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (selected: string[]) => void;
  orderId?: string;
}) {
  const { t } = useTranslation();
  const { organization } = useAuth();
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [error, setError] = useState(false);

  const getSharableLink = useCallback(async () => {
    const linkResp = await axios.get(
      `api/organizations/${organization?.id}/orders/${orderId}/sharable-link`
    );
    const link = linkResp.data.data;
    navigator.clipboard.writeText(link);
    setAlertOpen(true);
  }, [organization?.id, orderId]);

  const onChange = (_e: object, value: string[]) => {
    const errorEmail = value.find((email) => !regex.test(email));
    if (errorEmail) {
      setInputValue(errorEmail);
      setError(true);
    } else {
      setError(false);
    }
    setSelected(value.filter((email) => regex.test(email)));
  };

  const onDelete = (value: string) => {
    setSelected(selected.filter((e) => e !== value));
  };

  const onInputChange = (_e: object, newValue: string) => {
    setInputValue(newValue);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{t('ORDER_DETAILS.SHARE_ORDER')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('ORDER_DETAILS.SHARE_ORDER_DETAILS')}
          </DialogContentText>

          <Autocomplete
            multiple
            onChange={onChange}
            id="tags-filled"
            value={selected}
            inputValue={inputValue}
            onInputChange={onInputChange}
            options={[]}
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  onDelete={() => onDelete(option)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t('ORDER_DETAILS.SHARE_ORDER_EMAILS')}
                type="email"
                sx={{ mt: 4 }}
                error={error}
                helperText={error && 'Please enter a valid email address'}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            startIcon={<LinkIcon />}
            onClick={(e) => {
              e.stopPropagation();
              getSharableLink();
            }}
            sx={{ position: 'absolute', left: 6 }}
          >
            {t('ORDER_DETAILS.SHARE_ORDER_COPY_LINK')}
          </Button>

          <Button
            onClick={() => onSubmit(selected)}
            disabled={selected.length === 0 || error}
          >
            {t('ORDER_DETAILS.SHARE_ORDER_SUBMIT')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t('ORDER.LINK_COPIED')}
        </Alert>
      </Snackbar>
    </>
  );
}
