import * as React from 'react';
import { Box, LinearProgress } from '@mui/material';
import Logo, { LogoVariants } from './Logo';

export default function LoadingPage() {
  return (
    <Box
      display="flex"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Logo variant={LogoVariants.big} />
      <LinearProgress sx={{ width: '165px' }} />
    </Box>
  );
}
