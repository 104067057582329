import { Deserializable } from './deserializable.model';

export class Attachment implements Deserializable {
  'id': string;
  'taskId': string;
  'path': string;
  'name': string;
  'size': string;
  'mimeType': string;
  'url': string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
