import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useConfirm } from 'material-ui-confirm';
import axios from '../../../axios';
import { camelCase, mapKeys, times } from 'lodash';
import { useAuth } from '../../../context/AuthContext';
import { Attachment } from '../../../models/attachment.model';
import FileUpload from '../../../components/FileUpload';
import { useTranslation } from 'react-i18next';
import doesRoleExist from '../../../utils/doesRoleExist';
import { useNotification } from '../../../context/NotificationContext';

export default function Attachments({ taskId }: { taskId: string }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const confirm = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { organization, user } = useAuth();
  const { showMessage } = useNotification();

  const fetchAttachments = useCallback(async () => {
    setIsLoading(true);
    const tasksResp = await axios.get(
      `api/organizations/${organization?.id}/tasks/${taskId}/attachments`
    );
    setAttachments(
      tasksResp.data.data.items.map((item: any) => {
        const attachmentObj = mapKeys(item, (v, k) => camelCase(k));
        const attachment = new Attachment();
        return attachment.deserialize(attachmentObj);
      })
    );
    setIsLoading(false);
  }, [organization?.id, taskId]);

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  const deleteAttachment = (attachmentId: string) => {
    confirm({
      title: t('CONFIRMATION.ARE_YOU_SURE'),
      description: t('CONFIRMATION.DESCRIPTION'),
      confirmationText: t('CONFIRMATION.YES'),
      cancellationText: t('CONFIRMATION.CANCEL'),
    })
      .then(async () => {
        await axios.delete(
          `api/organizations/${organization?.id}/tasks/${taskId}/attachments/${attachmentId}`
        );
        fetchAttachments();
      })
      .catch(() => {});
  };

  const addNewAttachment = useCallback(
    async (data: File) => {
      const formData = new FormData();
      formData.append('file', data);
      await axios
        .post(
          `api/organizations/${organization?.id}/tasks/${taskId}/attachments`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .catch(() => {
          showMessage({
            message: t('FILE_UPLOAD_ERROR'),
            severity: 'error',
          });
        });
      fetchAttachments();
    },
    [fetchAttachments, organization?.id, taskId, showMessage, t]
  );

  function bytesToSize(bytes: number) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (i === 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      data-testid="attachments"
      height={isMobile ? 'fit-content' : 350}
      flexDirection={isMobile ? 'column-reverse' : 'row'}
    >
      <Box
        minWidth="50%"
        width={isMobile ? '100%' : '50%'}
        sx={{ overflow: 'scroll', paddingRight: '12px' }}
      >
        <List dense>
          {isLoading &&
            times(3, (index) => <Skeleton animation="wave" key={index} />)}
          {!isLoading &&
            attachments.map((attachment) => (
              <ListItem
                data-testid={attachment.id}
                key={attachment.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    data-testid={`delete-attachment-${attachment.id}`}
                    onClick={() => deleteAttachment(attachment.id)}
                    disabled={doesRoleExist(user, organization, ['OBSERVER'])}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton
                  role="button"
                  component="a"
                  download={attachment.url}
                  dense
                  onClick={async (e) => {
                    e.preventDefault();
                    const response = await fetch(attachment.url);
                    const blob = await response.blob();
                    const blobUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = attachment.name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(blobUrl); // Clean up
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <DownloadIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={attachment.name}
                    secondary={bytesToSize(parseInt(attachment.size))}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
      <Box
        display="flex"
        width="100%"
        sx={{ paddingLeft: '12px', paddingBottom: isMobile ? '12px' : '0px' }}
      >
        {doesRoleExist(user, organization, [
          'ADMIN',
          'TEAM_MANAGER',
          'TEAM_MEMBER',
        ]) && <FileUpload onUploadHandler={addNewAttachment} />}
      </Box>
    </Box>
  );
}
