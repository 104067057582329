import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Performance } from '../../../../models/performance.model';

export default function RevenueByWeeksChart({
  performance,
}: {
  performance?: Performance;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart
        data={performance?.orderPerformance.orderByWeek.map((or) => ({
          time: or.week,
          amount: or.revenue,
        }))}
        margin={{
          top: 16,
          right: 16,
          bottom: 0,
          left: 24,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          stroke={theme.palette.text.secondary}
          style={theme.typography.body2}
        />
        <Tooltip />
        <YAxis
          stroke={theme.palette.text.secondary}
          style={theme.typography.body2}
        >
          {/* <Label
            angle={270}
            position="left"
            style={{
              textAnchor: 'middle',
              fill: theme.palette.text.primary,
              ...theme.typography.body1,
            }}
          >
            {t('REVENUE_TITLE')}
          </Label> */}
        </YAxis>
        <Line
          isAnimationActive={true}
          type="monotone"
          dataKey="amount"
          stroke={theme.palette.primary.main}
          dot={true}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
