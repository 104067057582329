import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Address } from '../../../models/address.model';
import { useEffect } from 'react';

export interface AddAddressInput {
  id: string;
  name: string;
  type: string;
  addressLine1: string;
  addressLine2: string;
  postalcode: string;
  city: string;
  country: string;
}

export default function AddAddressDialog({
  isOpen,
  handleClose,
  onSubmit,
  values,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: SubmitHandler<AddAddressInput>;
  values?: Address;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { reset, control, handleSubmit, setValue } = useForm<AddAddressInput>();

  const onHandleClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    reset();
    if (isOpen && values) {
      setValue('id', values.id);
      setValue('name', values.name);
      setValue('type', values.type);
      setValue('addressLine1', values.addressLine1);
      setValue('addressLine2', values.addressLine2);
      setValue('postalcode', values.postalcode);
      setValue('city', values.city);
      setValue('country', values.country);
    }
  }, [isOpen, setValue, values, reset]);

  return (
    <Box>
      <Dialog open={isOpen} onClose={onHandleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DialogTitle>{t('SETTINGS.ADDRESS_TITLE')}</DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Controller
              name="name"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('SETTINGS.ADDRESS_NAME')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'addressNameInput' }}
                />
              )}
              rules={{
                required: `${t('SETTINGS.ADDRESS_NAME')} ${t(
                  'SETTINGS.ADDRESS_REQUIRED'
                )}`,
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('SETTINGS.ADDRESS_TYPE')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('SETTINGS.ADDRESS_TYPE')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'addressTypeInput' }}
                  >
                    <MenuItem value={'Billing'}>Billing</MenuItem>
                    <MenuItem value={'Office'}>Office</MenuItem>
                    <MenuItem value={'Warehouse'}>Warehouse</MenuItem>
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('SETTINGS.ADDRESS_TYPE')} ${t(
                          'SETTINGS.ADDRESS_REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('SETTINGS.ADDRESS_TYPE')} ${t(
                  'SIGNUP.REQUIRED'
                )}`,
              }}
            />
            <Controller
              name="addressLine1"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('SETTINGS.ADDRESS_ADDRESS_LINE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'addressLine1Input' }}
                />
              )}
              rules={{
                required: `${t('SETTINGS.ADDRESS_ADDRESS_LINE')} ${t(
                  'SETTINGS.ADDRESS_REQUIRED'
                )}`,
                maxLength: {
                  value: 100,
                  message: t('VALIDATION.MAX_LENGTH', { length: 100 }),
                },
              }}
            />
            <Controller
              name="addressLine2"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('SETTINGS.ADDRESS_ADDRESS_LINE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'addressLine2Input' }}
                />
              )}
              rules={{
                required: `${t('SETTINGS.ADDRESS_ADDRESS_LINE')} ${t(
                  'SETTINGS.ADDRESS_REQUIRED'
                )}`,
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="postalcode"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('SETTINGS.ADDRESS_ADDRESS_POSTAL_CODE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'postalCodeInput' }}
                />
              )}
              rules={{
                required: `${t('SETTINGS.ADDRESS_ADDRESS_POSTAL_CODE')} ${t(
                  'SETTINGS.ADDRESS_REQUIRED'
                )}`,
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="city"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('SETTINGS.ADDRESS_ADDRESS_POSTAL_CITY')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'cityInput' }}
                />
              )}
              rules={{
                required: `${t('SETTINGS.ADDRESS_ADDRESS_POSTAL_CITY')} ${t(
                  'SETTINGS.ADDRESS_REQUIRED'
                )}`,
                maxLength: {
                  value: 100,
                  message: t('VALIDATION.MAX_LENGTH', { length: 100 }),
                },
              }}
            />
            <Controller
              name="country"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('SETTINGS.ADDRESS_ADDRESS_POSTAL_COUNTRY')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'countryInput' }}
                />
              )}
              rules={{
                required: `${t('SETTINGS.ADDRESS_ADDRESS_POSTAL_COUNTRY')} ${t(
                  'SETTINGS.ADDRESS_REQUIRED'
                )}`,

                maxLength: {
                  value: 100,
                  message: t('VALIDATION.MAX_LENGTH', { length: 100 }),
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onHandleClose} data-testid="cancelDialog">
              {t('SETTINGS.ADDRESS_CANCEL')}
            </Button>
            <Button type="submit" data-testid="createButton">
              {t('SETTINGS.ADDRESS_SUBMIT')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
