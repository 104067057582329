import { Deserializable } from './deserializable.model';

export class Product implements Deserializable {
  'id': string;
  'organizationId': string;
  'categoryId': string;
  'categoryName': string;
  'name': string;
  'description': string;
  'serialNumber': string;
  'unit': string;
  'unitPrice': string;
  'isActive': boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
