import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { camelCase, mapKeys } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { User } from '../../../models/user.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import AddUserDialog, { CreateUserInput } from './AddUserDialog';
import ChangeDetailsDialog, { UpdateUserInput } from './ChangeDetailsDialog';
import UsersTable, { Data } from './UsersTable';

export default function UsersPage() {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { organization, user, subscription } = useAuth();
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [isChangeRoleDialogOpen, setIsChangeRoleDialogOpen] = useState(false);
  const [rows, setRows] = React.useState<Data[]>([]);

  const confirm = useConfirm();

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleChangeRoleDialogClose = () => {
    setIsChangeRoleDialogOpen(false);
  };

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    const usersResp = await axios.get(
      `api/organizations/${organization?.id}/users`
    );

    const usersData = usersResp.data.data.items.map((item: any) => {
      return mapKeys(item, (v, k) => camelCase(k));
    });

    const users = usersData.map((userObj: any) => {
      const user = new User();
      return user.deserialize(userObj);
    });

    setRows(
      users.map((user: User) => ({
        id: user.id.toString(),
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
      }))
    );

    setIsLoading(false);
  }, [organization?.id]);

  const subscriptionConfirmation = React.useCallback(
    async ({ description }: { description: string }) => {
      await confirm({
        title: t('CONFIRMATION_DIALOG.ARE_YOU_SURE'),
        description,
        confirmationText: t('CONFIRMATION_DIALOG.CONFIRM'),
        cancellationText: t('CONFIRMATION_DIALOG.CANCEL'),
      });
    },
    [confirm, t]
  );

  const handlePreview = React.useCallback(
    async (action: string) => {
      if (subscription?.status === 'trial' || subscription.isSuperOrg) {
        return true;
      }

      let description = '';

      const previewDataResp = await axios.post(
        `api/organizations/${organization?.id}/subscriptions/preview`,
        {
          reason: action === 'ADD' ? 'add_user' : 'remove_user',
        }
      );

      if (action === 'ADD') {
        description = t('SUBSCRIPTION_MANAGEMENT.ACTIONS.USER_ADD_TEXT', {
          additionalCharge:
            previewDataResp.data?.amountDue?.totalAmountDueDisplay,
        });
      } else if (action === 'REMOVE') {
        const refundCharge =
          previewDataResp?.data?.proposedPlan?.proratedItemTotal * -1;
        description = t('SUBSCRIPTION_MANAGEMENT.ACTIONS.USER_REMOVE_TEXT', {
          refundCharge: `$${refundCharge}`,
        });
      }

      await subscriptionConfirmation({
        description,
      });
    },
    [
      subscriptionConfirmation,
      organization?.id,
      t,
      subscription?.status,
      subscription?.isSuperOrg,
    ]
  );

  const addNewUser = useCallback(
    async (data: CreateUserInput) => {
      try {
        await handlePreview('ADD');

        handleClose();
        setIsLoading(true);
        await axios.post(`api/organizations/${organization?.id}/users`, {
          email: data.email,
          first_name: data.firstName,
          last_name: data.lastName,
          role: data.roles,
        });
        fetchUsers();
      } finally {
        setIsLoading(false);
      }
    },
    [fetchUsers, handlePreview, organization?.id]
  );

  const updateUser = useCallback(
    async (data: UpdateUserInput) => {
      handleChangeRoleDialogClose();
      await axios.put(
        `api/organizations/${organization?.id}/users/${data.id}`,
        {
          role: data?.roles,
          first_name: data?.firstName,
          last_name: data?.lastName,
        }
      );
      await fetchUsers();
    },
    [fetchUsers, organization?.id]
  );

  const deleteUser = useCallback(
    async (userId: string) => {
      await handlePreview('REMOVE');

      await axios.delete(
        `api/organizations/${organization?.id}/users/${userId}`
      );
      await fetchUsers();
    },
    [handlePreview, fetchUsers, organization?.id]
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column' }}
      data-testid="usersPage"
    >
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '0px',
        }}
      >
        {doesRoleExist(user, organization, ['ADMIN']) && (
          <Button
            variant="outlined"
            disabled={!['active', 'trial'].includes(subscription?.status)}
            onClick={() => {
              setIsDialogOpen(true);
            }}
            data-testid="addNewUserButton"
          >
            {t('USERS.ADD_NEW_USER')}
          </Button>
        )}
      </Box>
      <Box>
        <UsersTable
          isLoading={isLoading}
          rows={rows}
          deleteUser={deleteUser}
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
          openChangeRoleDialog={() => {
            setIsChangeRoleDialogOpen(true);
          }}
        />
      </Box>
      <AddUserDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        onSubmit={addNewUser}
      />

      {selectedUserId && (
        <ChangeDetailsDialog
          isOpen={isChangeRoleDialogOpen}
          handleClose={handleChangeRoleDialogClose}
          onSubmit={updateUser}
          user={rows.find((row) => row.id === selectedUserId) || rows[0]}
        />
      )}
    </Box>
  );
}
