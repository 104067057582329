import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Performance } from '../../../../models/performance.model';
import { useTranslation } from 'react-i18next';

interface DataItem {
  name: string;
  value: number;
  fill: string;
}

export default function TaskCountByTeamChart({
  performance,
}: {
  performance?: Performance;
}) {
  const { t } = useTranslation();

  const [data, setData] = useState<DataItem[]>([
    { name: t('SYSTEM_TASK_STATUS_PENDING'), value: 0, fill: '#0088FE' },
    {
      name: t('SYSTEM_TASK_STATUS_IN_PROGRESS'),
      value: 0,
      fill: '#00C49F',
    },
    { name: t('SYSTEM_TASK_STATUS_DONE'), value: 0, fill: '#FFBB28' },
  ]);

  useEffect(() => {
    let newData = [];
    if (performance) {
      for (const [key, value] of Object.entries(
        performance?.taskPerformance.taskCountByTeam
      )) {
        newData.push({
          name: key,
          value: parseInt(value + ''),
          fill: '#' + Math.floor(Math.random() * 16777215).toString(16),
        });
      }
      setData(newData);
    }
  }, [performance]);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart width={200} height={200} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" />
      </BarChart>
    </ResponsiveContainer>
  );
}
