import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import {
  Avatar,
  Card,
  CardHeader,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { each, get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Customer } from '../../../models/customer.model';
import { parsePhoneNumber } from 'libphonenumber-js';
import {
  NUMBER_ONLY_REGEX,
  VALID_EMAIL_REGEX,
  WORDS_ONLY_REGEX,
} from '../../../utils/regexHelpers';

export default function CustomerDetailsCard(props: { customer?: Customer }) {
  const { customer } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  type formValue =
    | 'id'
    | 'shortName'
    | 'fullName'
    | 'taxOffice'
    | 'taxNumber'
    | 'authorizedPersonName'
    | 'authorizedPersonPhone'
    | 'authorizedPersonEmail';

  const { control, setValue } = useForm<Customer>();

  useEffect(() => {
    const fillTheValues = async () => {
      if (customer) {
        const fields = [
          'id',
          'shortName',
          'fullName',
          'taxOffice',
          'taxNumber',
          'authorizedPersonName',
          'authorizedPersonPhone',
          'authorizedPersonEmail',
        ];

        each(fields, (field) => {
          const value = get(customer, field);
          if (value !== null && value !== undefined) {
            setValue(field as formValue, get(customer, field));
          }
        });
      }
    };

    fillTheValues();
  }, [customer, setValue]);

  return (
    <Card sx={{ width: '80%', padding: 4 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="">
            <BusinessIcon />
          </Avatar>
        }
        title={
          <Typography
            variant="h5"
            component="div"
            sx={{ color: theme.palette.grey[600] }}
          >
            {t('CUSTOMER.TITLE')}
          </Typography>
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          justifyContent: 'space-between',
          padding: '10px',
          flexWrap: 'wrap',
          gap: '12px',
        }}
      >
        <Controller
          name="id"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_ID')}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'codeInput' }}
            />
          )}
        />
        <Controller
          name="shortName"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_SHORT_NAME')}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'codeInput' }}
            />
          )}
          rules={{
            required: `${t('CUSTOMERS.CUSTOMER_SHORT_NAME')} ${t(
              'CUSTOMERS.CUSTOMER.REQUIRED'
            )}`,

            pattern: {
              value: WORDS_ONLY_REGEX,
              message: t('VALIDATION.INVALID_VALUE'),
            },
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },
          }}
        />
        <Controller
          name="fullName"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_FULL_NAME')}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'codeInput' }}
            />
          )}
          rules={{
            required: `${t('CUSTOMERS.CUSTOMER_FULL_NAME')} ${t(
              'CUSTOMERS.CUSTOMER.REQUIRED'
            )}`,

            pattern: {
              value: WORDS_ONLY_REGEX,
              message: t('VALIDATION.INVALID_VALUE'),
            },
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },
          }}
        />
        <Controller
          name="taxOffice"
          control={control}
          defaultValue={new Date().toDateString()}
          render={({ field: { ref, onChange, value, ...field } }) => (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              value={value}
              onChange={onChange}
              label={t('CUSTOMERS.CUSTOMER_TAX_OFFICE')}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'codeInput' }}
            />
          )}
          rules={{
            required: `${t('CUSTOMERS.CUSTOMER_TAX_OFFICE')} ${t(
              'CUSTOMERS.CUSTOMER.REQUIRED'
            )}`,
            pattern: {
              value: WORDS_ONLY_REGEX,
              message: t('VALIDATION.INVALID_VALUE'),
            },
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },
          }}
        />
        <Controller
          name="taxNumber"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              InputProps={{
                readOnly: true,
              }}
              onChange={onChange}
              error={!!error}
              multiline
              label={t('CUSTOMERS.CUSTOMER_TAX_NUMBER')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'totalAmountInput' }}
            />
          )}
          rules={{
            required: `${t('CUSTOMERS.CUSTOMER_TAX_NUMBER')} ${t(
              'CUSTOMERS.CUSTOMER.REQUIRED'
            )}`,

            pattern: {
              value: NUMBER_ONLY_REGEX,
              message: t('VALIDATION.ONLY_NUMBER'),
            },
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },
          }}
        />
        <Controller
          name="authorizedPersonName"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              InputProps={{
                readOnly: true,
              }}
              error={!!error}
              multiline
              label={t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_NAME')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'authorizedPersonNameInput' }}
            />
          )}
          rules={{
            required: `${t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_NAME')} ${t(
              'CUSTOMERS.CUSTOMER.REQUIRED'
            )}`,

            pattern: {
              value: WORDS_ONLY_REGEX,
              message: t('VALIDATION.INVALID_VALUE'),
            },
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },
          }}
        />
        <Controller
          name="authorizedPersonPhone"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              InputProps={{
                readOnly: true,
              }}
              label={t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_PHONE')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'taxAmountInput' }}
            />
          )}
          rules={{
            required: `${t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_PHONE')} ${t(
              'CUSTOMERS.CUSTOMER.REQUIRED'
            )}`,
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },
            validate: {
              isValidPhone: (value) => {
                try {
                  return parsePhoneNumber(value, 'TR')?.isValid()
                    ? true
                    : t('VALIDATION.INVALID_PHONE_NUMBER');
                } catch (err) {
                  return t('VALIDATION.INVALID_PHONE_NUMBER');
                }
              },
            },
          }}
        />
        <Controller
          name="authorizedPersonEmail"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              InputProps={{
                readOnly: true,
              }}
              onChange={onChange}
              error={!!error}
              multiline
              label={t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_EMAIL')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'totalDiscountInput' }}
            />
          )}
          rules={{
            pattern: {
              value: VALID_EMAIL_REGEX,
              message: t('CUSTOMERS.EMAIL.INVALID'),
            },
            required: `${t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_EMAIL')} ${t(
              'CUSTOMERS.CUSTOMER.REQUIRED'
            )}`,
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },
          }}
        />
      </Box>
    </Card>
  );
}
