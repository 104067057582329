import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form';
import Logo, { LogoVariants } from '../../components/Logo';

export interface ForgotPasswordFormInput {
  email: string;
}

export default function ForgotPasswordForm(props: {
  control: Control<ForgotPasswordFormInput>;
  handleSubmit: UseFormHandleSubmit<ForgotPasswordFormInput>;
  onSubmit: SubmitHandler<ForgotPasswordFormInput>;
}) {
  const { t } = useTranslation();

  const { control, handleSubmit, onSubmit } = props;

  return (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              padding: '10px',
              minWidth: '300px',
            }}
          >
            <Box
              sx={{
                marginBottom: '20px',
                textAlign: 'center',
              }}
            >
              <Logo variant={LogoVariants.small} />
              <Typography
                variant="h6"
                sx={{
                  marginTop: '10px',
                }}
              >
                {t('FORGOT_PASSWORD.TITLE')}
              </Typography>
            </Box>

            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: 'contents' }}
            >
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    label={t('FORGOT_PASSWORD.EMAIL')}
                    helperText={error ? error.message : null}
                    sx={{
                      marginBottom: '10px',
                    }}
                    inputProps={{ 'data-testid': 'emailInput' }}
                  />
                )}
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('FORGOT_PASSWORD.EMAIL.INVALID'),
                  },
                  required: `${t('FORGOT_PASSWORD.EMAIL')} ${t(
                    'FORGOT_PASSWORD.REQUIRED'
                  )}`,
                }}
              />

              <Button
                type="submit"
                variant="contained"
                data-testid="forgotPasswordSubmit"
              >
                {t('FORGOT_PASSWORD.SUBMIT')}
              </Button>
            </form>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
