import { Deserializable } from './deserializable.model';

export class Category implements Deserializable {
  'id': string;
  'organizationId': string;
  'name': string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
