import { Deserializable } from './deserializable.model';
import { Product } from './product.model';

export class OrderItem implements Deserializable {
  'id': string;
  'productId': string;
  'itemName': string;
  'itemSerialNumber': string;
  'quantity': string;
  'price': string;
  'taxRate': string;
  'taxAmount': string;
  'totalAmount': string;
  'product': Product;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
