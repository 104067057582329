import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
} from 'react-hook-form';
import Logo, { LogoVariants } from '../../components/Logo';

export interface UpdatePasswordFormInput {
  password: string;
  passwordConfirmation: string;
}

export default function UpdatePasswordForm(props: {
  control: Control<UpdatePasswordFormInput>;
  handleSubmit: UseFormHandleSubmit<UpdatePasswordFormInput>;
  onSubmit: SubmitHandler<UpdatePasswordFormInput>;
  getValues: UseFormGetValues<UpdatePasswordFormInput>;
}) {
  const { t } = useTranslation();

  const { control, handleSubmit, onSubmit, getValues } = props;

  return (
    <>
      <Card data-testid="updatePasswordForm">
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              minWidth: '300px',
            }}
          >
            <div style={{ alignSelf: 'center' }}>
              <Logo variant={LogoVariants.small} />
            </div>
            <Typography
              variant="h6"
              sx={{
                marginBottom: '20px',
                textAlign: 'center',
              }}
            >
              {t('FORGOT_PASSWORD.TITLE')}
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: 'contents' }}
            >
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    type="password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    label={t('FORGOT_PASSWORD.PASSWORD')}
                    helperText={error ? error.message : null}
                    sx={{
                      marginBottom: '20px',
                    }}
                    inputProps={{ 'data-testid': 'passwordInput' }}
                  />
                )}
                rules={{
                  required: `${t('FORGOT_PASSWORD.PASSWORD')} ${t(
                    'SIGNUP.REQUIRED'
                  )}`,
                  minLength: { value: 8, message: t('SIGNUP.PASSWORD.MIN') },
                }}
              />
              <Controller
                name="passwordConfirmation"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    type="password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    label={t('FORGOT_PASSWORD.PASSWORD_CONFIRMATION')}
                    helperText={error ? error.message : null}
                    sx={{
                      marginBottom: '20px',
                    }}
                    inputProps={{ 'data-testid': 'passwordConfirmationInput' }}
                  />
                )}
                rules={{
                  required: `${t('FORGOT_PASSWORD.PASSWORD_CONFIRMATION')} ${t(
                    'SIGNUP.REQUIRED'
                  )}`,
                  minLength: { value: 8, message: t('SIGNUP.PASSWORD.MIN') },
                  validate: (value) => {
                    const { password } = getValues();
                    return (
                      password === value ||
                      t('FORGOT_PASSWORD.PASSWORD_CONFIRMATION_ERROR')
                    );
                  },
                }}
              />

              <Button
                type="submit"
                variant="contained"
                data-testid="updatePasswordSubmit"
              >
                {t('FORGOT_PASSWORD.SUBMIT')}
              </Button>
            </form>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
