import { Deserializable } from './deserializable.model';

export class Activity implements Deserializable {
  'id': string;
  'channel': string;
  'type': string;
  'date': string;
  'note': string;
  'createdBy': {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
  };

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
