import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toUpper } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import { useAuth } from '../../context/AuthContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ExportDataDialogProps {
  open: boolean;
  type: string;
  onClose: (status: boolean) => void;
}

export default function ExportDataDialog(props: ExportDataDialogProps) {
  const { open, type, onClose } = props;

  const { t } = useTranslation();
  const { organization } = useAuth();

  // const fetchSubscription = useCallback(async () => {
  //   const organizationsResp = await axios.get(
  //     `api/organizations/${organization?.id}/subscriptions`
  //   );
  //   setSubscription(mapKeys(organizationsResp.data, (v, k) => camelCase(k)));
  // }, [setSubscription, organization?.id]);

  const handleClose = async (status: boolean) => {
    if (!status) {
      return onClose(status);
    }

    await axios.post(`api/organizations/${organization?.id}/exports/${type}`);
    onClose(status);
  };

  return (
    <>
      {open && (
        <Box
          data-testid="backdrop"
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }}
        />
      )}
      <Dialog
        hideBackdrop
        open={open}
        TransitionComponent={Transition}
        sx={{
          marginTop: '64px',
        }}
      >
        <DialogTitle>{t(`EXPORTS.${toUpper(type)}`)}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('EXPORTS.DIALOG_CONTENT')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              handleClose(false);
            }}
            sx={{
              color: 'gray',
            }}
          >
            {t('EXPORTS.CANCEL')}
          </Button>
          <Button
            onClick={() => {
              handleClose(true);
            }}
            // component="a"
            // target="_blank"
          >
            {t('EXPORTS.CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
