import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonIcon from '@mui/icons-material/Person';
import {
  CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { times } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { User } from '../../../models/user.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import { Order, getComparator } from '../../../utils/tableHelpers';
import { useConfirm } from 'material-ui-confirm';

export interface Data {
  id: string;
  name: string;
  managerId: string;
  managerName: string;
  users: User[];
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ORGANIZATION.TEAM_ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'ORGANIZATION.TEAM_NAME',
  },
  {
    id: 'managerName',
    numeric: false,
    disablePadding: false,
    label: 'ORGANIZATION.TEAM_MANAGER_NAME',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  name: string;
  teamId: string;
  deleteTeam: (teamId: string) => void;
  openEditTeamDialog: () => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, name, teamId, deleteTeam, openEditTeamDialog } = props;
  const { t } = useTranslation();
  const confirm = useConfirm();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {name} {t('ORGANIZATION.TEAM.SELECTED')}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t('ORGANIZATION.TEAM.TITLE')}
        </Typography>
      )}
      {numSelected > 0 && (
        <>
          <Tooltip title={t('ORGANIZATION.TEAM.EDIT')}>
            <IconButton onClick={openEditTeamDialog} data-testid="editTeam">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('ORGANIZATION.TEAM.DELETE')}>
            <IconButton
              onClick={() => {
                deleteTeam(teamId);
              }}
              data-testid="deleteTeam"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
}

export default function TeamsTable({
  rows,
  deleteTeam,
  openEditTeamDialog,
  selectedTeamId,
  setSelectedTeam,
  isLoading,
  fetchTeamUsers,
}: {
  rows: Data[];
  deleteTeam: (documentId: string) => void;
  openEditTeamDialog: () => void;
  selectedTeamId: string;
  setSelectedTeam: React.Dispatch<React.SetStateAction<Data>>;
  isLoading: boolean;
  fetchTeamUsers: (row: Data) => void;
}) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const { user, organization } = useAuth();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, id: string) => {
    if (doesRoleExist(user, organization, ['ADMIN'])) {
      if (id === selectedTeamId) {
        setSelectedTeam({} as Data);
        return;
      }

      const row = rows.find((row) => row.id === id);
      if (row) {
        setSelectedTeam(row);
      }
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Row = ({
    row,
    isItemSelected,
    labelId,
    fetchTeamUsers,
  }: {
    row: Data;
    isItemSelected: boolean;
    labelId: string;
    fetchTeamUsers: (row: Data) => void;
  }) => {
    const [open, setOpen] = useState(false);
    const [isUsersLoading, setIsUsersOpen] = useState(false);
    const { t } = useTranslation();

    return (
      <>
        <TableRow
          hover
          onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          data-testid={row.id}
          selected={isItemSelected}
        >
          <TableCell padding="checkbox">
            {doesRoleExist(user, organization, ['ADMIN']) && (
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            )}
          </TableCell>
          <TableCell component="th" id={labelId} scope="row" padding="none">
            {row.id}
          </TableCell>
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.managerName}</TableCell>
          <TableCell align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              data-testid="userDetails"
              onClick={async (e) => {
                e.stopPropagation();
                setIsUsersOpen(true);
                await fetchTeamUsers(row);
                setIsUsersOpen(false);
                setOpen(!open);
              }}
            >
              {isUsersLoading ? (
                <CircularProgress size={20} />
              ) : open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {t('ORGANIZATION.TEAM.TEAM_MEMBERS')}
                </Typography>

                <List dense>
                  {row.users.map((user) => (
                    <ListItem key={user.id}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${user.firstName} ${user.lastName}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const isSelected = ($id: string) => selectedTeamId === $id;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  let rowDisplay;
  if (isLoading) {
    rowDisplay = times(3, (index) => {
      return (
        <TableRow key={index}>
          <TableCell padding="checkbox">
            <Skeleton animation="wave" />
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            <Skeleton animation="wave" />
          </TableCell>
          <TableCell align="left">
            <Skeleton animation="wave" />
          </TableCell>
          <TableCell align="left">
            <Skeleton animation="wave" />
          </TableCell>
        </TableRow>
      );
    });
  } else {
    rowDisplay = rows
      .sort(getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <Row
            key={row.id}
            row={row}
            isItemSelected={isItemSelected}
            labelId={labelId}
            fetchTeamUsers={fetchTeamUsers}
          />
        );
      });
  }

  return (
    <Box sx={{ width: '100%' }} data-testid="teamsTable">
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selectedTeamId ? 1 : 0}
          name={rows.find((r) => r.id === selectedTeamId)?.name || ''}
          teamId={selectedTeamId}
          deleteTeam={deleteTeam}
          openEditTeamDialog={() => openEditTeamDialog()}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rowDisplay}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          data-testid="rowsPerPage"
        />
      </Paper>
    </Box>
  );
}
