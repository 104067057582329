import { Button, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { camelCase, mapKeys, pickBy, snakeCase } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { OrderItem } from '../../../models/orderItem.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import AddOrderItemDialog, { AddOrderItemInput } from './AddOrderItemDialog';
import OrderItemsTable, { Data } from './OrderItemsTable';

export default function OrderItems({ orderId }: { orderId: string }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [selectedOrderItem, setSelectedOrderItem] = useState<OrderItem>();
  const confirm = useConfirm();
  const { organization, user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchOrderItems = useCallback(async () => {
    setIsLoading(true);
    const orderItemsResp = await axios.get(
      `api/organizations/${organization?.id}/orders/${orderId}/items`
    );
    setOrderItems(
      orderItemsResp.data.data.items.map((item: any) => {
        item.id = item.id.toString();
        const orderItemObj = mapKeys(item, (v, k) => camelCase(k));
        orderItemObj.product = mapKeys(orderItemObj.product, (v, k) =>
          camelCase(k)
        );
        const orderItem = new OrderItem();
        return orderItem.deserialize(orderItemObj);
      })
    );
    setIsLoading(false);
  }, [organization?.id, orderId]);

  useEffect(() => {
    fetchOrderItems();
  }, [fetchOrderItems]);

  const deleteOrderItem = (orderItemId: string) => {
    confirm({
      title: t('CONFIRMATION.ARE_YOU_SURE'),
      description: t('CONFIRMATION.DESCRIPTION'),
      confirmationText: t('CONFIRMATION.YES'),
      cancellationText: t('CONFIRMATION.CANCEL'),
    })
      .then(async () => {
        await axios.delete(
          `api/organizations/${organization?.id}/orders/${orderId}/items/${orderItemId}`
        );
        fetchOrderItems();
      })
      .catch(() => {});
  };

  const handleClose = useCallback(() => {
    setSelectedOrderItem(undefined);
    setIsDialogOpen(false);
  }, []);

  const addNewOrderItem = useCallback(
    async (data: AddOrderItemInput) => {
      handleClose();
      if (data.id) {
        await axios.put(
          `api/organizations/${organization?.id}/orders/${orderId}/items/${data.id}`,
          mapKeys(pickBy(data), (v, k) => snakeCase(k))
        );
      } else {
        await axios.post(
          `api/organizations/${organization?.id}/orders/${orderId}/items`,
          mapKeys(pickBy(data), (v, k) => snakeCase(k))
        );
      }

      fetchOrderItems();
    },
    [fetchOrderItems, organization?.id, orderId, handleClose]
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      data-testid="orderItemsPage"
    >
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '0px',
          paddingLeft: '0px',
          flexDirection: isMobile ? 'column' : 'row-reverse',
          gap: '12px',
        }}
      >
        {doesRoleExist(user, organization, [
          'ADMIN',
          'TEAM_MANAGER',
          'TEAM_MEMBER',
        ]) && (
          <Button
            variant="outlined"
            sx={{
              maxWidth: 250,
            }}
            onClick={() => {
              setIsDialogOpen(true);
            }}
            data-testid="createNewOrderItemButton"
          >
            {t('ORDER_ITEMS.CREATE')}
          </Button>
        )}
      </Box>
      <Box>
        <OrderItemsTable
          rows={orderItems.map(
            (orderItem: OrderItem): Data => ({
              id: orderItem.id,
              productName: orderItem.itemName,
              productSerialNumber: orderItem.itemSerialNumber,
              quantity: orderItem.quantity,
              price: orderItem.price,
              taxRate: orderItem.taxRate,
              taxAmount: orderItem.taxAmount,
              totalAmount: orderItem.totalAmount,
            })
          )}
          isLoading={isLoading}
          deleteOrderItem={deleteOrderItem}
          openEditOrderItemDialog={(orderItemId: string) => {
            setSelectedOrderItem(
              orderItems.find((orderItem) => orderItem.id === orderItemId)
            );
            setIsDialogOpen(true);
          }}
        />
      </Box>
      <AddOrderItemDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        onSubmit={addNewOrderItem}
        selectedOrderItem={selectedOrderItem}
      />
    </Box>
  );
}
