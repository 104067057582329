import { camelCase, mapKeys } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import axios from './axios';
import LoadingPage from './components/LoadingPage';
import { useAuth } from './context/AuthContext';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage';
import OrderDisplayPage from './pages/ProfilePage/OrderDisplayPage/OrderDisplayPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import SignInPage from './pages/SignInPage/SignInPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import UpdatePasswordPage from './pages/UpdatePassword/UpdatePasswordPage';
import VerificationPage from './pages/VerificationPage/VerificationPage';

export default function App() {
  const { user, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const PrivateRoute = useCallback(
    ({ children, ...rest }: any) => {
      return (
        <Route
          {...rest}
          render={({ location }) =>
            user ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/signin',
                  state: { from: location },
                }}
              />
            )
          }
        />
      );
    },
    [user]
  );

  const RedirectAuthRoute = useCallback(
    ({ children, ...rest }: any) => {
      return (
        <Route
          {...rest}
          render={({ location }) =>
            !user ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/app',
                  state: { from: location },
                }}
              />
            )
          }
        />
      );
    },
    [user]
  );

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setIsLoading(true);
        const resp = await axios.get('api/me');
        const userObj = mapKeys(resp.data.data, (v, k) => camelCase(k));
        userObj.organizationRoleMap = userObj.organizations.map(
          (organization: any) => ({
            organizationId: organization.id,
            role: organization.pivot.role,
          })
        );
        localStorage.setItem('user', JSON.stringify(userObj));
        setUser(userObj);
      } catch (e) {
        localStorage.removeItem('user');
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };
    if (localStorage.getItem('user')) {
      fetchSession();
    } else {
      setIsLoading(false);
    }
  }, [setUser]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {user ? (
            <Redirect
              to={{
                pathname: '/app',
              }}
            />
          ) : (
            <SignInPage />
          )}
        </Route>
        <Route path="/share/order/:token">
          <OrderDisplayPage />
        </Route>
        <RedirectAuthRoute path="/signin">
          <SignInPage />
        </RedirectAuthRoute>
        <RedirectAuthRoute path="/signup">
          <SignUpPage />
        </RedirectAuthRoute>
        <Route path="/verification">
          <VerificationPage />
        </Route>
        <Route path="/forgotPassword">
          <ForgotPasswordPage />
        </Route>
        <Route path="/password-reset/:token">
          <UpdatePasswordPage />
        </Route>
        <PrivateRoute path="/app">
          <ProfilePage />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
