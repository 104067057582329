import { Organization } from '../models/organization.model';
import { User } from '../models/user.model';

const doesRoleExist = (
  user?: User | null,
  organization?: Organization | null,
  roleList?: string[]
) => {
  return roleList?.some((role) =>
    user?.organizationRoleMap
      .find((org) => org.organizationId === organization?.id)
      ?.role.includes(role)
  );
};

export default doesRoleExist;
