import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../context/AuthContext';
import { Performance } from '../../../../models/performance.model';
import Title from './Title';

export default function TotalRevenue({
  performance,
}: {
  performance?: Performance;
}) {
  const { t } = useTranslation();

  const { subscription, organization } = useAuth();

  const total: number =
    performance?.orderPerformance.orderByWeek.reduce(
      (acc, o) => acc + o.revenue,
      0
    ) || 0;

  return (
    <React.Fragment>
      <Title>{t('TOTAL_REVENUE_TITLE')}</Title>
      <Typography component="p" variant="h4">
        {new Intl.NumberFormat(organization?.locale, {
          style: 'currency',
          currency: organization?.currency,
        }).format(total)}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {t('FROM_REVENUE_DESCRIPTION', {
          fromDate: format(
            parseISO(
              new Date(
                new Date().setFullYear(new Date().getFullYear() - 1)
              ).toISOString()
            ),
            'dd/MM/yyyy'
          ),
        })}
      </Typography>
      <div>
        {subscription?.product === 'ecosh-professional' && (
          <Link color="primary" href="./reports">
            {t('VIEW_MORE')}
          </Link>
        )}
      </div>
    </React.Fragment>
  );
}
