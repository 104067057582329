import { Deserializable } from './deserializable.model';

export class Customer implements Deserializable {
  'id': string;
  'shortName': string;
  'fullName': string;
  'taxOffice': string;
  'taxNumber': string;
  'type': string;
  'industry': string;
  'authorizedPersonName': string;
  'authorizedPersonEmail': string;
  'authorizedPersonPhone': string;
  'accountManagerId': string;
  'manager': {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
