import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import {
  camelCase,
  debounce,
  flattenDeep,
  get,
  mapKeys,
  pickBy,
  snakeCase,
} from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { useNotification } from '../../../context/NotificationContext';
import { Customer } from '../../../models/customer.model';
import { User } from '../../../models/user.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import ExportDataDialog from '../ExportDialog';
import AddCustomerDialog, { AddCustomerInput } from './AddCustomerDialog';
import CustomersTable, { Data } from './CustomersTable';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomersPage() {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = useState('');
  const { organization, user, subscription } = useAuth();
  const history = useHistory();
  const [rows, setRows] = React.useState<Data[]>([]);
  const [displayRows, setDisplayRows] = useState<Data[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const confirm = useConfirm();
  const [users, setUsers] = useState<User[]>([]);
  const { showMessage } = useNotification();

  const [isExportDataDialogOpen, setExportDataDialogOpen] =
    useState<boolean>(false);

  const [alertProps, setAlertProps] = useState<{
    severity: AlertColor;
    isOpen: boolean;
    text: string;
  }>({
    severity: 'success',
    isOpen: false,
    text: '',
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const debouncedFilter = useMemo(
    () =>
      debounce((value: string) => {
        if (value === '') {
          setDisplayRows(rows);
        } else {
          setDisplayRows(
            rows.filter((o) =>
              Object.keys(o).some((k) => {
                return get(o, k)
                  ?.toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              })
            )
          );
        }
      }, 1000),
    [rows]
  );

  const fetchCustomers = useCallback(async () => {
    setIsLoading(true);
    const customersResp = await axios.get(
      `api/organizations/${organization?.id}/customers`
    );
    const customersData = customersResp.data.data.items.map((item: any) => {
      return mapKeys(item, (v, k) => camelCase(k));
    });

    const customerData = customersData.map((customer: Customer) => ({
      id: customer.id.toString(),
      shortName: customer.shortName,
      fullName: customer.fullName,
      taxOffice: customer.taxOffice,
      taxNumber: customer.taxNumber,
      type: customer.type,
      industry: customer.industry,
      authorizedPersonName: customer.authorizedPersonName,
      authorizedPersonEmail: customer.authorizedPersonEmail,
      manager: mapKeys(customer.manager, (v, k) => camelCase(k)),
      accountManagerId: customer.accountManagerId,
    }));
    setRows(customerData);
    setDisplayRows(customerData);

    const usersResp = await axios.get(
      `api/organizations/${organization?.id}/users`
    );
    const usersData = usersResp.data.data.items.map((item: any) => {
      return mapKeys(item, (v, k) => camelCase(k));
    });

    setUsers(
      usersData.map((userObj: User) => {
        const user = new User();
        return user.deserialize(userObj);
      })
    );
    setIsLoading(false);
  }, [organization?.id]);

  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }
      const file = e.target.files[0];

      try {
        const data = new FormData();
        data.append('data', file);
        const { data: res } = await axios.post(
          `api/organizations/${organization?.id}/customers/parse-bulk-import-csv`,
          data
        );

        confirm({
          title: t('CONFIRMATION.ARE_YOU_SURE'),
          description: t('CONFIRM_CUSTOMERS_IMPORT', {
            customersCount: res.data.data.length,
          }),
          confirmationText: t('CONFIRMATION.YES'),
          cancellationText: t('CONFIRMATION.CANCEL'),
        })
          .then(async () => {
            await axios.post(
              `api/organizations/${organization?.id}/customers/bulk`,
              res.data
            );
            fetchCustomers();
          })
          .catch(() => {});
      } catch (e: any) {
        let errors = flattenDeep(
          Object.values(e?.response?.data?.error?.errors || [])
        );

        let text: any = t(`IMPORT_CUSTOMERS_FAILED`);

        if (errors.length) {
          errors = errors.slice(0, 5);
          text = Object.values(errors).map((e, index) => (
            <p key={`error-alert-${index}`}>
              <>
                {e}
                <br />
              </>
            </p>
          ));
        }

        showMessage({
          severity: 'error',
          message: text,
          autohideDuration: 30000,
        });
      } finally {
        handleMenuClose();
      }
    },
    [handleMenuClose, organization?.id, confirm, t, fetchCustomers, showMessage]
  );

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const addNewCustomer = useCallback(
    async (data: AddCustomerInput) => {
      handleClose();
      await axios.post(
        `api/organizations/${organization?.id}/customers`,
        mapKeys(pickBy(data), (v, k) => snakeCase(k))
      );
      fetchCustomers();
    },
    [fetchCustomers, organization?.id, handleClose]
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      data-testid="customersPage"
    >
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '0px',
          paddingLeft: '0px',
          flexDirection: isMobile ? 'column' : 'row',
          gap: '12px',
        }}
      >
        <TextField
          placeholder="Search..."
          id="search-customer"
          value={filter}
          sx={{ width: '25ch' }}
          inputProps={{ 'data-testid': 'searchInput' }}
          onChange={(event) => {
            setFilter(event.target.value);
            debouncedFilter(event.target.value);
          }}
          InputProps={{
            sx: { height: '45px' },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="clearFilter"
                  aria-label="clear"
                  onClick={() => {
                    setFilter('');
                    debouncedFilter('');
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setIsDialogOpen(true);
            }}
          >
            {t('CUSTOMERS.CREATE_NEW')}
          </MenuItem>

          <>
            <MenuItem
              disabled={
                !['ecosh-standard', 'ecosh-professional'].includes(
                  subscription?.product
                )
              }
              onClick={() => {
                handleUploadClick();
              }}
            >
              {t('IMPORT_CUSTOMERS')}
              &nbsp;
              <Chip size="small" label="Standard" />
            </MenuItem>
            <input
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </>

          {['ecosh-standard', 'ecosh-professional'].includes(
            subscription?.product
          ) && (
            <Link
              style={{ color: 'inherit', textDecoration: 'inherit' }}
              href={`${process.env.REACT_APP_API_URL}/api/assets/customer-import-template`}
              target="_blank"
              rel="noreferrer"
            >
              <MenuItem onClick={handleMenuClose}>
                {t('DOWNLOAD_IMPORT_TEMPLATE')}
                &nbsp;
                <Chip size="small" label="Standard" />
              </MenuItem>
            </Link>
          )}

          <MenuItem
            disabled={!['ecosh-professional'].includes(subscription?.product)}
            onClick={() => {
              handleMenuClose();
              setExportDataDialogOpen(true);
            }}
          >
            {t('CUSTOMERS.EXPORT')}
            &nbsp;
            <Chip size="small" label="Professional" />
          </MenuItem>
        </Menu>
        {doesRoleExist(user, organization, [
          'ADMIN',
          'TEAM_MANAGER',
          'TEAM_MEMBER',
        ]) && (
          <Button
            variant="outlined"
            sx={{
              maxWidth: 250,
            }}
            onClick={handleClick}
            data-testid="createNewCustomerButton"
          >
            {t('EDIT_CUSTOMERS')}
          </Button>
        )}
      </Box>
      <Box>
        <CustomersTable
          rows={displayRows}
          setSelectedCustomer={(customerId) => {
            history.push(`./customer/${customerId}`);
          }}
          isLoading={isLoading}
        />
      </Box>
      <AddCustomerDialog
        isOpen={isDialogOpen}
        users={users}
        handleClose={handleClose}
        onSubmit={addNewCustomer}
      />
      <ExportDataDialog
        open={isExportDataDialogOpen}
        type="customers"
        onClose={(status: boolean) => {
          setExportDataDialogOpen(false);
          if (status) {
            setAlertProps({
              severity: 'success',
              text: 'EXPORTS.COMPLETED',
              isOpen: true,
            });
          }
        }}
      />
      <Snackbar
        open={alertProps.isOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlertProps((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      >
        <Alert
          onClose={() => {
            setAlertProps((prev) => ({
              ...prev,
              isOpen: false,
            }));
          }}
          severity={alertProps.severity}
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t(alertProps.text)}
        </Alert>
      </Snackbar>
    </Box>
  );
}
