import { Deserializable } from './deserializable.model';

export class Address implements Deserializable {
  'id': string;
  'name': string;
  'type': string;
  'addressLine1': string;
  'addressLine2': string;
  'postalcode': string;
  'city': string;
  'country': string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
