import { createContext, ReactNode, useContext, useState } from 'react';
import { Organization } from '../models/organization.model';
import { User } from '../models/user.model';

interface AuthContextProps {
  user?: null | User;
  setUser: null | any;
  subscription?: null | any;
  setSubscription?: null | any;
  organization?: null | Organization;
  setOrganization?: null | any;
  organizations?: null | Organization[];
  setOrganizations?: null | any;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  setUser: null,
  subscription: null,
  setSubscription: null,
  organization: null,
  setOrganization: null,
  organizations: null,
  setOrganizations: null,
});

export const useAuth = () => useContext(AuthContext);

interface ProviderProps {
  children?: ReactNode;
}

const AuthProvider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [subscription, setSubscription] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        setUser,
        user,
        subscription,
        setSubscription,
        organization,
        setOrganization,
        organizations,
        setOrganizations,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
