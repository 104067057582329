import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Data } from './UsersTable';
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import { roleNameMap } from '../../../utils/roleNameMap';
import { get } from 'lodash';
import { useSystemSettings } from '../../../context/SystemSettingsContext';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { WORDS_ONLY_REGEX } from '../../../utils/regexHelpers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface UpdateUserInput {
  id: string;
  firstName: string;
  lastName: string;
  roles: string[];
}

export default function ChangeDetailsDialog({
  isOpen,
  handleClose,
  onSubmit,
  user,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: SubmitHandler<UpdateUserInput>;
  user: Data;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { roles: roleOptions } = useSystemSettings();

  const { control, handleSubmit, setValue } = useForm<UpdateUserInput>({
    mode: 'all',
    defaultValues: {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      roles: user?.role,
    },
  });

  function getStyles(name: string, roles: readonly string[], theme: Theme) {
    return {
      fontWeight:
        roles?.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const closeDialog = () => {
    handleClose();
  };

  useEffect(() => {
    setValue('id', user?.id);
    setValue('firstName', user?.firstName);
    setValue('lastName', user?.lastName);
    setValue('roles', user?.role);
  }, [user, setValue]);

  return (
    <Box>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>{t('CHANGE_DETAILS.TITLE')}</DialogTitle>
        <DialogContent>
          <Controller
            name="firstName"
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={onChange}
                fullWidth
                error={!!error}
                label={t('USERS.INVITE_NAME')}
                helperText={error ? error.message : null}
                sx={{
                  marginBottom: '20px',
                  marginTop: '20px',
                }}
                inputProps={{ 'data-testid': 'userNameInput' }}
              />
            )}
            rules={{
              required: `${t('USERS.INVITE_NAME')} ${t(
                'USERS.INVITE_REQUIRED'
              )}`,
              pattern: {
                value: WORDS_ONLY_REGEX,
                message: t('USERS.INVITE_NAME_VALIDATION'),
              },
              maxLength: {
                value: 50,
                message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
              },
            }}
          />

          <Controller
            name="lastName"
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                label={t('USERS.INVITE_LASTNAME')}
                helperText={error ? error.message : null}
                sx={{
                  marginBottom: '20px',
                  marginTop: '20px',
                }}
                inputProps={{ 'data-testid': 'userNameInput' }}
              />
            )}
            rules={{
              required: `${t('USERS.INVITE_LASTNAME')} ${t(
                'USERS.INVITE_REQUIRED'
              )}`,
              pattern: {
                value: WORDS_ONLY_REGEX,
                message: t('USERS.INVITE_NAME_VALIDATION'),
              },
              maxLength: {
                value: 50,
                message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
              },
            }}
          />
          <Controller
            name="roles"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t('USERS.INVITE_ROLE')}
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  data-testid={'role-select'}
                  id="role-select"
                  // multiple
                  value={value}
                  error={!!error}
                  onChange={(e) => {
                    onChange([e.target.value]);
                  }}
                  sx={{
                    minWidth: '100px',
                  }}
                  inputProps={{ 'data-testid': 'userRoleInput' }}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={t(get(roleNameMap, value))} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {roleOptions?.map((roleOption: string, index: number) => (
                    <MenuItem
                      key={roleOption}
                      value={roleOption}
                      data-testid={`roleOption-${index}`}
                      style={getStyles(roleOption, value, theme)}
                    >
                      {t(get(roleNameMap, roleOption))}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {error
                    ? `${t('USERS.INVITE_ROLE')} ${t('USERS.INVITE_REQUIRED')}`
                    : ''}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              required: `${t('USERS.INVITE_ROLE')} ${t(
                'USERS.INVITE_REQUIRED'
              )}`,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} data-testid="cancelDialog">
            {t('CHANGE_DETAILS.CANCEL')}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            data-testid="changeRoleDialogButton"
          >
            {t('CHANGE_DETAILS.SUBMIT')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
