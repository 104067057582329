import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  activityTypesTextMap,
  customerActivityChannelTextMap,
  useSystemSettings,
} from '../../../context/SystemSettingsContext';
import { get } from 'lodash';
import { useEffect } from 'react';

export interface AddActivityInput {
  channel: string;
  type: string;
  date: string;
  note: string;
}

export default function AddActivityDialog({
  isOpen,
  handleClose,
  onSubmit,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: SubmitHandler<AddActivityInput>;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { customerActivityTypes, customerActivityChannels } =
    useSystemSettings();

  const { reset, control, handleSubmit } = useForm<AddActivityInput>();

  useEffect(() => {
    if (isOpen) {
      reset({
        channel: '',
        type: '',
        date: new Date().toDateString(),
        note: '',
      });
    }
  }, [reset, isOpen]);

  return (
    <Box>
      <Dialog open={isOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DialogTitle>
            {t('CUSTOMERS.CUSTOMER_DETAILS.CREATE_ACIVITY')}
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Controller
              name="channel"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_CHANNEL')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_CHANNEL')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'activityChannelInput' }}
                  >
                    {customerActivityChannels.map((channel: string) => (
                      <MenuItem value={channel} key={channel}>
                        {t(get(customerActivityChannelTextMap, channel))}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t(
                          'CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_CHANNEL'
                        )} ${t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_REQUIRED')}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t(
                  'CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_CHANNEL'
                )} ${t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_REQUIRED')}`,
              }}
            />
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_TYPE')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_TYPE')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'activityTypeInput' }}
                  >
                    {customerActivityTypes.map((type: string) => (
                      <MenuItem value={type} key={type}>
                        {t(get(activityTypesTextMap, type))}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_TYPE')} ${t(
                          'CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_TYPE')} ${t(
                  'CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_REQUIRED'
                )}`,
              }}
            />
            <Controller
              name="date"
              control={control}
              defaultValue={new Date().toDateString()}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <DesktopDateTimePicker
                  {...field}
                  value={value}
                  inputRef={ref}
                  label={t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_DATE')}
                  inputFormat="dd/MM/yyyy hh:mm aa"
                  onChange={(value) => {
                    onChange(value);
                  }}
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      margin="normal"
                      sx={{
                        width: isMobile ? '100%' : '45%',
                      }}
                    />
                  )}
                />
              )}
              rules={{
                required: `${t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_DATE')} ${t(
                  'CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_REQUIRED'
                )}`,
              }}
            />
            <Controller
              name="note"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  multiline
                  label={t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_NOTE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'noteInput' }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} data-testid="cancelDialog">
              {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_CANCEL')}
            </Button>
            <Button type="submit" data-testid="createButton">
              {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_SAVE')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
