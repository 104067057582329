import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Performance } from '../../../../models/performance.model';

interface DataItem {
  name: string;
  value: number;
  fill: string;
}

export default function OrderStatusChart({
  performance,
}: {
  performance?: Performance;
}) {
  const { t } = useTranslation();

  const [data, setData] = useState<DataItem[]>([
    { name: t('ORDERS.STATUS_DRAFT'), value: 0, fill: '#0088FE' },
    {
      name: t('ORDERS.STATUS_PENDING_CUSTOMER_APPROVAL'),
      value: 0,
      fill: '#00C49F',
    },
    { name: t('ORDERS.STATUS_ON_TRACK'), value: 0, fill: '#FFBB28' },
    { name: t('ORDERS.STATUS_DONE'), value: 0, fill: '#FF8042' },
  ]);

  useEffect(() => {
    setData((prevState) => {
      prevState[0].value =
        performance?.orderPerformance.orderCountByStatus.draft || 0;

      prevState[1].value =
        performance?.orderPerformance.orderCountByStatus
          .pendingCustomerApproval || 0;

      prevState[2].value =
        performance?.orderPerformance.orderCountByStatus.onTrack || 0;

      prevState[3].value =
        performance?.orderPerformance.orderCountByStatus.done || 0;
      return prevState;
    });
  }, [performance]);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <PieChart width={200} height={200}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={60}
          label
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
}
