import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { camelCase, get, mapKeys, omitBy, pickBy, snakeCase } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { Address } from '../../../models/address.model';
import { Organization } from '../../../models/organization.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import { productNameMap } from '../../../utils/productNameMap';
import AddAddressDialog, { AddAddressInput } from './AddAddressDialog';
import BasicDetailsForm, {
  OrganizationDetailsFormInput,
} from './BasicDetailsForm';
import SubscriptionChangeDialog from './SubscriptionChangeDialog';
import SubscriptionHistoryDialog from './SubscriptionHistoryDialog';

export default function SettingsPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    user,
    organization,
    setOrganizations,
    setOrganization,
    subscription,
  } = useAuth();
  const confirm = useConfirm();
  const history = useHistory();
  const [isAddAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscriptionChangeOpen, setIsSubscriptionChangeOpen] =
    useState(false);
  const [isSubscriptionHistoryOpen, setIsSubscriptionHistoryOpen] =
    useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [updateAddress, setUpdateAddress] = useState<Address>();

  const deleteOrganization = useCallback(() => {
    confirm({
      title: t('CONFIRMATION.ARE_YOU_SURE'),
      description: t('CONFIRMATION.DESCRIPTION'),
      confirmationText: t('CONFIRMATION.YES'),
      cancellationText: t('CONFIRMATION.CANCEL'),
    })
      .then(async () => {
        await axios.delete(`api/organizations/${organization?.id}`);
        setOrganizations(null);
        setOrganization(null);
        history.push('/');
      })
      .catch(() => {});
  }, [
    confirm,
    history,
    organization?.id,
    setOrganization,
    setOrganizations,
    t,
  ]);

  const fetchAddresses = useCallback(async () => {
    setIsLoading(true);
    const addressResp = await axios.get(
      `api/organizations/${organization?.id}/address`
    );
    const addresses = addressResp.data.data.items.map((item: Address) => {
      const add = new Address();
      const addObj = mapKeys(item, (_v, k) => camelCase(k));
      return add.deserialize(addObj);
    });
    setIsLoading(false);
    setAddresses(addresses);
  }, [organization?.id]);

  const addAddressHandler = useCallback(
    async (data: AddAddressInput) => {
      setUpdateAddress(undefined);
      setIsAddressDialogOpen(false);
      if (data.id) {
        await axios.put(
          `api/organizations/${organization?.id}/address/${data.id}`,
          mapKeys(pickBy(data), (_v, k) => snakeCase(k))
        );
      } else {
        await axios.post(
          `api/organizations/${organization?.id}/address`,
          mapKeys(pickBy(data), (_v, k) => snakeCase(k))
        );
      }

      fetchAddresses();
    },
    [organization?.id, fetchAddresses]
  );

  const deleteAddress = useCallback(
    (addressId: string) => {
      confirm({
        title: t('CONFIRMATION.ARE_YOU_SURE'),
        description: t('CONFIRMATION.DESCRIPTION'),
        confirmationText: t('CONFIRMATION.YES'),
        cancellationText: t('CONFIRMATION.CANCEL'),
      })
        .then(async () => {
          await axios.delete(
            `api/organizations/${organization?.id}/address/${addressId}`
          );
          fetchAddresses();
        })
        .catch(() => {});
    },
    [fetchAddresses, organization?.id, confirm, t]
  );

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  const updateBasicDetails = useCallback(
    async (data: OrganizationDetailsFormInput) => {
      setIsDetailsLoading(true);
      const organizationId = organization?.id;
      await axios.put(
        `api/organizations/${organization?.id}`,
        mapKeys(data, (_v, k) => snakeCase(k))
      );
      const organizationsResp = await axios.get('api/organizations');
      const organizations = organizationsResp.data.data.items.map(
        (item: Organization) => {
          const org = new Organization();
          const orgObj = mapKeys(omitBy(item, ['name']), (_v, k) =>
            camelCase(k)
          );
          return org.deserialize(orgObj);
        }
      );
      setOrganizations(organizations);
      setOrganization(
        organizations.find((org: Organization) => org.id === organizationId)
      );
      setIsDetailsLoading(false);
    },
    [organization?.id, setOrganization, setOrganizations]
  );

  const detailsCard = (
    <Card sx={{ width: '25%', minWidth: isMobile ? '100%' : 400 }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('SETTINGS.ORGANIZATION_DETAILS')}
        </Typography>
        <BasicDetailsForm
          onSubmit={updateBasicDetails}
          isDetailsLoading={isDetailsLoading}
        />
      </CardContent>
    </Card>
  );

  const dangerZoneCard = (
    <Card sx={{ width: '60%', minWidth: isMobile ? '100%' : 400 }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('SETTINGS.DANGER_ZONE')}
        </Typography>
        <Button
          variant="contained"
          size="medium"
          onClick={deleteOrganization}
          data-testid="deleteOrganization"
          sx={{ width: '200px', backgroundColor: 'red', color: 'white' }}
          disabled={!doesRoleExist(user, organization, ['ADMIN'])}
        >
          {t('SETTINGS.DELETE_ORGANIZATION')}
        </Button>
      </CardContent>
    </Card>
  );

  const addressesCard = (
    <Card
      sx={{
        width: '60%',
        minWidth: isMobile ? '100%' : 400,
        height: 'fit-content',
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('SETTINGS.ADDRESSES')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            padding: '10px',
          }}
        >
          {isLoading && <Skeleton animation="wave" />}
          {!isLoading &&
            addresses.map((address) => (
              <Accordion key={address.id} sx={{ backgroundColor: '#F3F4F6' }}>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  data-testid={address.id}
                >
                  <Typography>{address.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{address.type}</Typography>
                  <Typography>{address.addressLine1}</Typography>
                  <Typography>{address.addressLine2}</Typography>
                  <Typography>{address.postalcode}</Typography>
                  <Typography>{address.city}</Typography>
                  <Typography>{address.country}</Typography>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button
                    data-testid={`updateButton${address.id}`}
                    disabled={!doesRoleExist(user, organization, ['ADMIN'])}
                    onClick={() => {
                      setUpdateAddress(address);
                      setIsAddressDialogOpen(true);
                    }}
                  >
                    {t('SETTINGS.ADDRESS_UPDATE')}
                  </Button>
                  <Button
                    data-testid={`deleteButton${address.id}`}
                    onClick={() => deleteAddress(address.id)}
                    disabled={!doesRoleExist(user, organization, ['ADMIN'])}
                  >
                    {t('SETTINGS.ADDRESS_DELETE')}
                  </Button>
                </AccordionActions>
              </Accordion>
            ))}
          <Button
            variant="outlined"
            size="medium"
            data-testid="addAddressButton"
            onClick={() => setIsAddressDialogOpen(true)}
            sx={{ width: '200px', marginTop: 2 }}
            disabled={!doesRoleExist(user, organization, ['ADMIN'])}
          >
            {t('SETTINGS.ADDRESS_BUTTON_TEXT')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

  const subscriptionCard = (
    <Card
      sx={{
        width: '60%',
        minWidth: isMobile ? '100%' : 400,
        height: 'fit-content',
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('SETTINGS_SUBSCRIPTION')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            padding: '10px',
          }}
        >
          {!subscription?.status && <Skeleton animation="wave" />}
          {subscription?.status && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              <div>
                <Typography
                  sx={{
                    marginBottom: '20px',
                    textAlign: 'left',
                    color: '#828080',
                  }}
                >
                  {subscription?.nextChargeTotal && (
                    <>
                      {subscription?.status === 'canceled' && (
                        <Chip
                          color="warning"
                          label={t('SUBSCRIPTION_MANAGEMENT.CANCELED_TITLE')}
                        />
                      )}

                      {subscription?.status === 'active' && (
                        <Chip
                          color="primary"
                          label={t('SUBSCRIPTION_MANAGEMENT.ACTIVE_TITLE')}
                        />
                      )}

                      <Typography variant="subtitle1" color="secondary">
                        {get(productNameMap, subscription?.product)?.name}{' '}
                        {subscription?.nextChargeTotal} /{' '}
                        {subscription?.activeUserCount} {t('USERS.LINK')}
                      </Typography>

                      {subscription?.nextChargeDate && (
                        <Typography variant="caption">
                          {t('SUBSCRIPTION_MANAGEMENT.NEXT_BILLING_DATE', {
                            date: subscription?.nextChargeDate,
                          })}
                        </Typography>
                      )}
                    </>
                  )}

                  {subscription?.status === 'trial' && (
                    <Chip
                      color="primary"
                      sx={{ ml: 2 }}
                      label={t('SUBSCRIPTION_FREE_TRIAL')}
                    />
                  )}
                </Typography>
                <div>
                  <Button
                    variant="outlined"
                    size="medium"
                    data-testid="manageSubscriptionButton"
                    onClick={() => setIsSubscriptionChangeOpen(true)}
                    sx={{ width: '200px', marginTop: 2 }}
                    disabled={
                      !doesRoleExist(user, organization, ['ADMIN']) ||
                      subscription?.status === 'trial'
                    }
                  >
                    {t('MANAGE_SUBSCRIPTION')}
                  </Button>
                  {!['trial', 'trial_ended'].includes(subscription?.status) && (
                    <Button
                      variant="outlined"
                      size="medium"
                      data-testid="subscriptionHistory"
                      onClick={() => setIsSubscriptionHistoryOpen(true)}
                      sx={{ width: '200px', marginTop: 2, ml: 2 }}
                      disabled={
                        !doesRoleExist(user, organization, ['ADMIN']) ||
                        subscription?.status === 'trial'
                      }
                    >
                      {t('SUBSCRIPTION_HISTORY')}
                    </Button>
                  )}
                </div>
              </div>
              <div>
                {subscription?.status === 'trial' && (
                  <Typography variant="body1" color="secondary" sx={{ pl: 2 }}>
                    {t('SUBSCRIPTION_FREE_TRIAL_DESC', {
                      days: subscription?.trialEndsInDays,
                    })}
                  </Typography>
                )}
              </div>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap={2}
      sx={isMobile ? { width: '100%' } : {}}
    >
      {detailsCard}

      <Box
        display="flex"
        flexDirection="column"
        gap="20px"
        flexWrap="wrap"
        data-testid="settingsPage"
        flexGrow="1"
      >
        {addressesCard}
        {subscriptionCard}
        {dangerZoneCard}
      </Box>

      <SubscriptionChangeDialog
        open={isSubscriptionChangeOpen}
        handleClose={() => setIsSubscriptionChangeOpen(false)}
      />

      <SubscriptionHistoryDialog
        open={isSubscriptionHistoryOpen}
        handleClose={() => setIsSubscriptionHistoryOpen(false)}
      />

      <AddAddressDialog
        onSubmit={addAddressHandler}
        handleClose={() => {
          setUpdateAddress(undefined);
          setIsAddressDialogOpen(false);
        }}
        isOpen={isAddAddressDialogOpen}
        values={updateAddress}
      />
    </Box>
  );
}
