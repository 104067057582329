import {
  FormControlLabel,
  FormHelperText,
  Skeleton,
  Switch,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { each, get, times } from 'lodash';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { Category } from '../../../models/category.model';
import { Product } from '../../../models/product.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import { PRICE_REGEX } from '../../../utils/regexHelpers';

export default function ProductDetailsForm(props: {
  product?: Product;
  onSubmit: SubmitHandler<Product>;
  isDetailsLoading: boolean;
  categories: Category[];
}) {
  const { onSubmit, isDetailsLoading, product, categories } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { organization, user } = useAuth();

  type formValue =
    | 'id'
    | 'categoryId'
    | 'name'
    | 'description'
    | 'serialNumber'
    | 'unit'
    | 'unitPrice'
    | 'isActive';

  const { control, handleSubmit, setValue } = useForm<Product>();

  useEffect(() => {
    const fillTheValues = async () => {
      if (product) {
        const fields = [
          'id',
          'categoryId',
          'name',
          'description',
          'serialNumber',
          'unit',
          'unitPrice',
          'isActive',
        ];

        each(fields, (field) => {
          const value = get(product, field);
          if (value !== null && value !== undefined) {
            setValue(field as formValue, get(product, field));
          }
        });
      }
    };

    fillTheValues();
  }, [product, setValue]);

  if (isDetailsLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: '10px',
          flexWrap: 'wrap',
          gap: '24px',
        }}
      >
        {times(5, (index) => (
          <Skeleton animation="wave" sx={{ marginBottom: 6 }} key={index} />
        ))}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        padding: '10px',
        flexWrap: 'wrap',
        gap: '20px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Controller
          name="serialNumber"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              multiline
              label={t('PRODUCTS.SERIAL_NUMBER')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              inputProps={{ 'data-testid': 'serialNumberInput' }}
            />
          )}
          rules={{
            required: `${t('PRODUCTS.SERIAL_NUMBER')} ${t(
              'PRODUCTS.REQUIRED'
            )}`,
          }}
        />
        <Controller
          name="name"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              multiline
              label={t('PRODUCTS.NAME')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              inputProps={{ 'data-testid': 'nameInput' }}
            />
          )}
          rules={{
            required: `${t('PRODUCTS.NAME')} ${t('PRODUCTS.REQUIRED')}`,
          }}
        />
        <Controller
          name="categoryId"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: '210px',
                width: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('PRODUCTS.CATEGORY')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('PRODUCTS.CATEGORY')}
                data-testid={'categoryInput'}
                onChange={onChange}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {categories?.map((category: Category) => (
                  <MenuItem value={category.id} key={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error
                  ? `${t('PRODUCTS.CATEGORY')} ${t('PRODUCTS.REQUIRED')}`
                  : ''}
              </FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          name="unitPrice"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              multiline
              label={t('PRODUCTS.UNIT_PRICE')}
              helperText={error ? error.message : null}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'unitPriceInput' }}
            />
          )}
          rules={{
            pattern: {
              value: PRICE_REGEX,
              message: `${t('PRODUCTS.UNIT_PRICE')} ${t(
                'PRODUCTS.UNIT_PRICE_NUMERIC'
              )}`,
            },
          }}
        />
        <Controller
          name="unit"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              multiline
              label={t('PRODUCTS.UNIT')}
              helperText={error ? error.message : null}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'unitInput' }}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              rows={3}
              multiline
              label={t('PRODUCTS.DESCRIPTION')}
              helperText={error ? error.message : null}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'descriptionInput' }}
            />
          )}
          rules={{
            maxLength: {
              value: 10000,
              message: t('VALIDATION.MAX_LENGTH', { length: 10000 }),
            },
          }}
        />
        <Controller
          name="isActive"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Switch
                  onChange={onChange}
                  disabled={doesRoleExist(user, organization, ['OBSERVER'])}
                  value={value}
                  checked={value}
                />
              }
              sx={{ mt: 1, width: isMobile ? '100%' : '45%', marginRight: 0 }}
              label={t('PRODUCTS.IS_ACTIVE')}
            />
          )}
        />
        <Button
          type="submit"
          variant="outlined"
          data-testid="updateProductSubmit"
          disabled={!doesRoleExist(user, organization, ['ADMIN'])}
          sx={{
            marginTop: '20px',
            maxWidth: '200px',
            minWidth: '160px',
            height: '40px',
            alignSelf: 'flex-end',
          }}
        >
          {t('PRODUCT.UPDATE')}
        </Button>
      </form>
    </Box>
  );
}
