import React, { useEffect, useState } from 'react';
import { PieChart, Pie, ResponsiveContainer, Legend } from 'recharts';
import { Performance } from '../../../../models/performance.model';
import { useTranslation } from 'react-i18next';

interface DataItem {
  name: string;
  value: number;
  fill: string;
}

export default function CustomerActivityCountByTypeChart({
  performance,
}: {
  performance?: Performance;
}) {
  const { t } = useTranslation();

  const [data, setData] = useState<DataItem[]>([
    {
      name: t('SYSTEM_CUSTOMER_ACTIVITY_TYPES_DEMO_REQUEST'),
      value: 0,
      fill: '#0088FE',
    },
    {
      name: t('SYSTEM_CUSTOMER_ACTIVITY_TYPES_MEETING_REQUEST'),
      value: 0,
      fill: '#00C49F',
    },
  ]);

  useEffect(() => {
    setData((prevState) => {
      prevState[0].value =
        performance?.customerPerformance.customerActivityCountByType
          ?.demoRequest || 0;

      prevState[1].value =
        performance?.customerPerformance.customerActivityCountByType
          ?.meetingRequest || 0;

      return prevState;
    });
  }, [performance]);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <PieChart width={200} height={200}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          label
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
}
