import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { camelCase, mapKeys } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../../axios';
import MainDialog from '../../components/MainDialog';

import { LinearProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { useAuth } from '../../context/AuthContext';
import VerifyEmailCard from '../SignUpPage/VerifyEmailCard';
import SignInForm, { SignInFormInput } from './SignInForm';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SignInPage() {
  const { control, handleSubmit } = useForm<SignInFormInput>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [notVerifiedYet, setNotVerifiedYet] = useState(false);
  const [email, setUserEmail] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const { t } = useTranslation();

  const { setUser } = useAuth();

  const history = useHistory();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (query.get('verificationSuccess')) {
      setAlertOpen(true);
      setAlertMessage('VERIFICATION.SUCCESS');
    }

    if (query.get('isPasswordReset')) {
      setAlertOpen(true);
      setAlertMessage('FORGOT_PASSWORD.SUCCESS');
    }
  }, [query]);

  async function success(position: any) {
    await axios.post('/api/me/location', {
      lat: position.coords.latitude.toString(),
      long: position.coords.longitude.toString(),
    });
  }

  const onSuccessfullyLogin = async () => {
    const resp = await axios.get('api/me');
    const userObj = mapKeys(resp.data.data, (_v, k) => camelCase(k));

    userObj.organizationRoleMap = userObj.organizations.map(
      (organization: any) => ({
        organizationId: organization.id,
        role: organization.pivot.role,
      })
    );

    localStorage.setItem('user', JSON.stringify(userObj));

    setUser(userObj);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, (err) => {
        console.error(err);
      });
    }

    history.push('/app');
  };

  const onSubmit: SubmitHandler<SignInFormInput> = async (
    data: SignInFormInput
  ) => {
    setIsLoading(true);
    try {
      setUserEmail(data.email);
      await axios('sanctum/csrf-cookie');
      await axios.post('api/auth/login', {
        email: data.email,

        password: data.password,
      });

      await onSuccessfullyLogin();
    } catch (error) {
      const err = error as AxiosError;

      if (err.response?.status === 400) {
        const { error: respDataErr } = err?.response?.data as { error: string };

        if (respDataErr === 'already-logged-in') {
          await onSuccessfullyLogin();
          return;
        }

        setDialogContent(t('SIGNIN.WRONG_CREDENTIALS'));
        setIsDialogOpen(true);
      } else if (err.response?.status === 403) {
        setNotVerifiedYet(true);
      } else {
        setDialogContent(t('MAIN.SERVER_ERROR'));
        setIsDialogOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    /* istanbul ignore next */
    if (reason === 'clickaway') {
      return;
    }

    /* istanbul ignore next */
    setAlertOpen(false);
  };

  return (
    <>
      <Box
        data-testid="signinPage"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh',
        }}
      >
        {isLoading && (
          <LinearProgress
            sx={{ position: 'absolute', top: '0px', width: '100%' }}
          />
        )}
        {notVerifiedYet ? (
          <VerifyEmailCard email={email} />
        ) : (
          <SignInForm
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        )}
      </Box>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t(alertMessage)}
        </Alert>
      </Snackbar>
      <MainDialog
        open={isDialogOpen}
        onConfirm={() => {
          setIsDialogOpen(false);
        }}
        content={dialogContent}
        ok={t('SIGNIN.OK')}
      />
    </>
  );
}
