import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import Box from '@mui/material/Box';
import { useAuth } from '../../context/AuthContext';
import { Card, CardContent, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

export default function VerificationPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { setUser } = useAuth();
  const [isSuccess, setIsSuccess] = useState(true);

  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const handleSuccess = async () => {
      try {
        await axios.post('api/auth/logout');
      } finally {
        setUser(null);
        localStorage.removeItem('user');
        history.push('/signin?verificationSuccess=true');
      }
    };
    const status = query.get('status');
    if (status === 'success') {
      handleSuccess();
    } else {
      setIsSuccess(false);
    }
  }, [query, history, setUser]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh',
      }}
    >
      <Card
        sx={{
          maxWidth: '30%',
        }}
        data-testid="verification-card"
      >
        <CardContent>
          <Typography variant="h5" component="div">
            {t('VERIFICATION.TITLE')}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginTop: '15px',
            }}
          >
            {isSuccess ? t('VERIFICATION.SUCCESS') : t('VERIFICATION.FAILED')}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
