import axios from '../../../axios';
import React from 'react';
import { useAuth } from '../../../context/AuthContext';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { InputLabel, Select, FormControl, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSystemSettings } from '../../../context/SystemSettingsContext';

const CustomersPage = () => {
  interface Filters {
    type: string;
    industry: string;
    size: string;
    startDate: string | null;
    endDate: string | null;
  }

  const [filters, setFilters] = React.useState<Filters>({
    type: 'All',
    industry: 'All',
    size: 'All',
    startDate: null,
    endDate: null,
  });

  const [activities, setActivities] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    limit: 10,
    page: 0,
  });
  const [sortField, setSortField] = React.useState('id:desc');

  const { organization } = useAuth();
  const { t } = useTranslation();
  const { industries } = useSystemSettings();

  const fetchActivities = React.useCallback(async () => {
    const params = {
      pagination: {
        enabled: 1,
        limit: pagination?.limit,
        page: pagination?.page + 1,
        sort: sortField,
      },
    } as any;

    if (filters?.type !== 'All') {
      params.filters = params.filters || {};
      params.filters.type = filters.type;
    }

    if (filters?.industry !== 'All') {
      params.filters = params.filters || {};
      params.filters.industry = filters.industry;
    }

    if (filters?.size !== 'All') {
      params.filters = params.filters || {};
      params.filters.size = filters.size;
    }

    const { data: { data: { items = [], total_count = 0 } = {} } = {} } =
      await axios.get(`api/organizations/${organization?.id}/customers`, {
        params,
      });

    setActivities(items);

    if (totalCount !== total_count) {
      setTotalCount(total_count);
    }
  }, [organization, pagination, totalCount, filters, sortField]);

  const columns: GridColDef<(typeof activities)[number]>[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'account_manager',
      headerName: t(`CUSTOMERS.CUSTOMER_ACCOUNT_MANAGER`),
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (_val, row: any) => {
        if (!row.manager) return '';
        return `${row?.manager?.first_name} ${row?.manager?.last_name}`;
      },
    },
    {
      field: 'full_name',
      headerName: t(`CUSTOMERS.CUSTOMER_FULL_NAME`),
      width: 150,
      editable: false,
    },
    {
      field: 'short_name',
      headerName: t(`CUSTOMERS.CUSTOMER_SHORT_NAME`),
      width: 150,
      editable: false,
    },
    {
      field: 'authorized_person_name',
      headerName: t(`CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_NAME`),
      width: 150,
      editable: false,
    },
    {
      field: 'authorized_person_phone',
      headerName: t(`CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_PHONE`),
      width: 150,
      editable: false,
    },
    {
      field: 'authorized_person_email',
      headerName: t(`CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_EMAIL`),
      width: 150,
      editable: false,
    },
    {
      field: 'type',
      headerName: t(`CUSTOMERS.CUSTOMER_TYPE`),
      width: 150,
      editable: false,
    },
    {
      field: 'industry',
      headerName: t(`CUSTOMERS.CUSTOMER_INDUSTRY`),
      width: 150,
      editable: false,
    },
    {
      field: 'size',
      headerName: t(`CREATE_ORGANIZATION.SIZE`),
      width: 150,
      editable: false,
    },
    {
      field: 'last_activity',
      headerName: t(`CUSTOMERS.CUSTOMER_DETAILS.LAST_ACTIVITY`),
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (_v, row: any) => {
        if (!row.last_activity) return '';
        return `${row.last_activity?.type} - ${row.last_activity?.channel} - ${row.last_activity?.date}`;
      },
    },
  ];

  React.useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  return (
    <Box>
      <FormControl sx={{ width: 200 }}>
        <InputLabel id="filters-type-label">
          {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_TYPE')}
        </InputLabel>
        <Select
          labelId="filters-type-label"
          id="filters-type-select"
          value={filters?.type}
          label={t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_TYPE')}
          onChange={(e) => {
            setFilters({
              ...filters,
              type: e.target.value,
            });
          }}
        >
          <MenuItem value="All">{t(`FILTERS.ALL`)}</MenuItem>
          <MenuItem value="potential">
            {t(`SYSTEM_CUSTOMER_TYPES_POTENTIAL`)}
          </MenuItem>
          <MenuItem value="active">
            {t(`SYSTEM_CUSTOMER_TYPES_ACTIVE`)}
          </MenuItem>
          <MenuItem value="passive">
            {t(`SYSTEM_CUSTOMER_TYPES_PASSIVE`)}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: 200, ml: 2 }}>
        <InputLabel id="filters-industry-label">
          {t('CREATE_ORGANIZATION.SECTOR')}
        </InputLabel>
        <Select
          labelId="filters-industry-label"
          id="filters-industry-select"
          value={filters?.industry}
          data-testid={'organizationSectorInput'}
          label={t('CREATE_ORGANIZATION.SECTOR')}
          onChange={(e) => {
            setFilters({
              ...filters,
              industry: e.target.value,
            });
          }}
        >
          <MenuItem value="All">{t(`FILTERS.ALL`)}</MenuItem>
          {industries?.map((industry: string) => (
            <MenuItem value={industry} key={industry}>
              {industry}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 200, ml: 2 }}>
        <InputLabel id="demo-simple-select-label">
          {t('CREATE_ORGANIZATION.SIZE')}
        </InputLabel>
        <Select
          labelId="filters-size-label"
          id="filters-size-select"
          value={filters?.size}
          label={t('CREATE_ORGANIZATION.SIZE')}
          onChange={(e) => {
            setFilters({
              ...filters,
              size: e.target.value,
            });
          }}
          inputProps={{ 'data-testid': 'organizationSizeInput' }}
        >
          <MenuItem value="All">{t(`FILTERS.ALL`)}</MenuItem>
          <MenuItem value={'1-10'}>1 - 10</MenuItem>
          <MenuItem value={'10-50'}>10 - 50</MenuItem>
          <MenuItem value={'50-100'}>50 - 100</MenuItem>
          <MenuItem value={'100+'}>100+</MenuItem>
        </Select>
      </FormControl>{' '}
      <DataGrid
        rows={activities}
        columns={columns}
        paginationModel={{
          pageSize: pagination?.limit,
          page: pagination?.page,
        }}
        onPaginationModelChange={(params) => {
          if (params.pageSize !== pagination.limit) {
            params.page = 0;
          }

          setPagination({
            limit: params.pageSize,
            page: params.page,
          });
        }}
        sortingMode="server"
        onSortModelChange={([sortObj]) => {
          setSortField(`${sortObj?.field}:${sortObj?.sort}`);
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        rowCount={totalCount || 0}
        paginationMode="server"
        sx={{ minHeight: 500 }}
      />
    </Box>
  );
};

export default CustomersPage;
