import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  alpha,
  IconButton,
  Skeleton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { times } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import doesRoleExist from '../../../utils/doesRoleExist';
import { getComparator, Order } from '../../../utils/tableHelpers';

export interface Data {
  id: string;
  productName: string;
  productSerialNumber: string;
  quantity: string;
  price: string;
  taxRate: string;
  taxAmount: string;
  totalAmount: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ORDER_ITEMS.ID',
  },
  {
    id: 'productName',
    numeric: false,
    disablePadding: true,
    label: 'ORDER_ITEMS.PRODUCT',
  },
  {
    id: 'productSerialNumber',
    numeric: false,
    disablePadding: true,
    label: 'ORDER_ITEMS.PRODUCT_NUMBER',
  },
  {
    id: 'quantity',
    numeric: false,
    disablePadding: false,
    label: 'ORDER_ITEMS.QUANTITY',
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: false,
    label: 'ORDER_ITEMS.PRICE',
  },
  {
    id: 'taxRate',
    numeric: false,
    disablePadding: false,
    label: 'ORDER_ITEMS.TAX_RATE',
  },
  {
    id: 'taxAmount',
    numeric: false,
    disablePadding: false,
    label: 'ORDER_ITEMS.TAX_AMOUNT',
  },
  {
    id: 'totalAmount',
    numeric: false,
    disablePadding: false,
    label: 'ORDER_ITEMS.TOTAL_AMOUNT',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  name: string;
  orderItemId: string;
  deleteOrderItem: (orderItemId: string) => void;
  openEditOrderItemDialog: () => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    name,
    orderItemId,
    deleteOrderItem,
    openEditOrderItemDialog,
  } = props;
  const { t } = useTranslation();

  const { user, organization } = useAuth();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {name} {t('ORGANIZATION.TEAM.SELECTED')}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t('ORDER_DETAILS.ORDER_ITEMS')}
        </Typography>
      )}
      {numSelected > 0 && !doesRoleExist(user, organization, ['OBSERVER']) && (
        <>
          <Tooltip title={t('ORDER_DETAILS.ORDER_ITEMS_EDIT')}>
            <IconButton
              onClick={openEditOrderItemDialog}
              data-testid="editOrderItem"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('ORDER_DETAILS.ORDER_ITEMS_DELETE')}>
            <IconButton
              onClick={() => deleteOrderItem(orderItemId)}
              data-testid="deleteOrderItem"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
}

export default function OrderItemsTable({
  rows,
  isLoading,
  deleteOrderItem,
  openEditOrderItemDialog,
}: {
  rows: Data[];
  isLoading: boolean;
  deleteOrderItem: (documentId: string) => void;
  openEditOrderItemDialog: (orderItemId: string) => void;
}) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<string>('');
  const [selectedOrderId, setSelectedOrderId] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    if (!rows.find((row) => row.id === selected)) {
      setSelected('');
      setSelectedOrderId('');
    }
  }, [rows, selected]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    setSelected((prevSelected) => {
      return prevSelected !== id ? id : '';
    });
    const row = rows.find((row) => row.id === id);
    setSelectedOrderId(row?.id || '');
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Row = ({
    row,
    isItemSelected,
    labelId,
  }: {
    row: Data;
    isItemSelected: boolean;
    labelId: string;
  }) => {
    return (
      <>
        <TableRow
          hover
          onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          data-testid={row.id}
          selected={isItemSelected}
          sx={{ cursor: 'pointer' }}
        >
          <TableCell component="th" id={labelId} scope="row">
            {row.id}
          </TableCell>
          <TableCell align="left">{row.productName}</TableCell>
          <TableCell align="left">{row.productSerialNumber}</TableCell>
          <TableCell align="left">{row.quantity}</TableCell>
          <TableCell align="left">{row.price}</TableCell>
          <TableCell align="left">{row.taxRate}</TableCell>
          <TableCell align="left">{row.taxAmount}</TableCell>
          <TableCell align="left">{row.totalAmount}</TableCell>
        </TableRow>
      </>
    );
  };

  const isSelected = ($id: string) => selected === $id;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  let rowDisplay;
  if (isLoading) {
    rowDisplay = times(3, (index) => {
      return (
        <TableRow key={index}>
          {times(9, (index) => (
            <TableCell align="left" key={index}>
              <Skeleton animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  } else {
    rowDisplay = rows
      .sort(getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <Row
            key={row.id}
            row={row}
            isItemSelected={isItemSelected}
            labelId={labelId}
          />
        );
      });
  }

  return (
    <Box sx={{ width: '100%' }} data-testid="orderItemsTable">
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          name={selectedOrderId}
          orderItemId={selected}
          deleteOrderItem={deleteOrderItem}
          openEditOrderItemDialog={() => openEditOrderItemDialog(selected)}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rowDisplay}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          data-testid="rowsPerPage"
        />
      </Paper>
    </Box>
  );
}
