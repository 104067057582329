import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

// Response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/app/404';
    }
    if (
      error.response &&
      error.response.status === 500 &&
      error.response?.data?.error?.message === 'Unauthenticated.'
    ) {
      window.location.href = '/app/404';
    }
    return Promise.reject(error);
  }
);

export default instance;
