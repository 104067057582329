import { Alert, AlertColor, Snackbar } from '@mui/material';
import { createContext, ReactNode, useContext, useState } from 'react';

interface NotificationContextProps {
  showMessage?: null | any;
}

export const NotificationContext = createContext<NotificationContextProps>({
  showMessage: null,
});

export const useNotification = () => useContext(NotificationContext);

interface ProviderProps {
  children?: ReactNode;
}

const NotificationProvider = ({ children }: ProviderProps) => {
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [autohideDuration, setAutohideDuration] = useState(10000);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');
  const showMessage = ({
    message,
    severity = 'success',
    autohideDuration = 10000,
  }: {
    message: string;
    severity?: AlertColor;
    autohideDuration?: number;
  }) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setAutohideDuration(autohideDuration);

    setTimeout(() => {
      setSnackbarOpen(true);
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        showMessage,
      }}
    >
      <Snackbar
        key="notification-context-snackbar"
        open={isSnackbarOpen}
        autoHideDuration={autohideDuration}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          key="notification-context-alert"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
