import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import Title from './Title';
import { Performance } from '../../../../models/performance.model';
import { useTranslation } from 'react-i18next';

export default function RevenueByMonths({
  performance,
}: {
  performance?: Performance;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title> {t('REVENUE_TITLE')}</Title>
      <ResponsiveContainer width="100%" height={160}>
        <LineChart
          data={performance?.orderPerformance.orderByMonth.map((or) => ({
            time: or.month,
            amount: or.revenue,
          }))}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis
            dataKey="time"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          ></YAxis>
          <Line
            isAnimationActive={true}
            type="monotone"
            dataKey="amount"
            stroke={theme.palette.primary.main}
            dot={true}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
