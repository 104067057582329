import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  LinearProgress,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import axios from '../../../axios';
import { mapKeys, camelCase } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { Order } from '../../../models/order.model';
import OrderDetailsCard from './OrderDetailsCard';
import CustomerDetailsCard from './CustomerDetailsCard';
import { OrderItem } from '../../../models/orderItem.model';
import OrderItemsCard from './OrderItemsCard';
import { useTranslation } from 'react-i18next';

export default function OrderDisplayPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  let { token }: { token: string } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [order, setOrder] = useState<Order>();
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrder = useCallback(async (token: string) => {
    setIsLoading(true);
    const orderResp = await axios.get(`api/public/orders/${token}`);
    const order = new Order();
    orderResp.data.data.customer = mapKeys(
      orderResp.data.data.customer,
      (v, k) => camelCase(k)
    );
    orderResp.data.data.owner = mapKeys(orderResp.data.data.owner, (v, k) =>
      camelCase(k)
    );

    orderResp.data.data.items = orderResp.data.data.items.map(
      (item: OrderItem, index: number) => {
        orderResp.data.data.items[index].product = mapKeys(
          orderResp.data.data.items[index].product,
          (v, k) => camelCase(k)
        );
        return mapKeys(item, (v, k) => camelCase(k));
      }
    );
    setOrderItems(orderResp.data.data.items);
    order.deserialize(mapKeys(orderResp.data.data, (v, k) => camelCase(k)));
    setOrder(order);
    setIsLoading(false);
  }, []);

  const downloadOrderPdf = useCallback(async () => {
    const response = await axios.post(
      `api/public/orders/${token}/generate-pdf`,
      {},
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
        },
      }
    );
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${order?.code}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [token, order?.code]);

  useEffect(() => {
    fetchOrder(token);
  }, [fetchOrder, token]);

  return (
    <>
      <Box
        sx={{
          height: theme.mixins.toolbar.minHeight,
          backgroundColor: '#e96e06',
          color: theme.palette.common.white,
          display: 'flex',
        }}
        data-testid="orderDisplayPage"
      >
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          data-testid="appLink"
          onClick={() => {
            history.push('/app');
          }}
          sx={{ cursor: 'pointer', alignSelf: 'center', marginLeft: 4 }}
        >
          ECOSH
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', overflowX: 'auto' }}
        data-testid="profilePage"
      >
        <CssBaseline />
        {isLoading && <LinearProgress sx={{ width: '100%' }} />}

        <Box
          display="flex"
          flexDirection="row"
          gap="20px"
          flexWrap="wrap"
          data-testid="orderDetailsPage"
        >
          <Box
            sx={{
              width: isMobile ? '100%' : '80%',
              display: 'flex',
              alignItems: 'stretch',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              margin: 'auto',
              marginTop: 4,
            }}
          >
            {isLoading ? (
              <Skeleton animation="wave" />
            ) : (
              <>
                <Typography
                  variant="h5"
                  mb={1.5}
                  sx={{
                    color: 'darkgray',
                    paddingLeft: '24px',
                    paddingTop: '12px',
                  }}
                >
                  {order?.code}
                </Typography>
                <Button
                  variant="outlined"
                  data-testid="downloadOrderPdf"
                  onClick={downloadOrderPdf}
                  sx={{
                    maxHeight: '42px',
                    alignSelf: isMobile ? 'flex-start' : 'center',
                    marginLeft: isMobile ? '24px' : '0px',
                  }}
                >
                  {t('ORDER_DETAILS.DOWNLOAD')}
                </Button>
              </>
            )}
          </Box>

          {!isLoading && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <OrderDetailsCard order={order} />
              <CustomerDetailsCard customer={order?.customer} />
              <OrderItemsCard orderItems={orderItems} order={order} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
