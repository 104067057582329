import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { OrderItem } from '../../../models/orderItem.model';

export default function OrderItemsDetailsTable({
  orderItems,
}: {
  orderItems: OrderItem[];
}) {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('ORDER_ITEMS.ID')}</TableCell>
            <TableCell align="right">{t('ORDER_ITEMS.PRODUCT')}</TableCell>
            <TableCell align="right">
              {t('ORDER_ITEMS.PRODUCT_NUMBER')}
            </TableCell>
            <TableCell align="right">{t('ORDER_ITEMS.PRICE')}</TableCell>
            <TableCell align="right">{t('ORDER_ITEMS.QUANTITY')}</TableCell>
            <TableCell align="right">{t('ORDER_ITEMS.TAX_RATE')}</TableCell>
            <TableCell align="right">{t('ORDER_ITEMS.TAX_AMOUNT')}</TableCell>
            <TableCell align="right">{t('ORDER_ITEMS.TOTAL_AMOUNT')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderItems.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                {row.product.name}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                {row.product.serialNumber}
              </TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">{row.taxRate}</TableCell>
              <TableCell align="right">{row.taxAmount}</TableCell>
              <TableCell align="right">{row.totalAmount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
