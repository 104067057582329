import { Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormHandleSubmit,
  useForm,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Logo, { LogoVariants } from '../../components/Logo';

export interface CreateUserFormInput {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  password: string;
}

export default function CreateUserForm(props: {
  control: Control<CreateUserFormInput>;
  handleSubmit: UseFormHandleSubmit<CreateUserFormInput>;
  onSubmit: SubmitHandler<CreateUserFormInput>;
  isLoading: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { control, handleSubmit } = useForm<CreateUserFormInput>();

  const { onSubmit, isLoading } = props;
  return (
    <Card sx={{ width: isMobile ? 'auto' : '450px' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            padding: '10px',
            minWidth: '300px',
          }}
        >
          <Box
            sx={{
              marginBottom: '20px',
              textAlign: 'center',
            }}
          >
            <Logo variant={LogoVariants.small} />
            <Typography
              variant="h6"
              sx={{
                marginTop: '10px',
              }}
              data-testid="signInHeader"
            >
              {t('SIGNIN.SIGNUP')}
            </Typography>
          </Box>

          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'contents' }}
            autoComplete="off"
          >
            <Box
              display="flex"
              flexDirection="column"
              sx={{ paddingLeft: '8px', paddingRight: '8px' }}
            >
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    margin="normal"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    label={t('SIGNUP.NAME')}
                    helperText={error ? error.message : null}
                    inputProps={{ 'data-testid': 'nameInput' }}
                  />
                )}
                rules={{
                  required: `${t('SIGNUP.NAME')} ${t('SIGNUP.REQUIRED')}`,

                  maxLength: {
                    value: 50,
                    message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                  },
                }}
              />
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    margin="normal"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    label={t('SIGNUP.SURNAME')}
                    helperText={error ? error.message : null}
                    inputProps={{ 'data-testid': 'surnameInput' }}
                  />
                )}
                rules={{
                  required: `${t('SIGNUP.SURNAME')} ${t('SIGNUP.REQUIRED')}`,

                  maxLength: {
                    value: 50,
                    message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                  },
                }}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    margin="normal"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    label={t('SIGNUP.EMAIL')}
                    helperText={error ? error.message : null}
                    inputProps={{ 'data-testid': 'emailInput' }}
                  />
                )}
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('SIGNUP.EMAIL.INVALID'),
                  },
                  required: `${t('SIGNUP.EMAIL')} ${t('SIGNUP.REQUIRED')}`,
                }}
              />

              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    margin="normal"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    label={t('SIGNUP.PHONE')}
                    helperText={
                      error ? error.message : t('SIGNUP.PHONE.EXAMPLE')
                    }
                    inputProps={{ 'data-testid': 'phoneInput' }}
                  />
                )}
                rules={{
                  pattern: {
                    value:
                      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                    message: t('SIGNUP.PHONE.INVALID'),
                  },
                  required: `${t('SIGNUP.PHONE')} ${t('SIGNUP.REQUIRED')}`,

                  maxLength: {
                    value: 50,
                    message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                  },
                }}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    margin="normal"
                    type="password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    label={t('SIGNUP.PASSWORD')}
                    helperText={error ? error.message : null}
                    inputProps={{ 'data-testid': 'passwordInput' }}
                  />
                )}
                rules={{
                  required: `${t('SIGNUP.PASSWORD')} ${t('SIGNUP.REQUIRED')}`,
                  minLength: { value: 8, message: t('SIGNUP.PASSWORD.MIN') },

                  maxLength: {
                    value: 20,
                    message: t('VALIDATION.MAX_LENGTH', { length: 20 }),
                  },
                }}
              />
            </Box>

            <Typography
              variant="caption"
              sx={{ margin: '8px', color: 'dimgray' }}
            >
              <Trans
                components={{
                  Terms: (
                    <a target="_blank" href="/">
                      link
                    </a>
                  ),
                  Privacy: (
                    <a target="_blank" href="/">
                      link
                    </a>
                  ),
                  Cookies: (
                    <a target="_blank" href="/">
                      link
                    </a>
                  ),
                }}
              >
                SIGNUP.MESSAGE
              </Trans>
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ marginRight: '8px', marginTop: '24px' }}
            >
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  history.push('/signin');
                }}
                data-testid="signinRedirectButton"
              >
                {t('SIGNUP.SIGNIN_REDIRECT')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                data-testid="createUserSubmitButton"
                disabled={isLoading}
              >
                {t('SIGNUP.SAVE')}
              </Button>
            </Box>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}
