import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Performance } from '../../../../models/performance.model';

interface DataItem {
  name: string;
  value: number;
  fill: string;
}

export default function CustomerCountByTypeChart({
  performance,
}: {
  performance?: Performance;
}) {
  const { t } = useTranslation();

  const [data, setData] = useState<DataItem[]>([
    { name: t('SYSTEM_CUSTOMER_TYPES_POTENTIAL'), value: 0, fill: '#0088FE' },
    {
      name: t('SYSTEM_CUSTOMER_TYPES_ACTIVE'),
      value: 0,
      fill: '#00C49F',
    },
    { name: t('SYSTEM_CUSTOMER_TYPES_PASSIVE'), value: 0, fill: '#FFBB28' },
  ]);

  useEffect(() => {
    setData((prevState) => {
      prevState[0].value =
        performance?.customerPerformance?.customerCountByType?.potential || 0;

      prevState[1].value =
        performance?.customerPerformance?.customerCountByType?.active || 0;

      prevState[2].value =
        performance?.customerPerformance?.customerCountByType?.passive || 0;

      return prevState;
    });
  }, [performance]);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart width={200} height={200} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" />
      </BarChart>
    </ResponsiveContainer>
  );
}
