import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import StoreIcon from '@mui/icons-material/Store';
import { Controller, useForm } from 'react-hook-form';
import {
  Avatar,
  Card,
  CardHeader,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { get, each } from 'lodash';
import { Order } from '../../../models/order.model';

export interface OrderDetailsInput {
  id: string;
  code: string;
  customerName: string;
  orderDate: string;
  owner: string;
}

export default function OrderDetailsCard(props: { order?: Order }) {
  const { order } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  type formValue = 'id' | 'code' | 'customerName' | 'orderDate' | 'owner';

  const { control, setValue } = useForm<OrderDetailsInput>();

  useEffect(() => {
    const fillTheValues = async () => {
      if (order) {
        const fields = ['id', 'code', 'customerName', 'orderDate'];

        each(fields, (field) => {
          const value = get(order, field);
          if (value !== null && value !== undefined) {
            setValue(field as formValue, get(order, field));
          }
        });
        setValue('customerName', order.customer.shortName);
        setValue('owner', `${order?.owner.firstName} ${order?.owner.lastName}`);
      }
    };

    fillTheValues();
  }, [order, setValue]);

  return (
    <Card sx={{ width: '80%', padding: 4 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="">
            <StoreIcon />
          </Avatar>
        }
        title={
          <Typography
            variant="h5"
            component="div"
            sx={{ color: theme.palette.grey[600] }}
          >
            {t('ORDER_DETAILS.ORDER_DETAILS')}
          </Typography>
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          justifyContent: 'space-between',
          padding: '10px',
          flexWrap: 'wrap',
          gap: '12px',
        }}
      >
        <Controller
          name="code"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('ORDERS.CODE')}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'codeInput' }}
            />
          )}
        />
        <Controller
          name="customerName"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('ORDERS.CUSTOMER')}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'codeInput' }}
            />
          )}
        />
        <Controller
          name="orderDate"
          control={control}
          defaultValue={new Date().toDateString()}
          render={({ field: { ref, onChange, value, ...field } }) => (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              value={value}
              onChange={onChange}
              label={t('ORDERS.ORDER_DATE')}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'codeInput' }}
            />
          )}
        />
        <Controller
          name="owner"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              InputProps={{
                readOnly: true,
              }}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('ORDER.OWNER')}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'codeInput' }}
            />
          )}
        />
      </Box>
    </Card>
  );
}
