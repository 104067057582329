import { Typography } from '@mui/material';

export enum LogoVariants {
  small = 'h6',
  big = 'h2',
  medium = 'h4',
}

interface LogoProps {
  variant: LogoVariants;
}

export default function Logo({ variant }: LogoProps) {
  return (
    <Typography variant={variant} sx={{ color: '#e96e06' }}>
      ECOSH
    </Typography>
  );
}
