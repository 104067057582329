import React from 'react';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form';
import Logo, { LogoVariants } from '../../components/Logo';

export interface SignInFormInput {
  email: string;
  password: string;
}

export default function SignInForm(props: {
  control: Control<SignInFormInput>;
  handleSubmit: UseFormHandleSubmit<SignInFormInput>;
  onSubmit: SubmitHandler<SignInFormInput>;
  isLoading: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { control, handleSubmit, onSubmit, isLoading } = props;

  const onForgotPassword = async () => {
    history.push('/forgotPassword');
  };

  return (
    <>
      <Card sx={{ width: isMobile ? 'auto' : '450px' }}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              padding: '10px',
              minWidth: '300px',
            }}
          >
            <Box
              sx={{
                marginBottom: '20px',
                textAlign: 'center',
              }}
            >
              <Logo variant={LogoVariants.small} />
              <Typography
                variant="h6"
                sx={{
                  marginTop: '10px',
                }}
                data-testid="signInHeader"
              >
                {t('SIGNIN.SIGNIN')}
              </Typography>
            </Box>

            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: 'contents' }}
            >
              <Box
                display="flex"
                flexDirection="column"
                sx={{ paddingLeft: '8px', paddingRight: '8px' }}
              >
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      label={t('SIGNIN.EMAIL')}
                      helperText={error ? error.message : null}
                      sx={{
                        marginBottom: '10px',
                      }}
                      inputProps={{ 'data-testid': 'emailInput' }}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('SIGNIN.EMAIL.INVALID'),
                    },
                    required: `${t('SIGNIN.EMAIL')} ${t('SIGNIN.REQUIRED')}`,
                  }}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      type="password"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      label={t('SIGNIN.PASSWORD')}
                      helperText={error ? error.message : null}
                      inputProps={{ 'data-testid': 'passwordInput' }}
                    />
                  )}
                  rules={{
                    required: `${t('SIGNIN.PASSWORD')} ${t('SIGNIN.REQUIRED')}`,
                    minLength: {
                      value: 8,
                      message: `${t('SIGNIN.PASSWORD')} ${t('SIGNIN.MIN', {
                        length: 8,
                      })}`,
                    },
                  }}
                />
              </Box>

              <Button
                sx={{
                  marginBottom: '8px',
                  width: 'fit-content',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
                variant="text"
                size="small"
                data-testid="forgotPasswordButton"
                onClick={onForgotPassword}
              >
                {t('SIGNIN.FORGOT_PASSWORD')}
              </Button>

              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ marginRight: '8px', marginTop: '32px' }}
              >
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    history.push('/signup');
                  }}
                  data-testid="signupRedirectButton"
                >
                  {t('SIGNIN.SIGNUP_REDIRECT')}
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  data-testid="signInSubmitButton"
                  disabled={isLoading}
                >
                  {t('SIGNIN.SIGNIN')}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
