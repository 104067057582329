import axios from '../../../axios';
import React from 'react';
import { useAuth } from '../../../context/AuthContext';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const TasksPage = () => {
  interface Filters {
    priority: string | null;
    startDate: string | null;
    endDate: string | null;
  }

  const [filters, setFilters] = React.useState<Filters>({
    priority: 'All',
    startDate: null,
    endDate: null,
  });

  const [activities, setActivities] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    limit: 10,
    page: 0,
  });

  const [sortField, setSortField] = React.useState('id:desc');
  const { organization } = useAuth();
  const { t } = useTranslation();

  const fetchActivities = React.useCallback(async () => {
    const params = {
      filters: {},
      pagination: {
        enabled: 1,
        limit: pagination?.limit,
        page: pagination?.page + 1,
        sort: sortField,
      },
    } as any;

    if (filters?.priority !== 'All') {
      params.filters.priority = filters.priority;
    }

    if (filters?.startDate) {
      params.filters.due_date__$gte = filters.startDate;
    }

    if (filters?.endDate) {
      params.filters.due_date__$lte = filters.endDate;
    }

    const { data: { data: { items = [], total_count = 0 } = {} } = {} } =
      await axios.get(`api/organizations/${organization?.id}/tasks`, {
        params,
      });

    setActivities(items);

    if (totalCount !== total_count) {
      setTotalCount(total_count);
    }
  }, [organization, pagination, totalCount, filters, sortField]);

  const columns: GridColDef<(typeof activities)[number]>[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'title',
      headerName: t(`TASKS.TITLE`),
      width: 150,
      editable: false,
    },
    {
      field: 'description',
      headerName: t(`TASKS.DESCRIPTION`),
      width: 150,
      editable: false,
    },
    {
      field: 'customer_full_name',
      headerName: t(`CUSTOMERS.CUSTOMER_FULL_NAME`),
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (_val, row: any) => {
        if (!row.customer) return '';
        return `${row?.customer?.full_name}`;
      },
    },
    {
      field: 'assigned_team',
      headerName: t(`TASKS.CUSTOMER_ASSIGNED_TEAM`),
      width: 150,
      editable: false,

      sortable: false,
      valueGetter: (_val, row: any) => {
        if (!row.team) return '';
        return `${row?.team?.name}`;
      },
    },
    {
      field: 'assigned_user',
      headerName: t(`TASKS.CUSTOMER_ASSIGNED_USER`),
      width: 150,
      editable: false,

      sortable: false,
      valueGetter: (_val, row: any) => {
        if (!row.user) return '';
        return `${row?.user?.first_name} ${row?.user?.last_name}`;
      },
    },

    {
      field: 'due_date',
      headerName: t(`TASKS.DUE_DATE`),
      width: 150,
      editable: false,
    },
    {
      field: 'priority',
      headerName: t(`TASKS.PRIORITY`),
      width: 150,
      editable: false,
      valueGetter: (_val, row: any) => {
        if (!row.priority) return '';
        return t(`SYSTEM_PRIORITY_${row.priority.toUpperCase()}`);
      },
    },
  ];

  React.useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  return (
    <Box>
      <FormControl sx={{ width: 200 }}>
        <InputLabel id="filters-type-label">
          {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_TYPE')}
        </InputLabel>
        <Select
          labelId="filters-type-label"
          id="filters-type-select"
          value={filters?.priority}
          label={t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_TYPE')}
          onChange={(e) => {
            setFilters({
              ...filters,
              priority: e.target.value,
            });
          }}
        >
          <MenuItem value="All">{t(`FILTERS.ALL`)}</MenuItem>
          <MenuItem value="low">{t(`SYSTEM_PRIORITY_LOW`)}</MenuItem>
          <MenuItem value="medium">{t(`SYSTEM_PRIORITY_MEDIUM`)}</MenuItem>
          <MenuItem value="high">{t(`SYSTEM_PRIORITY_HIGH`)}</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ width: 200, ml: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={filters?.startDate}
            onChange={(date) => setFilters({ ...filters, startDate: date })}
            label={t('TASKS.DUE_DATE_FROM')}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>

      <FormControl sx={{ width: 200, ml: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={filters?.endDate}
            onChange={(date) => setFilters({ ...filters, endDate: date })}
            label={t('TASKS.DUE_DATE_TO')}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      <DataGrid
        rows={activities}
        columns={columns}
        paginationModel={{
          pageSize: pagination?.limit,
          page: pagination?.page,
        }}
        onPaginationModelChange={(params) => {
          if (params.pageSize !== pagination.limit) {
            params.page = 0;
          }

          setPagination({
            limit: params.pageSize,
            page: params.page,
          });
        }}
        sortingMode="server"
        onSortModelChange={([sortObj]) => {
          if (!sortObj?.field && !sortObj?.sort) {
            return;
          }

          setSortField(`${sortObj?.field}:${sortObj?.sort}`);
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        rowCount={totalCount || 0}
        paginationMode="server"
        sx={{ minHeight: 500 }}
      />
    </Box>
  );
};

export default TasksPage;
