import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { times } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Category } from '../../../models/category.model';
import { getComparator, Order } from '../../../utils/tableHelpers';

export interface Data {
  id: string;
  organizationId: string;
  categoryId: string;
  categoryName: string;
  name: string;
  description: string;
  serialNumber: string;
  unit: string;
  unitPrice: string;
  isActive: string | boolean;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'PRODUCTS.ID',
  },
  {
    id: 'serialNumber',
    numeric: false,
    disablePadding: true,
    label: 'PRODUCTS.SERIAL_NUMBER',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'PRODUCTS.NAME',
  },
  {
    id: 'unitPrice',
    numeric: false,
    disablePadding: false,
    label: 'PRODUCTS.UNIT_PRICE',
  },
  {
    id: 'unit',
    numeric: false,
    disablePadding: false,
    label: 'PRODUCTS.UNIT',
  },
  {
    id: 'categoryName',
    numeric: false,
    disablePadding: false,
    label: 'PRODUCTS.CATEGORY',
  },
  {
    id: 'isActive',
    numeric: false,
    disablePadding: false,
    label: 'PRODUCTS.IS_ACTIVE',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {['categoryName', 'serialNumber'].includes(headCell.id) ? (
              <span>{t(headCell.label)}</span>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.label)}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TasksTable({
  rows,
  setSelectedProduct,
  isLoading,
  categories,
}: {
  rows: Data[];
  setSelectedProduct: (productId: string) => void;
  isLoading: boolean;
  categories: Category[];
}) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('serialNumber');
  const [selected, setSelected] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    setSelected((prevSelected) => {
      return prevSelected !== id ? id : '';
    });
    const row = rows.find((row) => row.id === id);
    if (row) {
      setSelectedProduct(row.id);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Row = ({
    row,
    isItemSelected,
    labelId,
  }: {
    row: Data;
    isItemSelected: boolean;
    labelId: string;
  }) => {
    return (
      <>
        <TableRow
          hover
          onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          data-testid={row.id}
          selected={isItemSelected}
          sx={{ cursor: 'pointer' }}
        >
          <TableCell component="th" id={labelId} scope="row">
            {row.id}
          </TableCell>
          <TableCell align="left">{row.serialNumber}</TableCell>
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.unitPrice}</TableCell>
          <TableCell align="left">{row.unit}</TableCell>
          <TableCell align="left">
            {
              categories.find((category) => category.id === row.categoryId)
                ?.name
            }
          </TableCell>
          <TableCell align="left">{row.isActive.toString()}</TableCell>
        </TableRow>
      </>
    );
  };

  const isSelected = ($id: string) => selected.indexOf($id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  let rowDisplay;
  if (isLoading) {
    rowDisplay = times(3, (index) => {
      return (
        <TableRow key={index}>
          {times(8, (index) => (
            <TableCell align="left" key={index}>
              <Skeleton animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  } else {
    rowDisplay = rows
      .sort(getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <Row
            key={row.id}
            row={row}
            isItemSelected={isItemSelected}
            labelId={labelId}
          />
        );
      });
  }

  return (
    <Box sx={{ width: '100%' }} data-testid="productsTable">
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rowDisplay}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          data-testid="rowsPerPage"
        />
      </Paper>
    </Box>
  );
}
