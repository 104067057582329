import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Order } from '../../../models/order.model';
import { OrderItem } from '../../../models/orderItem.model';
import OrderItemsDetailsTable from './OrderItemsDetailsTable';

export default function OrderItemsCard(props: {
  orderItems?: OrderItem[];
  order?: Order;
}) {
  const { orderItems, order } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Card sx={{ width: '80%', padding: 4, mb: 4 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="">
            <ShoppingCartIcon />
          </Avatar>
        }
        title={
          <Typography
            variant="h5"
            component="div"
            sx={{ color: theme.palette.grey[600] }}
          >
            {t('ORDER_DETAILS.ORDER_ITEMS')}
          </Typography>
        }
      />
      {<OrderItemsDetailsTable orderItems={orderItems || []} />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          mr: 4,
          mt: 4,
          gap: 4,
        }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={{
              marginTop: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('ORDER_ITEMS.TAX_AMOUNT')} :
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('ORDERS.DISCOUNT')} :
          </Typography>

          <Typography
            variant="body1"
            sx={{
              marginTop: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('ORDER_ITEMS.TOTAL_AMOUNT')} :
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'end' }}>
          <Typography
            variant="body1"
            sx={{
              marginTop: '15px',
            }}
          >
            {order?.taxAmount}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: '15px',
            }}
          >
            {order?.totalDiscount || 0}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              marginTop: '15px',
            }}
          >
            {order?.totalAmount}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
