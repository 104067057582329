import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { get } from 'lodash';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  customerTypesTextMap,
  useSystemSettings,
} from '../../../context/SystemSettingsContext';
import { User } from '../../../models/user.model';
import { VALID_EMAIL_REGEX } from '../../../utils/regexHelpers';
import parsePhoneNumber from 'libphonenumber-js';

export interface AddCustomerInput {
  id: string;
  shortName: string;
  fullName: string;
  taxOffice: string;
  taxNumber: string;
  type: string;
  industry: string;
  authorizedPersonName: string;
  authorizedPersonEmail: string;
  authorizedPersonPhone: string;
  accountManagerId: string;
  size: string;
}

export default function AddACustomerDialog({
  isOpen,
  users,
  handleClose,
  onSubmit,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: SubmitHandler<AddCustomerInput>;
  users: User[];
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { industries, customerTypes } = useSystemSettings();

  const { reset, control, handleSubmit } = useForm<AddCustomerInput>();

  const onHandleClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  return (
    <Box>
      <Dialog open={isOpen} onClose={onHandleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DialogTitle>{t('CUSTOMERS.CREATE_NEW')}</DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Controller
              name="shortName"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('CUSTOMERS.CUSTOMER_SHORT_NAME')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'shortNameInput' }}
                />
              )}
              rules={{
                required: `${t('CUSTOMERS.CUSTOMER_SHORT_NAME')} ${t(
                  'CUSTOMERS.CUSTOMER.REQUIRED'
                )}`,
              }}
            />
            <Controller
              name="fullName"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('CUSTOMERS.CUSTOMER_FULL_NAME')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'fullNameInput' }}
                />
              )}
            />
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('CUSTOMERS.CUSTOMER_TYPE')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('CUSTOMERS.CUSTOMER_TYPE')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'customerTypeInput' }}
                  >
                    {customerTypes.map((type: string) => (
                      <MenuItem value={type} key={type}>
                        {t(get(customerTypesTextMap, type))}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('CUSTOMERS.CUSTOMER_TYPE')} ${t(
                          'CUSTOMERS.CUSTOMER.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <Controller
              name="size"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('CUSTOMERS.CUSTOMER_SIZE')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    data-testid="organizationSizeSelect"
                    value={value}
                    error={!!error}
                    label={t('CUSTOMERS.CUSTOMER_SIZE')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'customerSizeInput' }}
                  >
                    <MenuItem value={'1-10'}>1 - 10</MenuItem>
                    <MenuItem value={'10-50'}>10 - 50</MenuItem>
                    <MenuItem value={'50-100'}>50 - 100</MenuItem>
                    <MenuItem value={'100+'}>100+</MenuItem>
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('CUSTOMERS.CUSTOMER_SIZE')} ${t(
                          'CUSTOMERS.CUSTOMER.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <Controller
              name="industry"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('CUSTOMERS.CUSTOMER_INDUSTRY')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('CUSTOMERS.CUSTOMER_INDUSTRY')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'organizationSectorInput' }}
                  >
                    {industries.map((indust: string) => (
                      <MenuItem value={indust} key={indust}>
                        {indust}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('CUSTOMERS.CUSTOMER_INDUSTRY')} ${t(
                          'CUSTOMERS.CUSTOMER.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              name="taxOffice"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('CUSTOMERS.CUSTOMER_TAX_OFFICE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'addressLine2Input' }}
                />
              )}
              rules={{
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="taxNumber"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('CUSTOMERS.CUSTOMER_TAX_NUMBER')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'postalCodeInput' }}
                />
              )}
              rules={{
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="authorizedPersonName"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_NAME')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'cityInput' }}
                />
              )}
              rules={{
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
              }}
            />
            <Controller
              name="authorizedPersonEmail"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_EMAIL')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'countryInput' }}
                />
              )}
              rules={{
                pattern: {
                  value: VALID_EMAIL_REGEX,
                  message: t('CUSTOMERS.EMAIL.INVALID'),
                },
                maxLength: {
                  value: 100,
                  message: t('VALIDATION.MAX_LENGTH', { length: 100 }),
                },
              }}
            />
            <Controller
              name="authorizedPersonPhone"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_PHONE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'countryInput' }}
                />
              )}
              rules={{
                validate: {
                  isValidPhone: (value) => {
                    if (!value) {
                      return true;
                    }
                    try {
                      return parsePhoneNumber(value, 'TR')?.isValid()
                        ? true
                        : t('VALIDATION.INVALID_PHONE_NUMBER');
                    } catch (err) {
                      return t('VALIDATION.INVALID_PHONE_NUMBER');
                    }
                  },
                },
              }}
            />

            <Controller
              name="accountManagerId"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('CUSTOMERS.CUSTOMER_ACCOUNT_MANAGER')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    data-testid={'accountManagerSelect'}
                    value={value}
                    error={!!error}
                    label={t('CUSTOMERS.CUSTOMER_ACCOUNT_MANAGER')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'accountManagerIdInput' }}
                  >
                    {users.map((user, index) => {
                      return (
                        <MenuItem key={index} value={user.id}>
                          {user.firstName} {user.lastName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('CUSTOMERS.CUSTOMER_ACCOUNT_MANAGER')} ${t(
                          'CUSTOMERS.CUSTOMER.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onHandleClose} data-testid="cancelDialog">
              {t('CUSTOMERS.CUSTOMER.CREATE_NEW_CANCEL')}
            </Button>
            <Button type="submit" data-testid="createButton">
              {t('CUSTOMERS.CUSTOMER.CREATE_NEW_CREATE')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
