import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Title from './Title';

import { camelCase, mapKeys, orderBy, toUpper } from 'lodash';
import { useHistory } from 'react-router-dom';
import axios from '../../../../axios';
import { useAuth } from '../../../../context/AuthContext';
import { Order } from '../../../../models/order.model';

export interface Data {
  id: string;
  code: string;
  customerName: string;
  orderDate: string;
  totalAmount: string;
  totalDiscount: string;
  taxAmount: string;
  note: string;
  status: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.CODE',
  },
  {
    id: 'customerName',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.CUSTOMER',
  },
  {
    id: 'totalAmount',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.PRICE',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'ORDERS.STATUS',
  },
];

export default function RecentOrders() {
  const { t } = useTranslation();
  const [rows, setRows] = React.useState<Data[]>([]);
  const { organization } = useAuth();

  const fetchOrders = useCallback(async () => {
    const ordersResp = await axios.get(
      `api/organizations/${organization?.id}/orders?limit=10&page=1`
    );
    const ordersData = ordersResp.data.data.items.map((item: any) => {
      item.customer = mapKeys(item.customer, (v, k) => camelCase(k));
      return mapKeys(item, (v, k) => camelCase(k));
    });
    const data = ordersData.map((order: Order) => ({
      id: order.id.toString(),
      code: order.code,
      orderDate: order.orderDate,
      totalAmount: order.totalAmount,
      totalDiscount: order.totalDiscount,
      customerName: order.customer.shortName,
      taxAmount: order.taxAmount,
      note: order.note,
      status: order.status,
    }));
    setRows(data.slice(0, 10));
  }, [organization?.id]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const history = useHistory();

  return (
    <React.Fragment>
      <Title>{t('RECENT_ORDERS')}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.label}>{t(headCell.label)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.code}
              hover
              onClick={() => {
                history.push(`./order/${row.id}`);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell>{row.code}</TableCell>
              <TableCell>{row.customerName}</TableCell>
              <TableCell>{row.totalAmount}</TableCell>
              <TableCell>{t(`ORDERS.STATUS_${toUpper(row.status)}`)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
