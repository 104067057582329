import Box from '@mui/material/Box';
import { camelCase, mapKeys, snakeCase } from 'lodash';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { Organization } from '../../../models/organization.model';
import CreateCompanyForm, {
  CreateOrganizationFormInput,
} from './CreateOrganizationForm';

interface OrganizationData {
  name: string;
  size: string;
  industry: string;
}

export default function CreateOrganizationPage() {
  const history = useHistory();
  const { user, setOrganization, setOrganizations, setUser } = useAuth();

  const formSubmitHandler = async (formValues: OrganizationData) => {
    const newOrganizationResp = await axios.post(
      'api/organizations',
      mapKeys(formValues, (_, k) => snakeCase(k))
    );
    // const organizationsResp = await axios.get('api/organizations');

    const resp = await axios.get('api/me');
    const organizations = resp.data.data.organizations;
    const selectedOrganization = organizations.find(
      (org: Organization) => org.id === newOrganizationResp.data.data.id
    );

    setOrganizations(organizations);
    setOrganization(selectedOrganization);

    const userObj = mapKeys(resp.data.data, (_, k) => camelCase(k));
    userObj.organizationRoleMap = userObj.organizations.map(
      (organization: any) => ({
        organizationId: organization.id,
        role: organization.pivot.role,
      })
    );
    localStorage.setItem('user', JSON.stringify(userObj));
    setUser(userObj);
    history.push('/app');
  };

  const onOrganizationFormSubmit: SubmitHandler<
    CreateOrganizationFormInput
  > = async (data: CreateOrganizationFormInput) => {
    const formValues = {
      owner: user?.email,
      name: data.organizationName,
      size: data.size,
      industry: data.industry,
      currency: data.currency,
    };
    await formSubmitHandler(formValues);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh',
      }}
      data-testid="createOrganizationForm"
    >
      <CreateCompanyForm onSubmit={onOrganizationFormSubmit} />
    </Box>
  );
}
