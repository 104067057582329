import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface MainDialogProps {
  open: boolean;
  onClose?: (value?: string) => void;
  onConfirm: (value?: string) => void;
  title?: string;
  content: string;
  cancel?: string;
  ok: string;
}

export default function MainDialog(props: MainDialogProps) {
  const { onClose, open, title, onConfirm, content, cancel, ok } = props;

  const handleCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleOk = () => {
    onConfirm();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="dialog"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancel && (
            <Button
              autoFocus
              onClick={handleCancel}
              data-testid="dialog-cancel"
            >
              {cancel}
            </Button>
          )}
          <Button onClick={handleOk} data-testid="dialog-ok">
            {ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
