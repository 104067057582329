import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import axios from '../../../axios';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { camelCase, mapKeys } from 'lodash';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  prioritiesTextMap,
  taskStatusTextMap,
  useSystemSettings,
} from '../../../context/SystemSettingsContext';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Customer } from '../../../models/customer.model';
import { Team } from '../../../models/team.model';
import { User } from '../../../models/user.model';
import { useAuth } from '../../../context/AuthContext';
import { WORDS_ONLY_REGEX } from '../../../utils/regexHelpers';

export interface AddTaskInput {
  title: string;
  description: string;
  dueDate: string;
  priority: string;
  status: string;
  customerId: string;
  assignedTeamId: string;
  assignedUserId: string;
}

export default function AddTaskDialog({
  isOpen,
  handleClose,
  onSubmit,
  customers,
  teams,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: SubmitHandler<AddTaskInput>;
  customers: Customer[];
  teams: Team[];
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { organization } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { taskPriorityTypes, taskStatusTypes } = useSystemSettings();
  const [teamUsers, setTeamUsers] = useState<User[]>([]);

  const { reset, control, handleSubmit } = useForm<AddTaskInput>();

  const fetchTeamUsers = useCallback(
    async (assignedTeamId: string) => {
      const teamUsersResp = await axios.get(
        `api/organizations/${organization?.id}/teams/${assignedTeamId}`
      );
      setTeamUsers(
        teamUsersResp.data.data.users.map((item: any) => {
          const userObj = mapKeys(item, (v, k) => camelCase(k));
          const user = new User();
          return user.deserialize(userObj);
        })
      );
    },
    [organization?.id]
  );

  useEffect(() => {
    if (isOpen) {
      reset({
        title: '',
        description: '',
        dueDate: new Date().toDateString(),
        priority: '',
        status: '',
        customerId: '',
        assignedTeamId: '',
        assignedUserId: '',
      });
    }
  }, [reset, isOpen]);

  return (
    <Box>
      <Dialog open={isOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DialogTitle>{t('TASKS.CREATE_TASK')}</DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Controller
              name="title"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  multiline
                  label={t('TASKS.TITLE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'titleInput' }}
                />
              )}
              rules={{
                required: `${t('TASKS.TITLE')} ${t('TASKS.REQUIRED')}`,

                pattern: {
                  value: WORDS_ONLY_REGEX,
                  message: `${t('VALIDATION.INVALID_VALUE')}`,
                },
                maxLength: {
                  value: 100,
                  message: t('VALIDATION.MAX_LENGTH', { length: 100 }),
                },
              }}
            />
            <Controller
              name="customerId"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('TASKS.CUSTOMER')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('TASKS.CUSTOMER')}
                    data-testid={'customerInput'}
                    onChange={onChange}
                  >
                    {customers?.map((customer: Customer) => (
                      <MenuItem value={customer.id} key={customer.id}>
                        {customer.shortName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('TASKS.CUSTOMER')} ${t('TASKS.REQUIRED')}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('TASKS.CUSTOMER')} ${t('TASKS.REQUIRED')}`,
              }}
            />
            <Controller
              name="assignedTeamId"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('TASKS.CUSTOMER_ASSIGNED_TEAM')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('TASKS.CUSTOMER_ASSIGNED_TEAM')}
                    data-testid={'assignedTeamInput'}
                    onChange={(event) => {
                      fetchTeamUsers(event.target.value);
                      onChange(event);
                    }}
                  >
                    {teams?.map((team: Team) => (
                      <MenuItem value={team.id} key={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText>
                    {error
                      ? `${t('TASKS.CUSTOMER_ASSIGNED_TEAM')} ${t(
                          'TASKS.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('TASKS.CUSTOMER_ASSIGNED_TEAM')} ${t(
                  'TASKS.REQUIRED'
                )}`,
              }}
            />
            <Controller
              name="assignedUserId"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('TASKS.CUSTOMER_ASSIGNED_USER')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('TASKS.CUSTOMER_ASSIGNED_USER')}
                    data-testid={'assignedUserInput'}
                    onChange={onChange}
                  >
                    {teamUsers?.map((user: User) => (
                      <MenuItem value={user.id} key={user.id}>
                        {`${user.firstName} ${user.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText>
                    {error
                      ? `${t('TASKS.CUSTOMER_ASSIGNED_USER')} ${t(
                          'TASKS.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('TASKS.CUSTOMER_ASSIGNED_USER')} ${t(
                  'TASKS.REQUIRED'
                )}`,
              }}
            />
            <Controller
              name="priority"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('TASKS.PRIORITY')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('TASKS.PRIORITY')}
                    onChange={onChange}
                    data-testid={'priorityInput'}
                  >
                    {taskPriorityTypes.map((priority: string) => (
                      <MenuItem value={priority} key={priority}>
                        {t(get(prioritiesTextMap, priority))}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('TASKS.PRIORITY')} ${t('TASKS.REQUIRED')}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('TASKS.PRIORITY')} ${t('TASKS.REQUIRED')}`,
              }}
            />
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('TASKS.STATUS')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('TASKS.STATUS')}
                    onChange={onChange}
                    data-testid={'statusInput'}
                  >
                    {taskStatusTypes.map((status: string) => (
                      <MenuItem value={status} key={status}>
                        {t(get(taskStatusTextMap, status))}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error ? `${t('TASKS.STATUS')} ${t('TASKS.REQUIRED')}` : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('TASKS.STATUS')} ${t('TASKS.REQUIRED')}`,
              }}
            />
            <Controller
              name="dueDate"
              control={control}
              defaultValue={new Date().toDateString()}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <DesktopDateTimePicker
                  {...field}
                  value={value}
                  inputRef={ref}
                  label={t('TASKS.DUE_DATE')}
                  inputFormat="dd/MM/yyyy hh:mm aa"
                  onChange={onChange}
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      margin="normal"
                      sx={{
                        width: isMobile ? '100%' : '45%',
                      }}
                    />
                  )}
                />
              )}
              rules={{
                required: `${t('TASKS.DUE_DATE')} ${t('TASKS.REQUIRED')}`,
              }}
            />
            <Controller
              name="description"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  rows={3}
                  multiline
                  label={t('TASKS.DESCRIPTION')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'noteInput' }}
                />
              )}
              rules={{
                maxLength: {
                  value: 10000,
                  message: t('VALIDATION.MAX_LENGTH', { length: 1000 }),
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} data-testid="cancelDialog">
              {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_CANCEL')}
            </Button>
            <Button type="submit" data-testid="createButton">
              {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_SAVE')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
