import { Deserializable } from './deserializable.model';

export class User implements Deserializable {
  'id': string;
  'createdAt': string;
  'updatedAt': string;
  'firstName': string;
  'lastName': string;
  'email': string;
  'phone': string;
  'emailVerifiedAt': string;
  'rememberToken': string;
  'lastKnownLocation': string;
  'role': string[];
  'organizationRoleMap': { organizationId: string; role: string[] }[];

  deserialize(input: any) {
    Object.assign(this, input);
    /* istanbul ignore next */
    if (input.pivot?.role) {
      this.role = input.pivot.role;
    }
    return this;
  }
}
