import i18n from 'i18next';
import LanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translation/en.json';
import trTanslation from './translation/tr.json';

const options: DetectorOptions = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
  lookupLocalStorage: 'lang',
};

const resources = {
  en: {
    translation: enTranslation,
  },
  tr: {
    translation: trTanslation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: options,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
