import { Customer } from './customer.model';
import { Deserializable } from './deserializable.model';
import { User } from './user.model';

export class Order implements Deserializable {
  'id': string;
  'code': string;
  'organizationId': string;
  'customerId': string;
  'customerName': string;
  'orderDate': string;
  'status': string;
  'note': string;
  'totalAmount': string;
  'totalAmountWithDiscount': string;
  'customer': Customer;
  'totalDiscount': string;
  'taxAmount': string;
  'owner': User;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
