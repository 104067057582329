import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Performance } from '../../../../models/performance.model';

export default function TotalOrder({
  performance,
}: {
  performance?: Performance;
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t('TOTAL_ORDER_TITLE')}</Title>
      <Typography component="p" variant="h4">
        {performance?.orderPerformance.orderByWeek
          .reduce((acc, o) => acc + o.count, 0)
          .toLocaleString('en-US')}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {t('FROM_ORDER_DESCRIPTION', {
          fromDate: format(
            parseISO(
              new Date(
                new Date().setFullYear(new Date().getFullYear() - 1)
              ).toISOString()
            ),
            'dd/MM/yyyy'
          ),
        })}
      </Typography>
      <div>
        <Link color="primary" href="./orders">
          {t('VIEW_MORE')}
        </Link>
      </div>
    </React.Fragment>
  );
}
