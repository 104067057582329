import React, { useEffect, useState } from 'react';
import { PieChart, Pie, ResponsiveContainer, Legend } from 'recharts';
import { Performance } from '../../../../models/performance.model';
import { useTranslation } from 'react-i18next';

interface DataItem {
  name: string;
  value: number;
  fill: string;
}

export default function TaskCountByPriorityChart({
  performance,
}: {
  performance?: Performance;
}) {
  const { t } = useTranslation();

  const [data, setData] = useState<DataItem[]>([
    { name: t('SYSTEM_PRIORITY_LOW'), value: 0, fill: '#0088FE' },
    {
      name: t('SYSTEM_PRIORITY_MEDIUM'),
      value: 0,
      fill: '#00C49F',
    },
    { name: t('SYSTEM_PRIORITY_HIGH'), value: 0, fill: '#FFBB28' },
  ]);

  useEffect(() => {
    setData((prevState) => {
      prevState[0].value =
        performance?.taskPerformance.taskCountByPriority.low || 0;

      prevState[1].value =
        performance?.taskPerformance.taskCountByPriority.medium || 0;

      prevState[2].value =
        performance?.taskPerformance.taskCountByPriority.high || 0;

      return prevState;
    });
  }, [performance]);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <PieChart width={200} height={200}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={60}
          label
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
}
