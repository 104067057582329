import {
  FormHelperText,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { parsePhoneNumber } from 'libphonenumber-js';
import { each, get, times } from 'lodash';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import {
  customerTypesTextMap,
  useSystemSettings,
} from '../../../context/SystemSettingsContext';
import { Customer } from '../../../models/customer.model';
import { User } from '../../../models/user.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import { VALID_EMAIL_REGEX } from '../../../utils/regexHelpers';

export interface CustomerDetailsFormInput {
  id: string;
  shortName: string;
  fullName: string;
  taxOffice: string;
  taxNumber: string;
  type: string;
  industry: string;
  authorizedPersonName: string;
  authorizedPersonEmail: string;
  authorizedPersonPhone: string;
  accountManagerId: string;
  size: string;
}

export default function CustomerDetailsForm(props: {
  customer?: Customer;
  onSubmit: SubmitHandler<CustomerDetailsFormInput>;
  isDetailsLoading: boolean;
  users: User[];
}) {
  const { onSubmit, isDetailsLoading, customer, users } = props;
  const { t } = useTranslation();
  const { customerTypes, industries } = useSystemSettings();
  const theme = useTheme();
  const { organization, user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  type formValue =
    | 'id'
    | 'shortName'
    | 'fullName'
    | 'taxOffice'
    | 'taxNumber'
    | 'type'
    | 'industry'
    | 'authorizedPersonName'
    | 'authorizedPersonEmail'
    | 'authorizedPersonPhone'
    | 'accountManagerId'
    | 'size';

  const { control, handleSubmit, setValue } =
    useForm<CustomerDetailsFormInput>();

  useEffect(() => {
    const fillTheValues = async () => {
      if (customer) {
        const fields = [
          'id',
          'shortName',
          'fullName',
          'taxOffice',
          'taxNumber',
          'type',
          'industry',
          'authorizedPersonName',
          'authorizedPersonEmail',
          'authorizedPersonPhone',
          'accountManagerId',
          'size',
        ];

        each(fields, (field) => {
          const value = get(customer, field);
          if (value !== null && value !== undefined) {
            setValue(field as formValue, get(customer, field));
          }
        });
      }
    };

    fillTheValues();
  }, [customer, setValue]);

  if (isDetailsLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: '10px',
          flexWrap: 'wrap',
          gap: '24px',
        }}
      >
        {times(8, (index) => (
          <Skeleton animation="wave" sx={{ marginBottom: 6 }} key={index} />
        ))}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        padding: '10px',
        flexWrap: 'wrap',
        gap: '12px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Controller
          name="id"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              disabled
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_ID')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
            />
          )}
        />
        <Controller
          name="shortName"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_SHORT_NAME')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'shortNameInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            required: `${t('CUSTOMERS.CUSTOMER_SHORT_NAME')} ${t(
              'CUSTOMERS.CUSTOMER.REQUIRED'
            )}`,
          }}
        />
        <Controller
          name="fullName"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_FULL_NAME')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'fullNameInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('CUSTOMERS.CUSTOMER_TYPE')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('CUSTOMERS.CUSTOMER_TYPE')}
                onChange={onChange}
                inputProps={{ 'data-testid': 'customerTypeInput' }}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {customerTypes?.map((type: string) => (
                  <MenuItem value={type} key={type}>
                    {t(get(customerTypesTextMap, type))}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error
                  ? `${t('CUSTOMERS.CUSTOMER_TYPE')} ${t(
                      'CUSTOMERS.CUSTOMER.REQUIRED'
                    )}`
                  : ''}
              </FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="size"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: '210px',
                width: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('CUSTOMERS.CUSTOMER_SIZE')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                data-testid="organizationSizeSelect"
                value={value}
                error={!!error}
                label={t('CUSTOMERS.CUSTOMER_SIZE')}
                onChange={onChange}
                inputProps={{ 'data-testid': 'customerSizeInput' }}
              >
                <MenuItem value={'1-10'}>1 - 10</MenuItem>
                <MenuItem value={'10-50'}>10 - 50</MenuItem>
                <MenuItem value={'50-100'}>50 - 100</MenuItem>
                <MenuItem value={'100+'}>100+</MenuItem>
              </Select>
              <FormHelperText>
                {error
                  ? `${t('CUSTOMERS.CUSTOMER_SIZE')} ${t(
                      'CUSTOMERS.CUSTOMER.REQUIRED'
                    )}`
                  : ''}
              </FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          name="industry"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('CUSTOMERS.CUSTOMER_INDUSTRY')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('CUSTOMERS.CUSTOMER_INDUSTRY')}
                onChange={onChange}
                inputProps={{ 'data-testid': 'organizationSectorInput' }}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {industries?.map((indust: string) => (
                  <MenuItem value={indust} key={indust}>
                    {indust}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error
                  ? `${t('CUSTOMERS.CUSTOMER_INDUSTRY')} ${t(
                      'CUSTOMERS.CUSTOMER.REQUIRED'
                    )}`
                  : ''}
              </FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          name="taxOffice"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_TAX_OFFICE')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              inputProps={{ 'data-testid': 'addressLine2Input' }}
            />
          )}
        />
        <Controller
          name="taxNumber"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_TAX_NUMBER')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'postalCodeInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
        />
        <Controller
          name="authorizedPersonName"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_NAME')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'cityInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
        />
        <Controller
          name="authorizedPersonEmail"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_EMAIL')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'countryInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            pattern: {
              value: VALID_EMAIL_REGEX,
              message: t('CUSTOMERS.EMAIL.INVALID'),
            },
          }}
        />
        <Controller
          name="authorizedPersonPhone"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_PHONE')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'countryInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            validate: {
              isValidPhone: (value) => {
                if (!value) {
                  return true;
                }
                try {
                  return parsePhoneNumber(value, 'TR')?.isValid()
                    ? true
                    : t('VALIDATION.INVALID_PHONE_NUMBER');
                } catch (err) {
                  return t('VALIDATION.INVALID_PHONE_NUMBER');
                }
              },
            },
          }}
        />

        <Controller
          name="accountManagerId"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                minWidth: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('CUSTOMERS.CUSTOMER_ACCOUNT_MANAGER')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('CUSTOMERS.CUSTOMER_ACCOUNT_MANAGER')}
                onChange={onChange}
                inputProps={{ 'data-testid': 'accountManagerIdInput' }}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {users.map((user, index) => {
                  return (
                    <MenuItem key={index} value={user.id}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {error
                  ? `${t('CUSTOMERS.CUSTOMER_ACCOUNT_MANAGER')} ${t(
                      'CUSTOMERS.CUSTOMER.REQUIRED'
                    )}`
                  : ''}
              </FormHelperText>
            </FormControl>
          )}
        />
        <Button
          type="submit"
          variant="outlined"
          data-testid="updateCustomerSubmit"
          sx={{
            marginTop: '20px',
            maxWidth: '200px',
          }}
          disabled={
            !doesRoleExist(user, organization, [
              'ADMIN',
              'TEAM_MANAGER',
              'TEAM_MEMBER',
            ])
          }
        >
          {t('CUSTOMERS.CUSTOMER.UPDATE')}
        </Button>
      </form>
    </Box>
  );
}
