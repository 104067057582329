import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { camelCase, mapKeys } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { OrderItem } from '../../../models/orderItem.model';
import { Product } from '../../../models/product.model';

export interface AddOrderItemInput {
  id?: string;
  productId: string;
  product?: Product;
  itemName: string;
  itemSerialNumber: string;
  quantity: string;
  price: string;
  taxRate: string;
  taxAmount: string;
  totalAmount: string;
}

export default function AddOrderItemDialog({
  isOpen,
  handleClose,
  onSubmit,
  selectedOrderItem,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: SubmitHandler<AddOrderItemInput>;
  selectedOrderItem: OrderItem | undefined;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { organization } = useAuth();
  const [products, setProducts] = useState<Product[]>();

  const { reset, control, handleSubmit, setValue, getValues } =
    useForm<AddOrderItemInput>();

  useEffect(() => {
    const fetchData = async () => {
      const customersResp = await axios.get(
        `api/organizations/${organization?.id}/products`,
        {
          params: {
            filters: {
              is_active: true,
            },

            pagination: {
              sort: 'name:asc',
            },
          },
        }
      );
      setProducts(
        customersResp.data.data.items.map((item: any) => {
          const customerObj = mapKeys(item, (v, k) => camelCase(k));
          const customer = new Product();
          return customer.deserialize(customerObj);
        })
      );
    };
    fetchData();
  }, [organization?.id]);

  useEffect(() => {
    if (isOpen) {
      if (selectedOrderItem) {
        setValue('id', selectedOrderItem.id);
        setValue('productId', selectedOrderItem.productId);
        setValue('itemName', selectedOrderItem.itemName);
        setValue('itemSerialNumber', selectedOrderItem.itemSerialNumber);
        setValue('quantity', selectedOrderItem.quantity);
        setValue('price', selectedOrderItem.price);
        setValue('taxRate', selectedOrderItem.taxRate);
        setValue('taxAmount', selectedOrderItem.taxAmount);
        setValue('totalAmount', selectedOrderItem.totalAmount);
      } else {
        reset({
          productId: '',
          itemName: '',
          itemSerialNumber: '',
          quantity: '1',
          price: '',
          taxRate: '',
          taxAmount: '',
          totalAmount: '',
        });
      }
    }
  }, [reset, isOpen, selectedOrderItem, setValue]);

  return (
    <Box>
      <Dialog open={isOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DialogTitle>
            {selectedOrderItem
              ? t('ORDER_ITEM.UPDATE')
              : t('ORDER_ITEMS.CREATE')}
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Controller
              name="productId"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('ORDER_ITEMS.PRODUCT')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('TASKS.STATUS')}
                    onChange={(event) => {
                      onChange(event);
                      const product = products?.find(
                        (product) => product.id === event.target.value
                      );
                      setValue('price', product?.unitPrice || '');
                      setValue('itemName', product?.name || '');
                      setValue('itemSerialNumber', product?.serialNumber || '');
                      setValue('quantity', '1');
                    }}
                    data-testid={'productIdInput'}
                  >
                    {products?.map((product: Product) => (
                      <MenuItem value={product.id} key={product.id}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('ORDER_ITEMS.PRODUCT')} ${t(
                          'ORDER_ITEMS.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('TASKS.STATUS')} ${t('TASKS.REQUIRED')}`,
              }}
            />
            <Controller
              name="quantity"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={(event) => {
                    onChange(event);

                    const product = products?.find(
                      (product) => product.id === getValues('productId')
                    );

                    const quantity: number = parseFloat(
                      event.target.value || '1'
                    );

                    const newPrice: number =
                      quantity * parseFloat(product?.unitPrice || '0');

                    const taxRate =
                      parseFloat(getValues('taxRate') || '0') / 100;
                    const taxAmount: number = taxRate * newPrice;

                    if (taxAmount) {
                      setValue('taxAmount', String(taxAmount.toFixed(2)));
                    }

                    const newTotalAmount: number = newPrice + taxAmount;

                    setValue('price', String(newPrice) || '');
                    setValue('totalAmount', String(newTotalAmount || '0'));
                  }}
                  error={!!error}
                  multiline
                  label={t('ORDER_ITEMS.QUANTITY')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'quantityInput' }}
                />
              )}
              rules={{
                required: `${t('ORDER_ITEMS.QUANTITY')} ${t(
                  'ORDER_ITEMS.REQUIRED'
                )}`,
              }}
            />
            <Controller
              name="price"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  disabled
                  onChange={(event) => {
                    onChange(event);

                    const newPrice: number = parseFloat(
                      event.target.value || '0'
                    );

                    const taxRate =
                      parseFloat(getValues('taxRate') || '0') / 100;

                    const taxAmount: number = taxRate * newPrice;

                    if (taxAmount) {
                      setValue('taxAmount', String(taxAmount));
                    }

                    const newTotalAmount: number = newPrice + taxAmount;
                    setValue('totalAmount', String(newTotalAmount || '0'));
                  }}
                  error={!!error}
                  multiline
                  label={t('ORDER_ITEMS.PRICE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'priceInput' }}
                />
              )}
              rules={{
                required: `${t('ORDER_ITEMS.PRICE')} ${t(
                  'ORDER_ITEMS.REQUIRED'
                )}`,
                pattern: {
                  value: /^[+-]?\d+(\.\d+)?$/,
                  message: `${t('VALIDATION.INVALID_NUMBER_FORMAT')}`,
                },
              }}
            />
            <Controller
              name="taxRate"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={(event) => {
                    onChange(event);

                    const taxRate: number =
                      parseFloat(event.target.value || '0') / 100;
                    const taxAmount: number =
                      parseFloat(getValues('price')) * taxRate;

                    const totalAmount: number =
                      taxAmount + parseFloat(getValues('price'));

                    setValue('taxAmount', String(taxAmount.toFixed(2)));
                    setValue('totalAmount', String(totalAmount.toFixed(2)));
                  }}
                  error={!!error}
                  multiline
                  label={t('ORDER_ITEMS.TAX_RATE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'taxRateInput' }}
                />
              )}
              rules={{
                required: `${t('ORDER_ITEMS.TAX_RATE')} ${t(
                  'ORDER_ITEMS.REQUIRED'
                )}`,
                pattern: {
                  value: /^[+-]?\d+(\.\d+)?$/,
                  message: `${t('VALIDATION.INVALID_NUMBER_FORMAT')}`,
                },
              }}
            />
            <Controller
              name="taxAmount"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  disabled
                  error={!!error}
                  multiline
                  label={t('ORDER_ITEMS.TAX_AMOUNT')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'taxAmountInput' }}
                />
              )}
              rules={{
                required: `${t('ORDER_ITEMS.TAX_AMOUNT')} ${t(
                  'ORDER_ITEMS.REQUIRED'
                )}`,
                pattern: {
                  value: /^[+-]?\d+(\.\d+)?$/,
                  message: `${t('VALIDATION.INVALID_NUMBER_FORMAT')}`,
                },
              }}
            />
            <Controller
              name="totalAmount"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  disabled
                  onChange={onChange}
                  error={!!error}
                  multiline
                  label={t('ORDER_ITEMS.TOTAL_AMOUNT')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'totalAmountInput' }}
                />
              )}
              rules={{
                required: `${t('ORDER_ITEMS.TOTAL_AMOUNT')} ${t(
                  'ORDER_ITEMS.REQUIRED'
                )}`,
                pattern: {
                  value: /^[+-]?\d+(\.\d+)?$/,
                  message: `${t('VALIDATION.INVALID_NUMBER_FORMAT')}`,
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} data-testid="cancelDialog">
              {t('ORDER_ITEMS.CANCEL')}
            </Button>
            <Button type="submit" data-testid="createButton">
              {t('ORDER_ITEMS.SAVE')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
