import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from '../../../axios';
import OrderStatusChart from './Charts/OrderStatusChart';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { Performance } from '../../../models/performance.model';
import camelize from '../../../utils/camelize';
import CustomerCountByTypeChart from './Charts/CustomerCountByTypeChart';
import CustomerActivityCountByTypeChart from './Charts/CustomerActivityCountByTypeChart';
import TaskCountByStatusChart from './Charts/TaskCountByStatusChart';
import TaskCountByPriorityChart from './Charts/TaskCountByPriorityChart';
import RevenueByWeeksChart from './Charts/RevenueByWeeksChart';
import TaskCountByTeamChart from './Charts/TaskCountByTeamChart';

export default function ReportsPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [fromDate, setFromDate] = useState<string | null>(
    new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString()
  );
  const [toDate, setToDate] = useState<string | null>(new Date().toISOString());
  const [isLoading, setIsLoading] = useState(false);
  const [performance, setPerformance] = useState<Performance>();
  const { t } = useTranslation();
  const { organization } = useAuth();

  const charts = [
    {
      key: 'REVENUE_BY_WEEKS_CHART',
      component: <RevenueByWeeksChart performance={performance} />,
      size: '100%',
    },
    {
      key: 'ORDER_STATUS_CHART',
      component: <OrderStatusChart performance={performance} />,
      size: '30%',
    },
    {
      key: 'CUSTOMER_COUNT_BY_TYPE_CHART',
      component: <CustomerCountByTypeChart performance={performance} />,
      size: '30%',
    },
    {
      key: 'CUSTOMER_ACTIVITY_BY_TYPE_CHART',
      component: <CustomerActivityCountByTypeChart performance={performance} />,
      size: '30%',
    },
    {
      key: 'TASK_COUNT_BY_STATUS_CHART',
      component: <TaskCountByStatusChart performance={performance} />,
      size: '30%',
    },
    {
      key: 'TASK_COUNT_BY_PRIORITY_CHART',
      component: <TaskCountByPriorityChart performance={performance} />,
      size: '30%',
    },
    {
      key: 'TASK_COUNT_BY_TEAM_CHART',
      component: <TaskCountByTeamChart performance={performance} />,
      size: '30%',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const from = new Date(
        fromDate ||
          new Date(
            new Date().setFullYear(new Date().getFullYear() - 1)
          ).toISOString()
      );
      const to = new Date(toDate || new Date().toISOString());
      const performanceResp = await axios.get(
        `api/organizations/${
          organization?.id
        }/performance?startDate=${from.toISOString()}&endDate=${to.toISOString()}`
      );
      const performance = new Performance().initialize();
      const performanceObj = camelize(performanceResp.data);
      setPerformance(performance.deserialize(performanceObj));
      setIsLoading(false);
    };
    fetchData();
  }, [organization?.id, fromDate, toDate]);

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <DesktopDatePicker
          value={fromDate}
          label={t('REPORTS.FROM')}
          inputFormat="dd/MM/yyyy"
          onChange={setFromDate}
          renderInput={(inputProps) => (
            <TextField
              {...inputProps}
              margin="normal"
              sx={{
                width: isMobile ? '100%' : '15%',
              }}
            />
          )}
        />
        <DesktopDatePicker
          value={toDate}
          label={t('REPORTS.TO')}
          inputFormat="dd/MM/yyyy"
          onChange={setToDate}
          renderInput={(inputProps) => (
            <TextField
              {...inputProps}
              margin="normal"
              sx={{
                width: isMobile ? '100%' : '15%',
              }}
            />
          )}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="20px"
        flexWrap="wrap"
        data-testid="reportsPage"
      >
        {charts.map((chart) => (
          <Card
            sx={{
              minHeight: 400,
              minWidth: isMobile ? '80%' : chart.size,
              flex: 1,
            }}
            key={chart.key}
          >
            <CardHeader
              title={t(chart.key)}
              sx={{ color: 'darkgray', pb: 0 }}
            />
            {!isLoading && <CardContent>{chart.component}</CardContent>}
          </Card>
        ))}
      </Box>
    </>
  );
}
