import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from '../../../axios';
import Box from '@mui/material/Box';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  FormHelperText,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { times, get, each } from 'lodash';
import { Customer } from '../../../models/customer.model';
import {
  prioritiesTextMap,
  taskStatusTextMap,
  useSystemSettings,
} from '../../../context/SystemSettingsContext';
import { Task } from '../../../models/task.model';
import { camelCase, mapKeys } from 'lodash';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { Team } from '../../../models/team.model';
import { useAuth } from '../../../context/AuthContext';
import { User } from '../../../models/user.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import { WORDS_ONLY_REGEX } from '../../../utils/regexHelpers';

export default function TaskDetailsForm(props: {
  task?: Task;
  onSubmit: SubmitHandler<Task>;
  isDetailsLoading: boolean;
  customers: Customer[];
  teams: Team[];
}) {
  const { onSubmit, isDetailsLoading, task, customers, teams } = props;
  const { t } = useTranslation();
  const { taskPriorityTypes, taskStatusTypes } = useSystemSettings();
  const theme = useTheme();
  const { organization, user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [teamUsers, setTeamUsers] = useState<User[]>([]);

  type formValue =
    | 'id'
    | 'title'
    | 'description'
    | 'dueDate'
    | 'priority'
    | 'status'
    | 'customerId'
    | 'assignedTeamId'
    | 'assignedUserId';

  const { control, handleSubmit, setValue } = useForm<Task>();

  const fetchTeamUsers = useCallback(
    async (assignedTeamId: string) => {
      const teamUsersResp = await axios.get(
        `api/organizations/${organization?.id}/teams/${assignedTeamId}`
      );
      setTeamUsers(
        teamUsersResp.data.data.users.map((item: any) => {
          const userObj = mapKeys(item, (v, k) => camelCase(k));
          const user = new User();
          return user.deserialize(userObj);
        })
      );
    },
    [organization?.id]
  );

  useEffect(() => {
    const fillTheValues = async () => {
      if (task) {
        const fields = [
          'id',
          'title',
          'description',
          'dueDate',
          'priority',
          'status',
          'customerId',
          'assignedTeamId',
          'assignedUserId',
        ];

        if (get(task, 'assignedTeamId')) {
          await fetchTeamUsers(get(task, 'assignedTeamId'));
        }

        each(fields, (field) => {
          const value = get(task, field);
          if (value !== null && value !== undefined) {
            setValue(field as formValue, get(task, field));
          }
        });
      }
    };

    fillTheValues();
  }, [task, setValue, fetchTeamUsers]);

  if (isDetailsLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: '10px',
          flexWrap: 'wrap',
          gap: '24px',
        }}
      >
        {times(5, (index) => (
          <Skeleton animation="wave" sx={{ marginBottom: 6 }} key={index} />
        ))}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        padding: '10px',
        flexWrap: 'wrap',
        gap: '12px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Controller
          name="title"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              multiline
              label={t('TASKS.TITLE')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'titleInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            required: `${t('TASKS.TITLE')} ${t('TASKS.REQUIRED')}`,

            pattern: {
              value: WORDS_ONLY_REGEX,
              message: `${t('VALIDATION.INVALID_VALUE')}`,
            },
            maxLength: {
              value: 100,
              message: t('VALIDATION.MAX_LENGTH', { length: 100 }),
            },
          }}
        />
        <Controller
          name="customerId"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: '210px',
                width: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('TASKS.CUSTOMER')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('TASKS.CUSTOMER')}
                onChange={onChange}
                data-testid={'customerInput'}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {customers?.map((customer: Customer) => (
                  <MenuItem value={customer.id} key={customer.id}>
                    {customer.shortName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error ? `${t('TASKS.CUSTOMER')} ${t('TASKS.REQUIRED')}` : ''}
              </FormHelperText>
            </FormControl>
          )}
          rules={{
            required: `${t('TASKS.CUSTOMER')} ${t('TASKS.REQUIRED')}`,
          }}
        />
        <Controller
          name="assignedTeamId"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: '210px',
                width: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('TASKS.CUSTOMER_ASSIGNED_TEAM')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('TASKS.CUSTOMER_ASSIGNED_TEAM')}
                onChange={(event) => {
                  fetchTeamUsers(event.target.value);
                  onChange(event);
                }}
                data-testid={'assignedTeamInput'}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {teams?.map((team: Team) => (
                  <MenuItem value={team.id} key={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          rules={{
            required: `${t('TASKS.CUSTOMER_ASSIGNED_TEAM')} ${t(
              'TASKS.REQUIRED'
            )}`,
          }}
        />
        <Controller
          name="assignedUserId"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: '210px',
                width: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('TASKS.CUSTOMER_ASSIGNED_USER')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('TASKS.CUSTOMER_ASSIGNED_USER')}
                onChange={onChange}
                data-testid={'assignedUserInput'}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {teamUsers?.map((user: User) => (
                  <MenuItem value={user.id} key={user.id}>
                    {`${user.firstName} ${user.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          rules={{
            required: `${t('TASKS.CUSTOMER_ASSIGNED_USER')} ${t(
              'TASKS.REQUIRED'
            )}`,
          }}
        />
        <Controller
          name="priority"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: '210px',
                width: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('TASKS.PRIORITY')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('TASKS.PRIORITY')}
                onChange={onChange}
                data-testid={'priorityInput'}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {taskPriorityTypes?.map((priority: string) => (
                  <MenuItem value={priority} key={priority}>
                    {t(get(prioritiesTextMap, priority))}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error ? `${t('TASKS.PRIORITY')} ${t('TASKS.REQUIRED')}` : ''}
              </FormHelperText>
            </FormControl>
          )}
          rules={{
            required: `${t('TASKS.PRIORITY')} ${t('TASKS.REQUIRED')}`,
          }}
        />
        <Controller
          name="status"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: '210px',
                width: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('TASKS.STATUS')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('TASKS.STATUS')}
                onChange={onChange}
                data-testid={'statusInput'}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {taskStatusTypes?.map((status: string) => (
                  <MenuItem value={status} key={status}>
                    {t(get(taskStatusTextMap, status))}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error ? `${t('TASKS.STATUS')} ${t('TASKS.REQUIRED')}` : ''}
              </FormHelperText>
            </FormControl>
          )}
          rules={{
            required: `${t('TASKS.STATUS')} ${t('TASKS.REQUIRED')}`,
          }}
        />
        <Controller
          name="dueDate"
          control={control}
          defaultValue={new Date().toDateString()}
          render={({ field: { ref, onChange, value, ...field } }) => (
            <DesktopDateTimePicker
              {...field}
              value={value}
              inputRef={ref}
              label={t('TASKS.DUE_DATE')}
              inputFormat="dd/MM/yyyy hh:mm aa"
              onChange={(value) => {
                onChange(value);
              }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              renderInput={(inputProps) => (
                <TextField
                  {...inputProps}
                  margin="normal"
                  sx={{
                    width: isMobile ? '100%' : '45%',
                  }}
                />
              )}
            />
          )}
          rules={{
            required: `${t('TASKS.DUE_DATE')} ${t('TASKS.REQUIRED')}`,
          }}
        />
        <Controller
          name="description"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              rows={3}
              multiline
              label={t('TASKS.DESCRIPTION')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'descriptionInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            maxLength: {
              value: 10000,
              message: t('VALIDATION.MAX_LENGTH', { length: 1000 }),
            },
          }}
        />
        <Button
          type="submit"
          variant="outlined"
          data-testid="updateTaskSubmit"
          sx={{
            marginTop: '20px',
            maxWidth: '200px',
            minWidth: '160px',
          }}
          disabled={
            !doesRoleExist(user, organization, [
              'ADMIN',
              'TEAM_MANAGER',
              'TEAM_MEMBER',
            ])
          }
        >
          {t('TASK_DETAILS.UPDATE')}
        </Button>
      </form>
    </Box>
  );
}
